import React from 'react';
import { Dropdown } from 'semantic-ui-react';

export default function DropdownEditor(props) {
    const { column: { key, optionKey, search, defaultValue } = {}, row } = props;

    const options = (props.column.options ?? []).filter(o => {
        if (optionKey) {
            return o[optionKey] === row[optionKey];
        } else {
            return true;
        }
    });

    const handleChange = value => {
        props.onRowChange({ ...row, [key]: value }, true);
        props.onClose(true);
    };

    const value = row[key] ?? '';

    return (
        <Dropdown
            selectOnBlur={false}
            selectOnNavigation={false}
            defaultOpen
            closeOnChange
            showSearch
            fluid
            scrolling
            noResultsMessage="没有找到符合条件的数据"
            allowClear
            selection
            clearable
            options={options}
            defaultValue={defaultValue ?? value}
            search={search}
            name={key}
            onChange={(evt, { name, value }) => {
                handleChange(value);
            }}
        />
    );
}
