import React, { lazy, Suspense, useEffect, useRef } from 'react';
import { fromJS, is } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';

import { Sidebar } from 'semantic-ui-react';

import { DataGrid } from '../Common';

import Actions from './actions';

const Detail = lazy(() => import('./Detail'));

export default function Simulator(props) {
    const dispatch = useDispatch();

    const loading_index = useSelector(
        state => state.SimulatorReducer.get('loading_index'),
        (a, b) => is(a, b)
    );

    const loading_show = useSelector(
        state => state.SimulatorReducer.get('loading_show'),
        (a, b) => is(a, b)
    );

    const simulators = useSelector(
        state => state.SimulatorReducer.get('simulators'),
        (a, b) => is(a, b)
    );

    const simulator = useSelector(
        state => state.SimulatorReducer.get('simulator'),
        (a, b) => is(a, b)
    );

    const search = useSelector(
        store => store.SimulatorReducer.get('search'),
        (a, b) => is(a, b)
    );

    const defaultColumns = useSelector(
        store => store.SimulatorReducer.get('columns'),
        (a, b) => is(a, b)
    );

    const columns = useSelector(
        store => store.MainReducer.getIn(['gui', 'Customer'], defaultColumns),
        (a, b) => is(a, b)
    ).toJS();

    const onlines = useSelector(
        state => state.MainReducer.get('onlines'),
        (a, b) => is(a, b)
    );

    useEffect(() => {
        dispatch(Actions.requests());
    }, [search]);

    const handleSearch = (evt, values) => {
        const { name, value } = values;
        if (name) {
            dispatch(Actions.search(search.set(name, value instanceof Array ? value.join(',') : value)));
        } else {
            dispatch(Actions.search(search.merge(fromJS(values))));
        }
    };

    const handleAction = (evt, { action }) => {
        switch (action) {
            case 'add':
                dispatch(Actions.show());
                return DetailRef?.current?.handleOpen();
            case 'refresh':
                return dispatch(Actions.requests());
            case 'update':
                return dispatch(Actions.create());
            case 'destroy':
                return dispatch(Actions.destroy());
            case 'print':
                break;
            default:
        }
    };

    const handleColumnChange = columns => {
        // dispatch(GuiActions.update({ key: ['Employee', 'Worker'], value: columns }));
    };

    const handleChange = (evt, values) => {
        const { name, value } = values;
        if (name) {
            dispatch(Actions.show(simulator.set(name, fromJS(value))));
        } else {
            dispatch(Actions.show(simulator.merge(fromJS(values))));
        }
    };

    const DetailRef = useRef();

    const _simulators = simulators.map(simulator => {
        return simulator.set('online', onlines.includes(simulator.get('serial_no') ?? ''));
    });

    const status = [
        { label: '离线', value: _simulators.size - onlines.size },
        { label: '在线', value: onlines.size },
    ];

    return (
        <div className="flexHorizontalContent">
            <Sidebar.Pushable className="flexHorizontalContent">
                <DataGrid
                    status={status}
                    loading={loading_index}
                    columns={columns}
                    rows={_simulators}
                    addition={false}
                    defaultSortColumns={[{ columnKey: 'name', direction: 'ASC' }]}
                    onAction={handleAction}
                    onColumnChange={handleColumnChange}
                />
                <Suspense fallback={null}>
                    <Detail
                        ref={DetailRef}
                        loading={loading_show}
                        simulator={simulator}
                        onAction={handleAction}
                        onChange={handleChange}
                    />
                </Suspense>
            </Sidebar.Pushable>
        </div>
    );
}

export { default as SimulatorReducer } from './reducers';
export { default as SimulatorAction } from './actions';
