import { File as Api } from '../../../Api';
import * as ACTION_TYPES from './constants';
import { Toast } from '../';

export default class CommonPrintPreviewAction {
    static loading = () => ({
        type: ACTION_TYPES.FILE_PREVIEW_LOADING,
    });

    static open = query => async (dispatch, getState) => {
        dispatch({ type: ACTION_TYPES.FILE_PREVIEW_OPEN, query });
        // dispatch(this.requests(query));
    };

    static requests = query => async (dispatch, getState) => {
        try {
            dispatch(this.loading());
            const res = await Api.index(query);
            if (res instanceof Error) throw res;
            dispatch(this.show(res));
        } catch (error) {
            Toast.error(error);
            dispatch(this.loading());
        }
    };

    static show = (result = {}) => ({
        type: ACTION_TYPES.FILE_PREVIEW_SHOW,
        result,
    });

    static hide = () => ({
        type: ACTION_TYPES.FILE_PREVIEW_HIDE,
    });

    static reset = () => ({
        type: ACTION_TYPES.FILE_PREVIEW_RESET,
    });
}
