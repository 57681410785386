import { FaCar } from 'react-icons/fa6';
import { Icon } from 'semantic-ui-react';
import { List } from 'immutable';

export default function DashboardSimulator(props) {
    const { item, onClick = () => {}, onlines = List() } = props;

    let no = item?.no ?? '';
    const online = onlines.includes(item.serial_no) ? 'online' : '';
    no = no.split('-').slice(1).join('-');

    const handleClick = evt => {
        //online &&
        onClick(evt, { item });
    };

    return (
        <div className={`simulator ${online}`} onClick={handleClick}>
            <div className="image">
                <FaCar />
            </div>
            <div className="name">
                {item.schools_name}
                <span style={{ float: 'right', marginRight: -6 }}>
                    <Icon name={`check circle ${!!online ? '' : 'outline'}`} color={!!online ? 'green' : null} />
                </span>
            </div>
            <div>{no}</div>
        </div>
    );
}
