import { is, Map } from 'immutable';
import React from 'react';

import Form from 'semantic-ui-react/dist/es/collections/Form';

import { DatePicker } from '../../Common';
import { Utils } from '../../../Constants';
import { useSelector } from 'react-redux';

const { SEX_OPTIONS, STUDENT_STATUS_OPTIONS, LICENSE_OPTIONS } = Utils;

export default function StudentSearch(props) {
    const { onSearch = () => {}, search = Map(), project2_coach_options = [], project3_coach_options = [] } = props;

    const school_options = useSelector(
        store => store.SchoolReducer.get('options'),
        (a, b) => is(a, b)
    ).toJS();

    const keyword = search.get('keyword') ?? '';
    const schools_id = search.get('schools_id') ?? '';
    const sex = search.get('sex') ?? '';
    const status = search.get('status') ?? '';
    const start_date = search.get('start_date') ?? '';
    const end_date = search.get('end_date') ?? '';
    const date_mode = search.get('date_mode') ?? '';
    const license = search.get('license') ?? '';
    const project2_coaches_id = search.get('project2_coaches_id') ?? '';
    const project3_coaches_id = search.get('project3_coaches_id') ?? '';

    return (
        <Form size="tiny" widths="equal">
            <Form.Group>
                <Form.Input
                    width={4}
                    fluid
                    placeholder="姓名/手机"
                    onBlur={evt => onSearch(evt, { name: 'keyword', value: evt.target.value })}
                    onKeyDown={evt => evt.keyCode === 13 && onSearch(evt, { name: 'keyword', value: evt.target.value })}
                />
                <Form.Select
                    width={2}
                    clearable
                    placeholder="驾校"
                    name="schools_id"
                    value={schools_id}
                    options={school_options}
                    onChange={onSearch}
                />
                <Form.Select
                    fluid
                    width={1}
                    clearable
                    placeholder="性别"
                    name="sex"
                    value={sex}
                    options={SEX_OPTIONS}
                    onChange={onSearch}
                />
                <Form.Select
                    fluid
                    width={1}
                    clearable
                    placeholder="驾照"
                    name="license"
                    value={license}
                    options={LICENSE_OPTIONS}
                    onChange={onSearch}
                />
                <Form.Select
                    fluid
                    width={1}
                    clearable
                    placeholder="科二教练"
                    name="project2_coaches_id"
                    value={project2_coaches_id}
                    options={project2_coach_options}
                    onChange={onSearch}
                />
                <Form.Select
                    fluid
                    width={1}
                    clearable
                    placeholder="科三教练"
                    name="project3_coaches_id"
                    value={project3_coaches_id}
                    options={project3_coach_options}
                    onChange={onSearch}
                />
                <DatePicker
                    width={2}
                    mode="search"
                    placeholder="注册日期"
                    search_mode={date_mode}
                    start_date={start_date}
                    end_date={end_date}
                    onChange={onSearch}
                />
                <Form.Select
                    width={1}
                    fluid
                    placeholder="状态"
                    options={STUDENT_STATUS_OPTIONS}
                    name="status"
                    value={status}
                    onChange={onSearch}
                />
            </Form.Group>
        </Form>
    );
}
