import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { fromJS, is } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Dropdown, Sidebar } from 'semantic-ui-react';
import SchoolActions from '../School/actions';

import { DataGrid, Excel } from '../Common';
import Search from './Search';

import Actions from './actions';
import CoachActions from '../Coach/actions';

const Detail = lazy(() => import('../Setting/Scholar/Detail'));

export default function Student(props) {
    const dispatch = useDispatch();

    const loading_index = useSelector(
        state => state.StudentReducer.get('loading_index'),
        (a, b) => is(a, b)
    );

    const students = useSelector(
        state => state.StudentReducer.get('students'),
        (a, b) => is(a, b)
    );

    const student = useSelector(
        state => state.StudentReducer.get('student'),
        (a, b) => is(a, b)
    );

    const schools_options = useSelector(
        state => state.StudentReducer.get('schools'),
        (a, b) => is(a, b)
    ).toJS();

    const search = useSelector(
        store => store.StudentReducer.get('search'),
        (a, b) => is(a, b)
    );

    const defaultColumns = useSelector(
        store => store.StudentReducer.get('columns'),
        (a, b) => is(a, b)
    );

    const columns = useSelector(
        store => store.MainReducer.getIn(['gui', 'Student'], defaultColumns),
        (a, b) => is(a, b)
    ).toJS();

    const coach_options = useSelector(
        store => store.CoachReducer.get('options'),
        (a, b) => is(a, b)
    ).toJS();

    const project2_coach_options = coach_options.filter(({ kind }) => (kind ?? '').includes('科二'));
    const project3_coach_options = coach_options.filter(({ kind }) => (kind ?? '').includes('科三'));
    const simulator_coach_options = coach_options.filter(({ kind }) => (kind ?? '').includes('模拟培训'));

    const [selected, setSelected] = useState([]);

    useEffect(() => {
        dispatch(SchoolActions.options());
        dispatch(CoachActions.options());
        dispatch(Actions.school());
    }, []);

    useEffect(() => {
        dispatch(Actions.requests());
    }, [search]);

    useEffect(() => {
        DataGridRef?.current?.handleSelectedRowsChange();
    }, [students]);

    const handleSearch = (evt, values) => {
        const { name, value } = values;
        if (name) {
            dispatch(Actions.search(search.set(name, value instanceof Array ? value.join(',') : value)));
        } else {
            dispatch(Actions.search(search.merge(fromJS(values))));
        }
    };

    const handleAction = (evt, { action, value }) => {
        switch (action) {
            case 'refresh':
                return dispatch(Actions.requests());
            case 'excel':
                return handleExport();
            case 'update':
                return dispatch(Actions.create());
            default:
                return dispatch(Actions.patch({ action, value, selected }));
        }
    };

    const handleExport = () => {
        const eventHandler = res => {
            Excel.emitter.removeListener('opened', eventHandler);
            dispatch(Actions.loading_index());
        };

        Excel.emitter.addListener('opened', eventHandler);

        Excel.create({ title: ['学员清单'], headers: columns, bodies: students });

        dispatch(Actions.loading_index());
    };

    const handleRowSelect = row => {
        setSelected(row);
    };

    const handleColumnChange = columns => {
        // dispatch(GuiActions.update({ key: ['Employee', 'Worker'], value: columns }));
    };

    const handleChange = (evt, values) => {
        const { name, value } = values;
        if (name) {
            dispatch(Actions.show(student.set(name, fromJS(value))));
        } else {
            dispatch(Actions.show(student.merge(fromJS(values))));
        }
    };

    const handleClose = () => {
        dispatch(Actions.show());
    };

    const handleSelect = row => {
        dispatch(Actions.request(row.id));
        return DetailRef?.current?.handleOpen();
    };

    const DetailRef = useRef();
    const DataGridRef = useRef();

    const schools_id = search.get('schools_id') ?? '';

    return (
        <div className="flexHorizontalContent">
            <Search
                search={search}
                project2_coach_options={project2_coach_options}
                project3_coach_options={project3_coach_options}
                onSearch={handleSearch}
            />
            <Sidebar.Pushable className="flexHorizontalContent">
                <DataGrid
                    rowSelect
                    selected={selected}
                    ref={DataGridRef}
                    remit={true}
                    addition={false}
                    loading={loading_index}
                    columns={columns}
                    rows={students}
                    defaultSortColumns={[{ columnKey: 'name', direction: 'ASC' }]}
                    onAction={handleAction}
                    onRowSelect={handleRowSelect}
                    onSelect={handleSelect}
                    onColumnChange={handleColumnChange}
                    customInput={
                        <React.Fragment>
                            <Button
                                color="olive"
                                icon="check"
                                size="tiny"
                                content="确认"
                                action="confirm"
                                disabled={!selected.length}
                                onClick={handleAction}
                            />
                            <Button
                                color="teal"
                                icon="road"
                                size="tiny"
                                content="在学"
                                action="study"
                                disabled={!selected.length}
                                onClick={handleAction}
                            />

                            <Dropdown
                                selectOnNavigation={false}
                                selectOnBlur={false}
                                icon={null}
                                options={[
                                    { value: '科二', text: '科二' },
                                    { value: '科三', text: '科三' },
                                ]}
                                action="pass"
                                value=""
                                onChange={handleAction}
                                disabled={!selected.length}
                                trigger={
                                    <Button
                                        color="green"
                                        icon="thumbs up outline"
                                        size="tiny"
                                        content="通关"
                                        disabled={!selected.length}
                                    />
                                }
                            />

                            <Button
                                color="pink"
                                icon="pause"
                                size="tiny"
                                content="停学"
                                action="pause"
                                disabled={!selected.length}
                                onClick={handleAction}
                            />

                            <Dropdown
                                selectOnNavigation={false}
                                selectOnBlur={false}
                                icon={null}
                                options={simulator_coach_options}
                                action="simulator_coach"
                                value=""
                                onChange={handleAction}
                                disabled={!selected.length || !schools_id}
                                trigger={
                                    <Button
                                        color="orange"
                                        icon="user"
                                        size="tiny"
                                        content="模拟培训"
                                        action="pause"
                                        disabled={!selected.length || !schools_id}
                                    />
                                }
                            />

                            <Dropdown
                                selectOnNavigation={false}
                                selectOnBlur={false}
                                icon={null}
                                options={project2_coach_options}
                                action="project2_coach"
                                value=""
                                onChange={handleAction}
                                disabled={!selected.length || !schools_id}
                                trigger={
                                    <Button
                                        color="orange"
                                        icon="user"
                                        size="tiny"
                                        content="科二教练"
                                        action="pause"
                                        disabled={!selected.length || !schools_id}
                                    />
                                }
                            />

                            <Dropdown
                                selectOnNavigation={false}
                                selectOnBlur={false}
                                icon={null}
                                options={project3_coach_options}
                                action="project3_coach"
                                value=""
                                onChange={handleAction}
                                disabled={!selected.length || !schools_id}
                                trigger={
                                    <Button
                                        color="orange"
                                        icon="user"
                                        size="tiny"
                                        content="科三教练"
                                        action="pause"
                                        disabled={!selected.length || !schools_id}
                                    />
                                }
                            />
                        </React.Fragment>
                    }
                />
                <Suspense fallback={null}>
                    <Detail
                        user
                        schools_options={schools_options}
                        ref={DetailRef}
                        student={student}
                        coach_options={coach_options}
                        onAction={handleAction}
                        onChange={handleChange}
                        onClose={handleClose}
                    />
                </Suspense>
            </Sidebar.Pushable>
        </div>
    );
}

export { default as StudentReducer } from './reducers';
export { default as StudentAction } from './actions';
