import Base from '../Base';
import fetch from '../Fetch';

export default class SettingCommand extends Base {
    static url = '/api/setting/command';

    static async record(query) {
        try {
            return await fetch({ url: this.url + '/record', method: 'GET', query });
        } catch (error) {
            throw error;
        }
    }
}
