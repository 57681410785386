import fetch from '../Fetch';

export default class Print {
    static url = '/api/print';

    static async print(body) {
        try {
            return await fetch({ url: this.url, body, method: 'POST', rules: this.rules });
        } catch (error) {
            throw error;
        }
    }

    static async printed() {
        try {
            return await fetch({ url: this.url + '/printed', method: 'POST' });
        } catch (error) {
            throw error;
        }
    }
}
