import * as ACTION_TYPES from './constants';

import { Coach as Api } from '../../Api';

import { Toast } from '../Common';

const reducer = 'CoachReducer';

export default class CoachActions {
    static loading_index = toggle => ({
        type: ACTION_TYPES.COACH_LOADING_INDEX,
        toggle,
    });

    static loading_show = toggle => ({
        type: ACTION_TYPES.COACH_LOADING_SHOW,
        toggle,
    });

    static search =
        (search = {}) =>
        async (dispatch, getState) => {
            dispatch({ type: ACTION_TYPES.COACH_SEARCH, search });
        };

    static requests = () => async (dispatch, getState) => {
        try {
            dispatch(this.loading_index(true));
            const query = getState()[reducer].get('search');
            const res = await Api.index(query);
            if (res instanceof Error) throw res;
            dispatch(this.index(res));
        } catch (error) {
            dispatch(this.loading_index(false));
        } finally {
        }
    };

    static index = (coaches = []) => ({
        type: ACTION_TYPES.COACH_INDEX,
        coaches,
    });

    static request = params => async (dispatch, getState) => {
        try {
            dispatch(this.loading_show());
            const res = await Api.show(params);
            if (res instanceof Error) throw res;
            dispatch(this.show(res));
        } catch (error) {
            dispatch(this.loading_show(false));
        } finally {
        }
    };

    static update = () => async (dispatch, getState) => {
        try {
            dispatch(this.loading_show());
            const body = getState()[reducer].get('coach');
            const res = await Api.create(body);
            if (res instanceof Error) throw res;
            dispatch(this.show(res));
            Toast.success('记录已保存');
            dispatch(this.requests());
            dispatch(this.options());
        } catch (error) {
            dispatch(this.loading_show(false));
        } finally {
        }
    };

    static confirm = body => async (dispatch, getState) => {
        try {
            dispatch(this.loading_show());
            const res = await Api.confirm(body);
            if (res instanceof Error) throw res;
            dispatch(this.show(res));
        } catch (error) {
            dispatch(this.loading_show(false));
        } finally {
        }
    };

    static destroy = () => async (dispatch, getState) => {
        try {
            const params = getState()[reducer].getIn(['coach', 'id']);
            dispatch(this.loading_show());
            const res = await Api.destroy(params);
            if (res instanceof Error) throw res;
            dispatch(this.show(res));
            Toast.success('记录已删除');
            dispatch(this.requests());
        } catch (error) {
            dispatch(this.loading_show());
        } finally {
        }
    };

    static show = (coach = {}) => ({
        type: ACTION_TYPES.COACH_SHOW,
        coach,
    });

    static reset = () => ({
        type: ACTION_TYPES.COACH_RESET,
    });

    static column = (columns = []) => ({
        type: ACTION_TYPES.COACH_COLUMN,
        columns,
    });

    static options = () => async (dispatch, getState) => {
        try {
            const res = await Api.options();
            if (res instanceof Error) throw res;
            dispatch({
                type: ACTION_TYPES.COACH_OPTION,
                options: res,
            });
        } catch (error) {
        } finally {
        }
    };
}
