import React, { useEffect } from 'react';
import { is } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Form, Segment } from 'semantic-ui-react';

import Actions from './actions';
import { Toast } from '../../Common';

export default function SettingToken(props) {
    const dispatch = useDispatch();

    const loading_index = useSelector(
        state => state.SettingTokenReducer.get('loading_index'),
        (a, b) => is(a, b)
    );

    const tokens = useSelector(
        state => state.SettingTokenReducer.get('tokens'),
        (a, b) => is(a, b)
    );

    useEffect(() => {
        dispatch(Actions.requests());
        return () => {
            dispatch(Actions.reset());
        };
    }, []);

    const handleAction = (evt, values) => {
        const { action, value, item } = values;
        switch (action) {
            case 'create':
                return dispatch(Actions.create(item));
            case 'copy':
                navigator.clipboard.writeText(value).then(() => {
                    Toast.success('令牌已复制');
                });
            default:
        }
    };

    const items = [
        { value: 'student', label: '学员小程序' },
        { value: 'school', label: '驾校小程序' },
        { value: 'simulator', label: '模拟器' },
    ];

    return (
        <div className="flexHorizontalContent">
            {items.map(item => (
                <Segment>
                    <Form widths="equal">
                        <Form.Group>
                            <Form.Input fluid readOnly label="类别" name="student" value={item.label} />
                            <Form.Field width={2}>
                                <label>操作</label>
                                <Button.Group fluid>
                                    <Button
                                        content="生成"
                                        color="blue"
                                        onClick={evt => handleAction(evt, { action: 'create', item })}
                                    />
                                    <Button
                                        content="复制"
                                        disabled={!tokens.get(item.value)}
                                        color="green"
                                        onClick={evt =>
                                            handleAction(evt, { action: 'copy', value: tokens.get(item.value) ?? '' })
                                        }
                                    />
                                </Button.Group>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.TextArea
                                readOnly
                                label="令牌"
                                name={item.value}
                                value={tokens.get(item.value) ?? ''}
                            />
                        </Form.Group>
                    </Form>
                </Segment>
            ))}
        </div>
    );
}

export { default as SettingTokenReducer } from './reducers';
export { default as SettingTokenAction } from './actions';
