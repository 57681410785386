import { fromJS, isImmutable } from 'immutable';

import * as ACTION_TYPES from './constants';

const initialState = fromJS({
    windows: [],
    active: { content: '工作台', key: 'Main' },
    version: '',
    statistic: {},
    onlines: [],
    usings: [],
    gui: {},
    status: {},
});

export default function MainReducer(state = initialState, action) {
    const windows = state.get('windows');

    let { window } = action;
    window = isImmutable(window) ? window.toJS() : window;

    const index = state.get('windows').findIndex(w => w.get('key') === window?.key);

    switch (action.type) {
        case ACTION_TYPES.WINDOW_OPEN:
            return (() => {
                if (index > -1) return state.set('active', fromJS(window));

                const updated = windows.push(fromJS(window));

                return state.set('windows', updated).set('active', fromJS(window));
            })();
        case ACTION_TYPES.WINDOW_ACTIVE:
            return state.set('active', fromJS(action.window));

        case ACTION_TYPES.WINDOW_CLOSE:
            return (() => {
                const updated = windows.delete(index);
                let active = state.get('active');
                const isCurrent = active.get('key') === window.key;

                switch (true) {
                    case !updated.size:
                        active = initialState.get('active');
                        break;
                    case isCurrent:
                        if (index === updated.size) {
                            active = updated.get(index - 1);
                        } else {
                            active = updated.get(index);
                        }
                        break;
                    default:
                }

                return state.set('windows', updated).set('active', active);
            })();

        case ACTION_TYPES.WINDOW_RESET:
            return initialState;

        case ACTION_TYPES.WINDOW_VERSION:
            return state.set('version', action.version);

        case ACTION_TYPES.WINDOW_STATISTIC:
            return state.set('statistic', fromJS(action.statistic));

        case ACTION_TYPES.WINDOW_STATUS:
            return state.set('status', fromJS(action.status));

        case ACTION_TYPES.WINDOW_GUI:
            return state.set('gui', fromJS(action.gui));

        case ACTION_TYPES.WINDOW_ONLINE:
            return state.set('onlines', fromJS(action.onlines));

        case ACTION_TYPES.WINDOW_USING:
            return state.set('usings', fromJS(action.usings));

        default:
            return state;
    }
}
