import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import md5 from 'md5';
import SliderCaptcha, { ActionType } from 'rc-slider-captcha';

import { Form, Header, Input, Menu, Segment } from 'semantic-ui-react';

import styles from './styles.module.scss';
import Actions from './actions';
import { is } from 'immutable';
import { Toast } from '../Common';

export default function Login(props) {
    const actionRef = useRef();

    const user = useSelector(
        state => state.LoginReducer.get('user'),
        (a, b) => is(a, b)
    );

    const [showPassword, setShowPassword] = useState(false);
    const [captcha, setCaptcha] = useState('');
    const [verify, setVerify] = useState(false);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    useEffect(() => {
        if (sessionStorage.getItem('token') || localStorage.getItem('token')) {
            if (user.isEmpty()) {
                dispatch(Actions.token());
            }
        }
    }, []);

    useEffect(() => {
        if (sessionStorage.getItem('token') || localStorage.getItem('token')) {
            if (!user.isEmpty()) {
                navigate('/Main');
            }
        }
    }, [user]);

    const handleSubmit = evt => {
        let username = '';
        let password = '';

        for (let i = 0; i < evt.target.elements.length; i++) {
            const element = evt.target.elements[i];
            switch (true) {
                case element.name.includes('username'):
                    username = element.value;
                    break;
                case element.name.includes('password'):
                    password = element.value;
                    break;
                default:
            }
        }

        if (!username) return evt.target.elements['username'].focus();
        if (!password) return evt.target.elements['password'].focus();

        dispatch(Actions.login({ username, password: md5(password) }));
    };

    const CaptchaRef = useRef();

    const randomName = Math.round(Math.random() * 10000)
        .toString()
        .padStart(6, '0');

    return (
        <div className={styles.container} style={{ background: 'url(/background.jpg)' }}>
            <Menu
                inverted
                attached
                borderless
                size="massive"
                // onDoubleClick={evt => handleAction(evt, { action: 'maximize' })}
            >
                {/*<Menu.Menu position="right">*/}
                {/*    <Menu.Item link icon="minus" action="minimize" onClick={handleAction} />*/}
                {/*    <Menu.Item link icon="window maximize outline" action="maximize" onClick={handleAction} />*/}
                {/*    <Menu.Item link icon="close" action="close" onClick={handleAction} />*/}
                {/*</Menu.Menu>*/}
            </Menu>
            <Segment className="content">
                <Form widths="equal" size="large" onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Field>
                            <Header size="large" textAlign="center" content="学车宝模拟器管理" />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Input
                            autoFocus
                            fluid
                            required
                            // iconPosition="right"
                            // icon="user"
                            name={'username' + randomName}
                            label="登入账号"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Input
                            required
                            fluid
                            icon={{
                                name: showPassword ? 'eye slash' : 'eye',
                                link: true,
                                onClick: () => setShowPassword(!showPassword),
                            }}
                            type={showPassword ? 'text' : 'password'}
                            name={'password' + randomName}
                            label="登入密码"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field>
                            <SliderCaptcha
                                mode="slider"
                                tipText={{
                                    default: '请按住滑块，拖动到最右边',
                                    moving: '请按住滑块，拖动到最右边',
                                    error: '验证失败，请重新操作',
                                    success: '验证成功',
                                }}
                                errorHoldDuration={1000}
                                // 手动设置拼图宽度等于滑块宽度。后面大版本更新会将该模式下的拼图宽度改为和滑块宽度一致。
                                puzzleSize={{
                                    width: 40,
                                }}
                                onVerify={data => {
                                    if (data.x === 280) {
                                        setVerify(true);
                                        return Promise.resolve();
                                    }
                                    return Promise.reject();
                                }}
                                actionRef={actionRef}
                            />
                        </Form.Field>
                    </Form.Group>

                    {/*<Form.Group>*/}
                    {/*    <Form.Field>*/}
                    {/*        <label>验证码</label>*/}
                    {/*        <div className="captcha">*/}
                    {/*            <Input*/}
                    {/*                autoComplete="off"*/}
                    {/*                type="password"*/}
                    {/*                required*/}
                    {/*                maxLength="4"*/}
                    {/*                name={'captcha' + randomName}*/}
                    {/*            />*/}
                    {/*            <Captcha*/}
                    {/*                ref={CaptchaRef}*/}
                    {/*                charNum={4}*/}
                    {/*                onClick={() => CaptchaRef?.current.refresh()}*/}
                    {/*                onChange={value => value && setCaptcha(value)}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </Form.Field>*/}
                    {/*</Form.Group>*/}
                    <Form.Group>
                        <Form.Button type="submit" disabled={!verify} fluid size="large" content="登入" color="green" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field></Form.Field>
                    </Form.Group>
                </Form>
            </Segment>
            <div className="footer">©2024 佛山市依时利新科技有限公司 粤ICP备09098372号</div>
        </div>
    );
}

export { default as LoginReducer } from './reducers';
export { default as LoginAction } from './actions';
