import { is, Map } from 'immutable';
import React from 'react';

import { Button, Dropdown, Form } from 'semantic-ui-react';

import { DatePicker } from '../../../Common';
import { useSelector } from 'react-redux';

export default function FinanceSearch(props) {
    const { selected = [], onAction = () => {}, onSearch = () => {}, search = Map() } = props;

    const customers_options = useSelector(
        state => state.SettingCustomerReducer.get('options'),
        (a, b) => is(a, b)
    ).toJS();

    const customer = search.get('customer') ?? '';
    const status = search.get('status') ?? '';
    const year = search.get('year') ?? '';

    return (
        <Form size="tiny" widths="equal">
            <Form.Group>
                <Form.Field width={10}>
                    <Button
                        icon="refresh"
                        color="teal"
                        type="button"
                        size="tiny"
                        content="刷新"
                        action="refresh"
                        onClick={onAction}
                    />
                </Form.Field>
                <Form.Select
                    fluid
                    width={2}
                    clearable
                    search
                    placeholder="客户"
                    options={customers_options}
                    name="customer"
                    customer={customer}
                    onChange={onSearch}
                />
                <DatePicker
                    width={1}
                    mode="form"
                    showYearPicker
                    placeholder="对账月份"
                    pattern="yyyy"
                    name="year"
                    value={year}
                    onChange={(evt, { name, value }) => onSearch(evt, { name, value: value + '-01-01' })}
                />
                <Form.Select
                    width={1}
                    fluid
                    placeholder="状态"
                    options={[
                        { value: 1, text: '未收' },
                        { value: 0, text: '已收' },
                    ]}
                    name="status"
                    value={status}
                    onChange={onSearch}
                />
            </Form.Group>
        </Form>
    );
}
