import { is } from 'immutable';

import React, { lazy, Suspense, useEffect, useRef } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Menu, Segment, Sidebar } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import LeftMenu from './LeftMenu';
import HeadMenu from './HeadMenu';
import TabMenu from './TabMenu';

import styles from './styles.module.scss';

import Actions from './actions';
import LoginActions from '../Login/actions';

const Assessment = lazy(() => import('../Assessment'));
const Booking = lazy(() => import('../Booking'));
const Coach = lazy(() => import('../Coach'));
const Student = lazy(() => import('../Student'));
const School = lazy(() => import('../School'));
const Content = lazy(() => import('./Content'));
const Setting = lazy(() => import('../Setting'));
const Simulator = lazy(() => import('../Simulator'));
const Statement = lazy(() => import('../Statement'));
const Clearance = lazy(() => import('../Clearance'));

const Password = lazy(() => import('./Password'));
const PrintPreview = lazy(() => import('../Common/PrintPreview'));
const FilePreview = lazy(() => import('../Common/FilePreview'));

export default function Main(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector(
        state => state.LoginReducer.get('user'),
        (a, b) => is(a, b)
    );

    const status = useSelector(
        state => state.MainReducer.get('status'),
        (a, b) => is(a, b)
    );

    const windows = useSelector(
        state => state.MainReducer.get('windows'),
        (a, b) => is(a, b)
    );

    const version = useSelector(
        state => state.MainReducer.get('version'),
        (a, b) => is(a, b)
    );

    const active = useSelector(
        state => state.MainReducer.get('active'),
        (a, b) => is(a, b)
    );

    useEffect(() => {
        dispatch(Actions.version());
        dispatch(Actions.socket());
        dispatch(LoginActions.token());
        return () => {
            dispatch(Actions.reset());
        };
    }, []);

    useEffect(() => {
        if (!active.isEmpty()) {
            const key = active.get('key');
            if (key === 'Main') {
                return navigate('/Main');
            }
            const route = key.split('.').join('/');
            return navigate(route);
        }
    }, [active]);

    useEffect(() => {
        if (!user.isEmpty() && !user.get('admin')) {
            dispatch(Actions.status());
        }
    }, [user]);

    const handleTopMenuClick = (evt, values) => {
        const { action } = values;
        if (action === 'dashboard') {
            return navigate('/Dashboard');
        }
        if (action === 'main') {
            return navigate('/Main');
        }

        if (values.url) {
            window.open(values.url, '_blank');
        } else {
            return dispatch(Actions.open(values));
        }
    };

    const handleAction = (evt, { action }) => {
        switch (action) {
            case 'password':
                PasswordRef?.current?.handleOpen();
                break;
            case 'logout':
                dispatch(LoginActions.reset());
                document.location.replace('/');
                break;
            case 'dashboard':
                return navigate('/Dashboard', { replace: true });
        }
    };

    const PasswordRef = useRef();

    const notification = status.get('notification');
    const overdue = status.get('overdue') ?? false;

    return (
        <Sidebar.Pushable as={Segment.Group} className={styles.container} horizontal>
            <LeftMenu user={user} version={version} onClick={handleTopMenuClick} />
            <Segment attached style={{ display: 'flex', padding: 0, flexDirection: 'column' }}>
                <HeadMenu user={user} onAction={handleAction} />
                <Segment attached style={{ display: 'flex', flex: 1, height: 0, margin: 0, flexDirection: 'column' }}>
                    <TabMenu />
                    <Segment attached className={styles.content}>
                        <Suspense fallback={null}>
                            <Routes>
                                <Route path="/Assessment/*" element={<Assessment />} />
                                <Route path="/School/*" element={<School />} />
                                <Route path="/Booking/*" element={<Booking />} />
                                <Route path="/Student/*" element={<Student />} />
                                <Route path="/Setting/*" element={<Setting />} />
                                <Route path="/Status/*" element={<Simulator />} />
                                <Route path="/Coach/*" element={<Coach />} />
                                <Route path="/Statement/*" element={<Statement />} />
                                <Route path="/Clearance/*" element={<Clearance />} />
                                {/*<Route path="/" element={<Content />} />*/}
                                <Route path="/" element={<Content />} />
                            </Routes>
                            <Password ref={PasswordRef} />
                            <PrintPreview />
                            <FilePreview />
                        </Suspense>
                    </Segment>
                </Segment>
                {(!!notification || overdue) && (
                    <Menu attached compact inverted color="red" size="small" className={styles.error}>
                        <Menu.Item content={notification} />
                    </Menu>
                )}
            </Segment>
        </Sidebar.Pushable>
    );
}

export { default as MainReducer } from './reducers';
export { default as MainAction } from './actions';
