import { Button, Form } from 'semantic-ui-react';
import React from 'react';
import { Map } from 'immutable';

export default function (props) {
    const { selected = [], onAction = () => {}, onSearch = () => {}, search = Map() } = props;

    const disabled = search.get('disabled') ?? '';

    return (
        <Form size="tiny" widths="equal">
            <Form.Group>
                <Form.Field width={15}>
                    <Button
                        icon="refresh"
                        color="teal"
                        type="button"
                        size="tiny"
                        content="刷新"
                        action="refresh"
                        onClick={onAction}
                    />
                    <Button
                        icon="add"
                        color="blue"
                        type="button"
                        size="tiny"
                        content="新增"
                        action="add"
                        onClick={onAction}
                    />
                </Form.Field>
                <Form.Select
                    fluid
                    width={1}
                    search
                    placeholder="状态"
                    value={disabled}
                    options={[
                        { value: false, text: '正常' },
                        { value: true, text: '停用' },
                    ]}
                    name="disabled"
                    onChange={onSearch}
                />
            </Form.Group>
        </Form>
    );
}
