import Base from '../Base';
import fetch from '../Fetch';

export default class Coach extends Base {
    static url = '/api/statement';

    static async confirm(body) {
        try {
            return await fetch({ url: this.url + '/confirm', body, method: 'POST' });
        } catch (error) {
            throw error;
        }
    }
}
