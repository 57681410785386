import Base from '../Base';
import fetch from '../Fetch';

export default class SettingVersion extends Base {
    static url = '/api/setting/version';

    static rules = {
        app: { name: 'APP 类型', required: true },
        no: { name: '版本号', required: true },
        url: { name: '下载路径', required: true },
    };

    static async create(formData, body) {
        try {
            return await fetch({ url: this.url, formData, body, method: 'POST', rules: this.rules });
        } catch (error) {
            throw error;
        }
    }
}
