import React from 'react';

import { Icon, Image, Modal } from 'semantic-ui-react';

export default function ImageFormatter(props) {
    const { row, column } = props;
    const value = row[column.key];

    return (
        <div style={{ textAlign: 'center' }}>
            {!!value && (
                <Modal
                    basic
                    closeIcon
                    size="fullscreen"
                    trigger={<Icon name="image" link />}
                    content={<Image fluid src={value} />}
                />
            )}
        </div>
    );
}
