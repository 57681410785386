import { fromJS } from 'immutable';
import moment from 'moment';

import * as ACTION_TYPES from './constants';
import { NumericFormatter } from '../Common';

const initialState = fromJS({
    loading_index: false,
    loading_show: false,
    search: {
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().endOf('month').format('YYYY-MM-DD'),
        date_mode: 'current',
        status: 1,
    },
    students: [],
    student: {},
    options: [],
    schools: [],
    columns: [
        { key: 'confirm', name: '确认', width: 80, frozen: true },
        { key: 'status', name: '状态', width: 80, frozen: true },
        { key: 'project', name: '科目', width: 100, frozen: true },
        { key: 'name', name: '姓名', width: 80, frozen: true },
        { key: 'sex', name: '性别', width: 60, frozen: true },
        { key: 'age', name: '年龄', width: 60, frozen: true },
        { key: 'mobile', name: '手机', width: 130, frozen: true },
        { key: 'license', name: '考照类型', width: 80 },
        { key: 'created_at', name: '注册日期', width: 160 },
        { key: 'schools_name', name: '驾校', minWidth: 200 },
        { key: 'simulator_coaches_name', name: '模拟器培训教练', width: 120 },
        { key: 'project2_coaches_name', name: '科二教练', width: 120 },
        { key: 'project3_coaches_name', name: '科三教练', width: 120 },
        // { key: 'learn_hours', name: '学习时数', width: 60, formatter: NumericFormatter, fixed: 2 },
        { key: 'basic_rate', name: '基础', width: 60, formatter: NumericFormatter, unit: '%' },
        { key: 'project2_rate', name: '科二', width: 60, formatter: NumericFormatter, unit: '%' },
        { key: 'project3_rate', name: '科三', width: 60, formatter: NumericFormatter, unit: '%' },
    ],
});
export default function StudentReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.STUDENT_LOADING_INDEX:
            return action.toggle
                ? state.set('loading_index', action.toggle)
                : state.update('loading_index', loading => !loading);

        case ACTION_TYPES.STUDENT_LOADING_SHOW:
            return action.toggle
                ? state.set('loading_show', action.toggle)
                : state.update('loading_show', loading => !loading);

        case ACTION_TYPES.STUDENT_SEARCH:
            return state.set('search', fromJS(action.search));

        case ACTION_TYPES.STUDENT_INDEX:
            return state.set('students', fromJS(action.students)).set('loading_index', false);

        case ACTION_TYPES.STUDENT_SHOW:
            return state.set('student', fromJS(action.student)).set('loading_show', false);

        case ACTION_TYPES.STUDENT_OPTION:
            return state.set('options', fromJS(action.options));

        case ACTION_TYPES.STUDENT_SCHOOL:
            return state.set('schools', fromJS(action.schools));

        case ACTION_TYPES.STUDENT_RESET:
            return initialState.set('options', state.get('options')).set('columns', state.get('columns'));

        default:
            return state;
    }
}
