import { fromJS } from 'immutable';

import * as ACTION_TYPES from './constants';
import { NumericFormatter } from '../Common';

const initialState = fromJS({
    loading_index: false,
    loading_show: false,
    search: {
        schools_id: '',
        disabled: false,
    },
    coaches: [],
    coach: {},
    options: [],
    columns: [
        { key: 'name', name: '姓名', width: 100, frozen: true },
        { key: 'mobile', name: '手机', width: 150, frozen: true },
        { key: 'schools_name', name: '驾校', minWidth: 200 },
        {
            key: 'kind',
            name: '科目',
            width: 300,
        },
        { key: 'simulator_students_count', name: '模拟培训学员', width: 120, formatter: NumericFormatter },
        { key: 'project2_students_count', name: '科二学员', width: 120, formatter: NumericFormatter },
        { key: 'project3_students_count', name: '科三学员', width: 120, formatter: NumericFormatter },
        { key: 'basic_rate', name: '基础通关', width: 80, formatter: NumericFormatter, unit: '%' },
        { key: 'project2_rate', name: '科二通关', width: 80, formatter: NumericFormatter, unit: '%' },
        { key: 'project3_rate', name: '科三通关', width: 80, formatter: NumericFormatter, unit: '%' },
        { key: 'project2_confirm_at', name: '科二确认', width: 100 },
        { key: 'project3_confirm_at', name: '科三确认', width: 100 },
    ],
});
export default function CoachReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.COACH_LOADING_INDEX:
            return action.toggle
                ? state.set('loading_index', action.toggle)
                : state.update('loading_index', loading => !loading);

        case ACTION_TYPES.COACH_LOADING_SHOW:
            return action.toggle
                ? state.set('loading_show', action.toggle)
                : state.update('loading_show', loading => !loading);

        case ACTION_TYPES.COACH_SEARCH:
            return state.set('search', fromJS(action.search));

        case ACTION_TYPES.COACH_INDEX:
            return state.set('coaches', fromJS(action.coaches)).set('loading_index', false);

        case ACTION_TYPES.COACH_SHOW:
            return state.set('coach', fromJS(action.coach)).set('loading_show', false);

        case ACTION_TYPES.COACH_OPTION:
            return state.set('options', fromJS(action.options));

        case ACTION_TYPES.COACH_RESET:
            return initialState.set('options', state.get('options')).set('columns', state.get('columns'));

        default:
            return state;
    }
}
