export const SETTING_VERSION_COLUMN = 'SETTING_VERSION_COLUMN';
export const SETTING_VERSION_INDEX = 'SETTING_VERSION_INDEX';
export const SETTING_VERSION_LOADING_INDEX = 'SETTING_VERSION_LOADING_INDEX';
export const SETTING_VERSION_LOADING_SHOW = 'SETTING_VERSION_LOADING_SHOW';
export const SETTING_VERSION_OPTION = 'SETTING_VERSION_OPTION';
export const SETTING_VERSION_RESET = 'SETTING_VERSION_RESET';
export const SETTING_VERSION_SEARCH = 'SETTING_VERSION_SEARCH';
export const SETTING_VERSION_SHOW = 'SETTING_VERSION_SHOW';

export const APP_OPTIONS = [
    { value: 'student', text: '学员小程序' },
    { value: 'school', text: '驾校小程序' },
];
