import Base from '../Base';
import fetch from '../Fetch';

export default class SettingScholar extends Base {
    static url = '/api/setting/scholar';

    static rules = {
        project2_coaches_id: { name: '科目二教练', required: true },
        project3_coaches_id: { name: '科目三教练', required: true },
        simulator_coaches_id: { name: '模拟培训教练', required: true },
    };

    static async remove(body) {
        try {
            return await fetch({ url: this.url + '/remove', body, method: 'POST' });
        } catch (error) {
            throw error;
        }
    }

    static async exclude(body) {
        try {
            return await fetch({ url: this.url + '/exclude', body, method: 'POST' });
        } catch (error) {
            throw error;
        }
    }

    static async project(body) {
        try {
            return await fetch({ url: this.url + '/project', body, method: 'POST' });
        } catch (error) {
            throw error;
        }
    }

    static async school(body) {
        try {
            return await fetch({ url: this.url + '/school' });
        } catch (error) {
            throw error;
        }
    }
}
