import { fromJS } from 'immutable';

import * as ACTION_TYPES from './constants';

const initialState = fromJS({
    loading: false,
    visible: false,
    templates: [],
    content: {},
    param: {},
    printed: {},
    pages: [],
});

export default function CommonPrintPreviewReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.PRINT_PREVIEW_LOADING:
            return state.update('loading', loading => !loading);

        case ACTION_TYPES.PRINT_PREVIEW_SHOW:
            return state.set('visible', true).set('param', fromJS(action.param));

        case ACTION_TYPES.PRINT_PREVIEW_CONTENT:
            return state.merge(fromJS(action.content)).set('loading', false);

        case ACTION_TYPES.PRINT_PREVIEW_PAGE:
            return state.set('pages', fromJS(action.pages)).set('loading', false);

        case ACTION_TYPES.PRINT_PREVIEW_HIDE:
            return state.set('visible', false);

        case ACTION_TYPES.PRINT_PREVIEW_PRINTED:
            return state.set('printed', fromJS(action.printed));

        case ACTION_TYPES.PRINT_PREVIEW_RESET:
            return initialState;

        default:
            return state;
    }
}
