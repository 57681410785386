import Base from '../Base';

export default class Worker extends Base {
    static url = '/api/setting/worker';

    static rules = {
        name: { name: '名称', required: true },
        mobile: { name: '手机', required: true },
    };
}
