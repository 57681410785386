import Base from '../Base';
import fetch from '../Fetch';

export default class Booking extends Base {
    static url = '/api/booking';

    static async destroy(body) {
        try {
            return await fetch({ url: this.url + '/destroy', method: 'POST', body });
        } catch (error) {
            throw error;
        }
    }

    static async setting_request(params) {
        try {
            return await fetch({ url: this.url + '/setting', method: 'GET' });
        } catch (error) {
            throw error;
        }
    }

    static async setting_create(body) {
        try {
            return await fetch({ url: this.url + '/setting', body, method: 'POST' });
        } catch (error) {
            throw error;
        }
    }
}
