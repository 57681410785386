import { fromJS } from 'immutable';
import moment from 'moment';

import * as ACTION_TYPES from './constants';
import { Icon } from 'semantic-ui-react';
import { NumericFormatter } from '../../Common';

const initialState = fromJS({
    loading_index: false,
    loading_show: false,
    search: {
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().endOf('month').format('YYYY-MM-DD'),
        date_mode: 'current',
        status: 1,
    },
    campuses: [],
    campus: {},
    options: [],
    columns: [
        { key: 'customers_abbr', name: '客户', width: 200 },
        { key: 'name', name: '名称', width: 200 },
        { key: 'simulators_count', name: '模拟器', width: 70, formatter: NumericFormatter },
        { key: 'students_count', name: '学员数', width: 70, formatter: NumericFormatter },
        { key: 'project', name: '科目', width: 100 },
        { key: 'address', name: '地址', minWidth: 300 },
    ],
});
export default function SettingCampusReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.SETTING_CAMPUS_LOADING_INDEX:
            return action.toggle
                ? state.set('loading_index', action.toggle)
                : state.update('loading_index', loading => !loading);

        case ACTION_TYPES.SETTING_CAMPUS_LOADING_SHOW:
            return action.toggle
                ? state.set('loading_show', action.toggle)
                : state.update('loading_show', loading => !loading);

        case ACTION_TYPES.SETTING_CAMPUS_SEARCH:
            return state.set('search', fromJS(action.search));

        case ACTION_TYPES.SETTING_CAMPUS_INDEX:
            return state.set('campuses', fromJS(action.campuses)).set('loading_index', false);

        case ACTION_TYPES.SETTING_CAMPUS_SHOW:
            return state
                .set('campus', initialState.get('campus').merge(fromJS(action.campus)))
                .set('loading_show', false);

        case ACTION_TYPES.SETTING_CAMPUS_OPTION:
            return state.set('options', fromJS(action.options));

        case ACTION_TYPES.SETTING_CAMPUS_RESET:
            return initialState.set('options', state.get('options')).set('columns', state.get('columns'));

        default:
            return state;
    }
}
