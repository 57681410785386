import * as ACTION_TYPES from './constants';

import { Booking as Api } from '../../Api';

import { Toast } from '../Common';

const reducer = 'BookingReducer';

export default class BookingScheduleActions {
    static loading_index = toggle => ({
        type: ACTION_TYPES.BOOKING_LOADING_INDEX,
        toggle,
    });

    static loading_show = toggle => ({
        type: ACTION_TYPES.BOOKING_LOADING_SHOW,
        toggle,
    });

    static search =
        (search = {}) =>
        async (dispatch, getState) => {
            dispatch({ type: ACTION_TYPES.BOOKING_SEARCH, search });
        };

    static requests = () => async (dispatch, getState) => {
        try {
            dispatch(this.loading_index(true));
            const query = getState()[reducer].get('search');
            const res = await Api.index(query);
            if (res instanceof Error) throw res;
            dispatch(this.index(res));
        } catch (error) {
            dispatch(this.loading_index(false));
            console.error(error);
        } finally {
        }
    };

    static index = (bookings = []) => ({
        type: ACTION_TYPES.BOOKING_INDEX,
        bookings,
    });

    static request = params => async (dispatch, getState) => {
        try {
            dispatch(this.loading_show());
            const res = await Api.show(params);
            if (res instanceof Error) throw res;
            dispatch(this.show(res));
        } catch (error) {
            dispatch(this.loading_show(false));
            console.error(error);
        } finally {
        }
    };

    static create = body => async (dispatch, getState) => {
        try {
            dispatch(this.loading_show());
            const res = await Api.create(body);
            if (res instanceof Error) throw res;
            dispatch(this.show(res));
            Toast.success('记录已保存');
            dispatch(this.requests());
        } catch (error) {
            dispatch(this.loading_show(false));
            console.error(error);
        } finally {
        }
    };

    static update = () => async (dispatch, getState) => {
        try {
            dispatch(this.loading_show());
            const body = getState()[reducer].get('booking');
            const res = await Api.update(body);
            if (res instanceof Error) throw res;
            dispatch(this.show(res));
            Toast.success('记录已保存');
            dispatch(this.requests());
        } catch (error) {
            dispatch(this.loading_show(false));
            console.error(error);
        } finally {
        }
    };

    static destroy = body => async (dispatch, getState) => {
        try {
            dispatch(this.loading_show());
            const res = await Api.destroy(body);
            if (res instanceof Error) throw res;
            Toast.success('记录已删除');
            dispatch(this.requests());
        } catch (error) {
            dispatch(this.loading_show());
            console.error(error);
        } finally {
        }
    };

    static show = (booking = {}) => ({
        type: ACTION_TYPES.BOOKING_SHOW,
        booking,
    });

    static reset = () => ({
        type: ACTION_TYPES.BOOKING_RESET,
    });

    static setting_request = () => async (dispatch, getState) => {
        try {
            const res = await Api.setting_request();
            if (res instanceof Error) throw res;
            dispatch(this.setting(res));
        } catch (error) {
            console.error(error);
        }
    };

    static setting_create = () => async (dispatch, getState) => {
        try {
            dispatch(this.loading_show());
            const body = getState()[reducer].get('setting');
            const res = await Api.setting_create(body);
            if (res instanceof Error) throw res;
            Toast.success('记录已保存');
            dispatch(this.setting(res));
        } catch (error) {
            dispatch(this.loading_show());
            console.error(error);
        }
    };

    static setting = (setting = {}) => ({
        type: ACTION_TYPES.BOOKING_SETTING,
        setting,
    });
}
