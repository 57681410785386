import React from 'react';

export default function NumericFormatter(props) {
    const {
        column: { key, fixed = 0, zero, zeroEmpty, unit, multiple = 1 },
        row,
    } = props;

    let value = row[key];

    value = (isNaN(Number(value)) ? null : Number(value)) * multiple;

    const negative = value < 0;

    const format = new Intl.NumberFormat('zh-CN', {
        minimumFractionDigits: fixed,
        maximumFractionDigits: fixed,
    }).format;

    return (
        <div style={{ textAlign: 'right', color: negative ? 'red' : 'inherit', overflow: 'hidden' }}>
            {(() => {
                switch (true) {
                    case value === 0 && zeroEmpty:
                        return '';
                    case value === null && zero:
                        return format(0);
                    case value === null && !zero:
                        return '';
                    default:
                        return format(value);
                }
            })()}
            {unit}
        </div>
    );
}
