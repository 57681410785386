export const SETTING_CUSTOMER_COLUMN = 'SETTING_CUSTOMER_COLUMN';
export const SETTING_CUSTOMER_INDEX = 'SETTING_CUSTOMER_INDEX';
export const SETTING_CUSTOMER_LOADING_INDEX = 'SETTING_CUSTOMER_LOADING_INDEX';
export const SETTING_CUSTOMER_LOADING_SHOW = 'SETTING_CUSTOMER_LOADING_SHOW';
export const SETTING_CUSTOMER_LOADING_RESOURCE = 'SETTING_CUSTOMER_LOADING_RESOURCE';
export const SETTING_CUSTOMER_OPTION = 'SETTING_CUSTOMER_OPTION';
export const SETTING_CUSTOMER_RESET = 'SETTING_CUSTOMER_RESET';
export const SETTING_CUSTOMER_SEARCH = 'SETTING_CUSTOMER_SEARCH';
export const SETTING_CUSTOMER_SHOW = 'SETTING_CUSTOMER_SHOW';
export const SETTING_CUSTOMER_COURSE = 'SETTING_CUSTOMER_COURSE';
export const SETTING_CUSTOMER_RESOURCE = 'SETTING_CUSTOMER_RESOURCE';
