import { Button, Form } from 'semantic-ui-react';
import React from 'react';
import { is, Map } from 'immutable';
import { useSelector } from 'react-redux';
import { DatePicker } from '../../../Common';

export default function RevenueSearch(props) {
    const { onAction = () => {}, onSearch = () => {}, search = Map() } = props;

    const customers_options = useSelector(
        state => state.SettingCustomerReducer.get('options'),
        (a, b) => is(a, b)
    ).toJS();

    const accounts_options = [
        { value: '私户', text: '私户' },
        { value: '公户', text: '公户' },
    ];

    const customer = search.get('customer') ?? '';
    const start_date = search.get('start_date') ?? '';
    const end_date = search.get('end_date') ?? '';
    const date_mode = search.get('date_mode') ?? '';
    const account = search.get('account') ?? '';

    return (
        <Form size="tiny" widths="equal">
            <Form.Group>
                <Form.Field width={11}>
                    <Button
                        icon="refresh"
                        color="teal"
                        type="button"
                        size="tiny"
                        content="刷新"
                        action="refresh"
                        onClick={onAction}
                    />
                    <Button
                        icon="add"
                        color="blue"
                        type="button"
                        size="tiny"
                        content="新增"
                        action="add"
                        onClick={onAction}
                    />
                    <Button
                        icon="file excel"
                        color="brown"
                        type="button"
                        size="tiny"
                        content="汇出"
                        action="export"
                        onClick={onAction}
                    />
                </Form.Field>
                <DatePicker
                    width={2}
                    mode="search"
                    showMonthYearPicker
                    placeholder="日期"
                    search_mode={date_mode}
                    start_date={start_date}
                    end_date={end_date}
                    onChange={onSearch}
                />
                <Form.Select
                    fluid
                    width={2}
                    clearable
                    search
                    placeholder="客户"
                    options={customers_options}
                    name="customer"
                    value={customer}
                    onChange={onSearch}
                />
                <Form.Select
                    fluid
                    width={1}
                    clearable
                    search
                    placeholder="账户"
                    options={accounts_options}
                    name="account"
                    value={account}
                    onChange={onSearch}
                />
            </Form.Group>
        </Form>
    );
}
