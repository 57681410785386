export const SETTING_SIMULATOR_COLUMN = 'SETTING_SIMULATOR_COLUMN';
export const SETTING_SIMULATOR_INDEX = 'SETTING_SIMULATOR_INDEX';
export const SETTING_SIMULATOR_LOADING_INDEX = 'SETTING_SIMULATOR_LOADING_INDEX';
export const SETTING_SIMULATOR_LOADING_SHOW = 'SETTING_SIMULATOR_LOADING_SHOW';
export const SETTING_SIMULATOR_OPTION = 'SETTING_SIMULATOR_OPTION';
export const SETTING_SIMULATOR_RESET = 'SETTING_SIMULATOR_RESET';
export const SETTING_SIMULATOR_SEARCH = 'SETTING_SIMULATOR_SEARCH';
export const SETTING_SIMULATOR_SHOW = 'SETTING_SIMULATOR_SHOW';

export const LICENSE_OPTIONS = [
    { value: 'C1', text: 'C1-捷达手动' },
    { value: 'C2', text: 'C2-自动档' },
    { value: 'C3', text: 'C3-捷达手动+自动' },
    { value: 'C4', text: 'C4-倒档向后手动' },
    { value: 'C5', text: 'C5-倒档向后手动+自动' },
];
