import { fromJS } from 'immutable';
import { useEffect, useRef } from 'react';
import ReactECharts from 'echarts-for-react';

import styles from './styles.module.scss';

export default function EChart(props) {
    const { type = '', name = '', xAxis = [], series = [] } = props;

    let option = {};

    useEffect(() => {
        const chart = ReactEChartsRef.current.getEchartsInstance();
        let _option = fromJS(option);
        _option = _option.setIn(['xAxis', 'data'], xAxis);
        _option = _option.set('legend', { data: series.filter(s => s.legend !== false).map(({ name }) => name) });
        _option = _option.set(
            'series',
            series.map(s => ({
                type: 'line',
                smooth: true,
                ...s,
            }))
        );
        chart.setOption(_option.toJS());
    }, [xAxis, series]);

    switch (type) {
        case 'pie':
            option = {
                height: '90%',
                width: '90%',
                animation: false,
                tooltip: {
                    trigger: 'item',
                },
                legend: {
                    orient: 'vertical',
                    left: 'left',
                },
                series: [
                    {
                        name,
                        type,
                        radius: '80%',
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 5,
                            borderColor: '#fff',
                            borderWidth: 2,
                        },
                        label: {
                            show: false,
                            position: 'center',
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 40,
                                fontWeight: 'bold',
                            },
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [],
                    },
                ],
            };
            break;
        case 'category':
            option = {
                height: '85%',
                width: '100%',
                animation: false,
                grid: {
                    top: 20,
                    left: 30,
                    right: 0,
                    bottom: 20,
                    width: 'auto',
                    height: 'auto',
                },
                legend: {
                    top: 0,
                    show: true,
                },
                tooltip: {
                    trigger: 'axis',
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    axisLabel: {
                        formatter: value => {
                            return value.split('-').slice(-1);
                        },
                    },
                },
                yAxis: {
                    type: 'value',
                    minInterval: 1,
                },
                series: [
                    {
                        data: [],
                        type: 'line',
                        smooth: true,
                    },
                ],
            };
            break;
        default:
    }

    const ReactEChartsRef = useRef();

    return <ReactECharts ref={ReactEChartsRef} option={option} className={styles.container} style={{ height: 0 }} />;
}
