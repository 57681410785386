import { fromJS } from 'immutable';

import * as ACTION_TYPES from './constants';
import { NumericFormatter } from '../Common';

const initialState = fromJS({
    loading: false,
    courses: [],
    certificate: '',
});
export default function ClearanceReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.ASSESSMENT_LOADING:
            return action.toggle ? state.set('loading', action.toggle) : state.update('loading', loading => !loading);

        case ACTION_TYPES.ASSESSMENT_INDEX:
            return state.set('courses', fromJS(action.courses)).set('loading', false);

        case ACTION_TYPES.ASSESSMENT_CERTIFICATE:
            return state.set('certificate', action.certificate.content);

        case ACTION_TYPES.ASSESSMENT_RESET:
            return initialState;

        default:
            return state;
    }
}
