import Base from '../Base';
import fetch from '../Fetch';

export default class SettingFinance extends Base {
    static url = '/api/setting/finance';

    static async collect_request(query) {
        try {
            return await fetch({ url: this.url + '/collect', query });
        } catch (error) {
            throw error;
        }
    }

    static async collect_create(body) {
        try {
            return await fetch({ url: this.url + '/collect', method: 'POST', body });
        } catch (error) {
            throw error;
        }
    }
}
