import * as ACTION_TYPES from './constants';

import { School as Api } from '../../Api';

import { Toast } from '../Common';

const reducer = 'SchoolReducer';

export default class SchoolActions {
    static loading_index = toggle => ({
        type: ACTION_TYPES.SCHOOL_LOADING_INDEX,
        toggle,
    });

    static loading_show = toggle => ({
        type: ACTION_TYPES.SCHOOL_LOADING_SHOW,
        toggle,
    });

    static requests = () => async (dispatch, getState) => {
        try {
            dispatch(this.loading_index(true));
            const res = await Api.index();
            if (res instanceof Error) throw res;
            dispatch(this.index(res));
        } catch (error) {
            dispatch(this.loading_index(false));
            console.error(error);
        } finally {
        }
    };

    static index = (schools = []) => ({
        type: ACTION_TYPES.SCHOOL_INDEX,
        schools,
    });

    static request = params => async (dispatch, getState) => {
        try {
            dispatch(this.loading_show());
            const res = await Api.show(params);
            if (res instanceof Error) throw res;
            dispatch(this.show(res));
        } catch (error) {
            dispatch(this.loading_show(false));
            console.error(error);
        } finally {
        }
    };

    static create = () => async (dispatch, getState) => {
        try {
            dispatch(this.loading_show());
            const body = getState()[reducer].get('school');
            const res = await Api.create(body);
            if (res instanceof Error) throw res;
            dispatch(this.show(res));
            Toast.success('记录已保存');
            dispatch(this.requests());
            dispatch(this.options());
        } catch (error) {
            dispatch(this.loading_show(false));
            console.error(error);
        } finally {
        }
    };

    static destroy = () => async (dispatch, getState) => {
        try {
            const params = getState()[reducer].getIn(['school', 'id']);
            dispatch(this.loading_show());
            const res = await Api.destroy(params);
            if (res instanceof Error) throw res;
            dispatch(this.show(res));
            Toast.success('记录已删除');
            dispatch(this.requests());
        } catch (error) {
            dispatch(this.loading_show());
            console.error(error);
        } finally {
        }
    };

    static show = (school = {}) => ({
        type: ACTION_TYPES.SCHOOL_SHOW,
        school,
    });

    static reset = () => ({
        type: ACTION_TYPES.SCHOOL_RESET,
    });

    static column = (columns = []) => ({
        type: ACTION_TYPES.SCHOOL_COLUMN,
        columns,
    });

    static options = () => async (dispatch, getState) => {
        try {
            const res = await Api.options();
            if (res instanceof Error) throw res;
            dispatch({
                type: ACTION_TYPES.SCHOOL_OPTION,
                options: res,
            });
        } catch (error) {
            console.error(error);
        } finally {
        }
    };
}
