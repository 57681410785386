import Base from '../Base';
import fetch from '../Fetch';

export default class SettingResource extends Base {
    static url = '/api/setting/resource';

    static rules = {
        resources_groups_id: { name: '分类', required: true },
        name: { name: '名称', required: true },
    };

    static async upload(formData) {
        try {
            return await fetch({ url: this.url + '/upload', method: 'POST', formData });
        } catch (error) {
            throw error;
        }
    }

    static async download(params) {
        try {
            return await fetch({ url: this.url + '/download', params });
        } catch (error) {
            throw error;
        }
    }

    static async remove(body) {
        try {
            return await fetch({ url: this.url + '/remove', method: 'POST', body });
        } catch (error) {
            throw error;
        }
    }

    static async group_index() {
        try {
            return await fetch({ url: this.url + '/group' });
        } catch (error) {
            throw error;
        }
    }

    static async group_create(body) {
        try {
            return await fetch({ url: this.url + '/group', method: 'POST', body });
        } catch (error) {
            throw error;
        }
    }
}
