import fetch from '../Fetch';

export default class Index {
    static rules = {};
    static url = '';

    static async index(query) {
        try {
            return await fetch({ url: this.url, query });
        } catch (error) {
            throw error;
        }
    }

    static async show(params) {
        try {
            return await fetch({ url: this.url, params });
        } catch (error) {
            throw error;
        }
    }

    static async create(body) {
        try {
            return await fetch({ url: this.url, body, method: 'POST', rules: this.rules });
        } catch (error) {
            throw error;
        }
    }

    static async update(body) {
        try {
            return await fetch({ url: this.url, body, method: 'PUT', rules: this.rules });
        } catch (error) {
            throw error;
        }
    }

    static async destroy(params) {
        try {
            return await fetch({ url: this.url, params, method: 'DELETE' });
        } catch (error) {
            throw error;
        }
    }

    static async options(params) {
        try {
            return await fetch({ url: this.url, method: 'OPTIONS' });
        } catch (error) {
            throw error;
        }
    }

    static async patch(body) {
        try {
            return await fetch({ url: this.url, body, method: 'PATCH' });
        } catch (error) {
            throw error;
        }
    }
}
