import { is, isImmutable, Map } from 'immutable';
import React from 'react';
import { Icon, Button, Form, Modal, Image, Segment } from 'semantic-ui-react';
import md5 from 'md5';

import { Features } from '../../../Constants';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';

export default function LeftMenu(props) {
    const { user = Map(), version = '', onClick = () => {} } = props;

    const windows = useSelector(
        state => state.MainReducer.get('windows'),
        (a, b) => is(a, b)
    );

    const [collapse, setCollapse] = React.useState([]);

    const initialState = {
        passwordOpen: false,
        password: '',
        privacy: {},
        error: '',
    };

    const [state, setState] = React.useState(initialState);

    const { passwordOpen, password, privacy, error } = state;

    const handleItemClick = (evt, values) => {
        const { key, children, content } = values;
        if (children) {
            setCollapse(collapse.includes(key) ? collapse.filter(v => v !== key) : collapse.concat(key));
        } else {
            if (windows.find(window => window.get('content') === content)) {
                return onClick(evt, values);
            }

            if (['财务对账', '收支管理'].includes(content)) {
                setState({ ...initialState, passwordOpen: true, privacy: values });
            } else {
                onClick(evt, values);
            }
        }
    };

    const handleSubmit = () => {
        if (md5(password) === 'c86b37b5f6c6b3892c59e7af13aa6446') {
            onClick(null, privacy);
            setState(initialState);
        } else {
            setState({ ...state, error: '密码错误' });
        }
    };

    // 156975

    const Item = props => {
        const { item, is_child, onItemClick = () => {} } = props;
        const { key, children } = item;

        const isCollapsed = collapse.includes(key);

        return (
            <React.Fragment>
                <div className={'item ' + (is_child && ' child')} onClick={evt => onItemClick(evt, item)}>
                    {item.icon && <Icon name={item.icon} />}
                    <span style={{ flex: 1 }}>{item.content}</span>
                    {!!children && <Icon name={isCollapsed ? 'caret down' : 'caret left'} />}
                </div>
                {!!children && !isCollapsed && (
                    <div className="child_container">
                        {children.map(children => (
                            <Item item={children} key={children.key} is_child onItemClick={onItemClick} />
                        ))}
                    </div>
                )}
            </React.Fragment>
        );
    };

    const admin = user.get('admin') ?? false;
    const permission = isImmutable(user.get('permission')) ? user.get('permission').toJS() : false;

    const _features = Features.filter(f => (admin ? f.admin : !f.admin)).filter(f => {
        if (permission instanceof Array) {
            return permission.includes(f.key);
        } else {
            return true;
        }
    });

    return (
        <Segment inverted attached className={styles.container}>
            <div className="title">
                <Image src="/logo.png" size="mini" />
                <h2>学车宝</h2>
            </div>
            <div className="content">
                {_features.map(feature => (
                    <Item item={feature} key={feature.key} onItemClick={handleItemClick} />
                ))}
            </div>
            <div className="footer">{version}</div>
            <Modal
                open={passwordOpen}
                onOpen={() => setState({ ...state, passwordOpen: true })}
                onClose={() => setState(initialState)}
                centered={false}
                size="small">
                <Modal.Header>请输入密码</Modal.Header>
                <Modal.Content>
                    <Form widths="equal" size="huge">
                        <Form.Group>
                            {passwordOpen && (
                                <Form.Input
                                    type="password"
                                    autoComplete="new-password"
                                    onFocus={evt => evt.target.select()}
                                    onChange={(evt, { value }) => setState({ ...state, password: value, error: '' })}
                                    onKeyPress={evt => evt.key === 'Enter' && handleSubmit()}
                                    error={
                                        error
                                            ? {
                                                  content: error,
                                                  pointing: 'above',
                                              }
                                            : null
                                    }
                                />
                            )}
                        </Form.Group>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="green" content="确定" disabled={!password} onClick={handleSubmit} />
                </Modal.Actions>
            </Modal>
        </Segment>
    );
}
