import { Print as Api } from '../../../Api';

import * as ACTION_TYPES from './constants';
import { Toast } from '../';

export default class CommonPrintPreviewAction {
    static loading = () => ({
        type: ACTION_TYPES.PRINT_PREVIEW_LOADING,
    });

    static show = param => async (dispatch, getState) => {
        dispatch({ type: ACTION_TYPES.PRINT_PREVIEW_SHOW, param });
        dispatch(this.request(param));
    };

    static request = body => async (dispatch, getState) => {
        try {
            dispatch(this.loading());
            const res = await Api.print(body);
            if (res instanceof Error) throw res;
            dispatch(this.response(res));
        } catch (error) {
            Toast.error(error);
            dispatch(this.loading());
        }
    };

    static response = (content = { templates: [], content: {} }) => ({
        type: ACTION_TYPES.PRINT_PREVIEW_CONTENT,
        content,
    });

    static pages = (pages = []) => ({
        type: ACTION_TYPES.PRINT_PREVIEW_PAGE,
        pages,
    });

    static printed = body => async (dispatch, getState) => {
        try {
            const res = await Api.printed(body);
            if (res instanceof Error) throw res;
            dispatch({ type: ACTION_TYPES.PRINT_PREVIEW_PRINTED, printed: res });
        } catch (error) {
            Toast.error(error);
        }
    };

    static hide = () => ({
        type: ACTION_TYPES.PRINT_PREVIEW_HIDE,
    });

    static reset = () => ({
        type: ACTION_TYPES.PRINT_PREVIEW_RESET,
    });
}
