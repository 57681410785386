export const WINDOW_OPEN = 'WINDOW_OPEN';
export const WINDOW_CLOSE = 'WINDOW_CLOSE';
export const WINDOW_RESET = 'WINDOW_RESET';
export const WINDOW_ACTIVE = 'WINDOW_ACTIVE';
export const WINDOW_VERSION = 'WINDOW_VERSION';
export const WINDOW_STATISTIC = 'WINDOW_STATISTIC';
export const WINDOW_GUI = 'WINDOW_GUI';
export const WINDOW_ONLINE = 'WINDOW_ONLINE';
export const WINDOW_STATUS = 'WINDOW_STATUS';
export const WINDOW_USING = 'WINDOW_USING';
