import { EventEmitter } from 'events';

import { List } from 'immutable';
import moment from 'moment';
import * as XlsxPopulate from 'xlsx-populate/browser/xlsx-populate';

class Excel {
    static emitter = new EventEmitter();

    static async create({ title = [], headers = [], bodies = List() }) {
        if (bodies.size === 0) return false;

        try {
            const letterPart = Math.floor(headers.length / 26);
            const letterRemain = Math.floor(headers.length % 26);

            const firstLetter = Array.from(Array(letterPart + (letterRemain ? 1 : 0)).keys()).map((a, i) =>
                i > 0 ? String.fromCharCode(64 + i) : ''
            );

            const columns = firstLetter
                .map((f, i, a) =>
                    Array.from(Array(i === a.length - 1 && letterRemain > 0 ? letterRemain : 26).keys())
                        .map((a, i) => String.fromCharCode(65 + i))
                        .map(l => f + l)
                )
                .reduce((a, b) => a.concat(b), []);

            const workbook = await XlsxPopulate.fromBlankAsync();
            const sheet1 = workbook.sheet('Sheet1');
            const company = window.sessionStorage.getItem('company');

            const letterStartCode = 65;
            const letterEndCode = letterStartCode + headers.length - 1;
            const letterStart = String.fromCharCode(letterStartCode);
            const letterEnd = columns[columns.length - 1];

            const valueFormat = (formatter, value, props = {}) => {
                const { format = 'YYYY-MM-DD' } = props;
                switch (true) {
                    case formatter === 'CheckboxFormatter':
                        return value ? '是' : '否';
                    case formatter === 'NumericFormatter':
                        return isNaN(Number(value)) ? 0 : Number(value);
                    case formatter === 'DateFormatter':
                        return value && moment(value).isValid() ? moment(value).format(format) : '';
                    default:
                        return value;
                }
            };

            let rowCount = 1;

            sheet1.name(title[0]);
            sheet1.row().height(25);

            for (let i = 0; i < headers.length; i++) {
                const width = headers[i].width ?? headers[i].minWidth ?? 200;
                sheet1.column(i + 1).width(width / 6);
            }

            sheet1.row(rowCount).height(25);
            sheet1
                .range(`${letterStart}1:${letterEnd}1`)
                .merged(true)
                .style('horizontalAlignment', 'center')
                .style('verticalAlignment', 'center')
                .style('fontSize', '18')
                .value(company);

            rowCount++;

            for (let i = 0; i < title.length; i++) {
                rowCount++;
                sheet1.row(rowCount).height(25);
                sheet1
                    .range(`${letterStart}${rowCount}:${letterEnd}${rowCount}`)
                    .merged(true)
                    .value(title[i])
                    .style({ horizontalAlignment: 'center', verticalAlignment: 'center', fontSize: 16 - i * 2 });
            }

            rowCount++;
            rowCount++;

            for (let i = 0; i < headers.length; i++) {
                let letterNext = columns[i];

                let cell = sheet1.cell(`${letterNext}${rowCount}`);
                cell.value(headers[i].name).style('bottomBorder', true).style('bottomBorderStyle', 'dashed');
                if (headers[i].formatter) {
                    switch (headers[i].formatter.name) {
                        case 'NumericFormatter':
                            cell.style('horizontalAlignment', 'right');
                            break;
                        default:
                    }
                }
            }

            rowCount++;

            for (let j = 0; j < bodies.size; j++) {
                for (let i = 0; i < headers.length; i++) {
                    let letterNext = columns[i];
                    let cell = sheet1.cell(`${letterNext}${rowCount}`);
                    const formatter = headers[i].formatter ? headers[i].formatter.name : null;
                    let value = valueFormat(
                        formatter,
                        bodies.getIn([j, headers[i].key]),
                        headers[i].formatter ? headers[i].formatter.props : {}
                    );

                    cell.value(value).style('bottomBorder', true).style('bottomBorderStyle', 'dashed');

                    if (formatter) {
                        switch (formatter) {
                            case 'NumericFormatter':
                                cell.style('horizontalAlignment', 'right').style(
                                    'numberFormat',
                                    Number(headers[i].fixed ?? 0) > 0
                                        ? '#,##0.'.padEnd(Number(headers[i].fixed ?? 0) + 6, '0')
                                        : '#,##0'
                                );
                                break;
                            case 'DateFormatter':
                                cell.style('horizontalAlignment', 'center');
                                break;
                            default:
                        }
                    }
                }
                rowCount++;
            }

            for (let i = 0; i < headers.length; i++) {
                let letterNext = columns[i];
                let cell = sheet1.cell(`${letterNext}${rowCount}`);
                cell.style('bold', 'true');

                if (headers[i].sum || headers[i].aggSum) {
                    cell.value(bodies.reduce((a, b) => a + (Number(b.get(headers[i].key)) || 0), 0));
                }

                if (headers[i].footer) {
                    cell.value(headers[i].footer);
                }

                if (headers[i].formatter) {
                    switch (headers[i].formatter.name) {
                        case 'NumericFormatter':
                            cell.style('horizontalAlignment', 'right');
                            cell.style(
                                'numberFormat',
                                Number(headers[i].fixed ?? 0) > 0
                                    ? '#,##0.'.padEnd(Number(headers[i].fixed ?? 0) + 6, '0')
                                    : '#,##0'
                            );
                            break;
                        case 'DateFormatter':
                            cell.style('horizontalAlignment', 'center');
                            break;
                        default:
                    }
                }
            }

            const endRange = columns[columns.length - 1];
            sheet1.range('A1:' + endRange + rowCount).style('fontFamily', '微软雅黑');
            sheet1.range('A5:' + endRange + rowCount).style('fontSize', '12');

            const blob = await workbook.outputAsync();

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob, 'out.xlsx');
            } else {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.href = url;
                a.download = title.join('_') + '.xlsx';
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }

            Excel.emitter.emit('opened');
        } catch (error) {
            Excel.emitter.emit('opened', true);
        }
    }
}

export default Excel;
