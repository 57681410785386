import { fromJS } from 'immutable';

import * as ACTION_TYPES from './constants';

const initialState = fromJS({
    loading: false,
    visible: false,
    query: {},
    name: '',
    content: '',
    extension: '',
});

export default function CommonPrintPreviewReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.FILE_PREVIEW_LOADING:
            return state.update('loading', loading => !loading);

        case ACTION_TYPES.FILE_PREVIEW_OPEN:
            return state.set('visible', true).set('query', fromJS(action.query));

        case ACTION_TYPES.FILE_PREVIEW_HIDE:
            return state.set('visible', false);

        case ACTION_TYPES.FILE_PREVIEW_SHOW:
            const { name = '', content = '' } = action.result;
            if (name) {
                const [_name, extension] = name.split('.');
                switch (extension) {
                    default:
                }
                return state.set('name', _name).set('extension', extension).set('content', content);
            } else {
                return state;
            }

        case ACTION_TYPES.FILE_PREVIEW_RESET:
            return initialState;

        default:
            return state;
    }
}
