import React from 'react';
import { Confirm, Icon, Image, Menu, Popup } from 'semantic-ui-react';
import { QRCodeCanvas } from '@cheprasov/qrcode';
import PrintPreviewAction from '../../Common/PrintPreview/actions';
import { useDispatch } from 'react-redux';
import school from '../../../Assets/mini_school.jpg';
import student from '../../../Assets/mini_student.jpg';

export default function HeadMenu(props) {
    const dispatch = useDispatch();

    const { user, onAction = () => {} } = props;

    const [confirmOpen, setConfirmOpen] = React.useState(false);

    const [schoolPopup, setSchoolPopup] = React.useState(false);
    const [studentPopup, setStudentPopup] = React.useState(false);
    const [internalPopup, setInternalPopup] = React.useState(false);

    const handlePrint = props => {
        return dispatch(PrintPreviewAction.show({ section: 'apk', ...props }));
    };

    const name = user.get('name') ?? '';
    const admin = user.get('admin') ?? false;

    const school_app = window.location.origin + '/app/download/school';
    const student_app = window.location.origin + '/app/download/student';
    const internal_app = window.location.origin + '/app/download/internal';

    const school_svg = new QRCodeCanvas(school_app, { padding: 0 }).toDataURL();
    const student_svg = new QRCodeCanvas(student_app, { padding: 0 }).toDataURL();
    const internal_svg = new QRCodeCanvas(internal_app, { padding: 0 }).toDataURL();

    return (
        <Menu attached borderless fluid style={{ margin: 0 }}>
            <Menu.Item header content={name} style={{ textAlign: 'center', fontSize: '150%' }} />
            <Menu.Menu position="right">
                <Popup
                    on="click"
                    open={schoolPopup}
                    onOpen={() => setSchoolPopup(true)}
                    onClose={() => setSchoolPopup(false)}
                    trigger={<Menu.Item icon="mobile" content="学车宝管理小程序" link />}
                    position="bottom right"
                    content={
                        <div style={{ textAlign: 'center' }}>
                            <Image src={school} />
                            <div style={{ fontSize: '150%', paddingTop: 10 }}>
                                学车宝管理小程序
                                <Icon
                                    name="print"
                                    link
                                    onClick={() =>
                                        handlePrint({ app_name: '学车宝管理小程序', img: `<img src="${school}"/>` }) &&
                                        setSchoolPopup(false)
                                    }
                                />
                            </div>
                        </div>
                    }
                />
                <Popup
                    on="click"
                    open={studentPopup}
                    onOpen={() => setStudentPopup(true)}
                    onClose={() => setStudentPopup(false)}
                    trigger={<Menu.Item icon="mobile alternate" content="学员小程序" link />}
                    position="bottom right"
                    content={
                        <div style={{ textAlign: 'center' }}>
                            <Image src={student} />
                            <div style={{ fontSize: '150%', paddingTop: 10 }}>
                                学车宝小程序
                                <Icon
                                    name="print"
                                    link
                                    onClick={() =>
                                        handlePrint({ app_name: '学车宝小程序', img: `<img src="${student}"/>` }) &&
                                        setStudentPopup(false)
                                    }
                                />
                            </div>
                        </div>
                    }
                />

                {admin && (
                    <Popup
                        on="click"
                        open={internalPopup}
                        onOpen={() => setInternalPopup(true)}
                        onClose={() => setInternalPopup(false)}
                        trigger={<Menu.Item icon="mobile" content="内部管理 APP" link />}
                        position="bottom right"
                        content={
                            <div style={{ textAlign: 'center' }}>
                                <Image src={internal_svg} />
                                <div style={{ fontSize: '150%', paddingTop: 10 }}>
                                    内部管理 APP
                                    <Icon
                                        name="print"
                                        link
                                        onClick={() =>
                                            handlePrint({ app_name: '内部管理 APP', qrcode: school_app }) &&
                                            setInternalPopup(false)
                                        }
                                    />
                                </div>
                            </div>
                        }
                    />
                )}
                {!admin ? (
                    <Menu.Item content="修改密码" icon="key" link action="password" onClick={onAction} />
                ) : (
                    <Menu.Item content="数据看板" icon="tv" link action="dashboard" onClick={onAction} />
                )}
                <Confirm
                    open={confirmOpen}
                    onClose={() => setConfirmOpen(false)}
                    centered={false}
                    trigger={<Menu.Item content="登出" icon="sign-out" link />}
                    header={<h3 style={{ margin: 28 }}>你确定要登出吗？</h3>}
                    content={null}
                    confirmButton="登出"
                    cancelButton="取消"
                    onOpen={() => setConfirmOpen(true)}
                    onCancel={() => setConfirmOpen(false)}
                    onConfirm={evt => onAction(evt, { action: 'logout' })}
                />
            </Menu.Menu>
        </Menu>
    );
}
