import { fromJS } from 'immutable';

import * as ACTION_TYPES from './constants';

const initialState = fromJS({
    loading_index: false,
    loading_show: false,
    tokens: [],
});
export default function SettingTokenReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.SETTING_TOKEN_LOADING_INDEX:
            return action.toggle
                ? state.set('loading_index', action.toggle)
                : state.update('loading_index', loading => !loading);

        case ACTION_TYPES.SETTING_TOKEN_LOADING_SHOW:
            return action.toggle
                ? state.set('loading_show', action.toggle)
                : state.update('loading_show', loading => !loading);

        case ACTION_TYPES.SETTING_TOKEN_INDEX:
            return state.set('tokens', fromJS(action.tokens)).set('loading_index', false);

        case ACTION_TYPES.SETTING_TOKEN_RESET:
            return initialState;

        default:
            return state;
    }
}
