import React, { lazy, Suspense, useEffect, useRef } from 'react';
import { fromJS, is } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';

import { Sidebar } from 'semantic-ui-react';

import { DataGrid } from '../../Common';

import Actions from './actions';

import SettingCustomerAction from '../Customer/actions';

const Detail = lazy(() => import('./Detail'));

export default function SettingVersion(props) {
    const dispatch = useDispatch();

    const loading_index = useSelector(
        state => state.SettingVersionReducer.get('loading_index'),
        (a, b) => is(a, b)
    );

    const loading_show = useSelector(
        state => state.SettingVersionReducer.get('loading_show'),
        (a, b) => is(a, b)
    );

    const versions = useSelector(
        state => state.SettingVersionReducer.get('versions'),
        (a, b) => is(a, b)
    );

    const version = useSelector(
        state => state.SettingVersionReducer.get('version'),
        (a, b) => is(a, b)
    );

    const search = useSelector(
        store => store.SettingVersionReducer.get('search'),
        (a, b) => is(a, b)
    );

    const defaultColumns = useSelector(
        store => store.SettingVersionReducer.get('columns'),
        (a, b) => is(a, b)
    );

    const columns = useSelector(
        store => store.MainReducer.getIn(['gui', 'Version'], defaultColumns),
        (a, b) => is(a, b)
    ).toJS();

    useEffect(() => {
        dispatch(SettingCustomerAction.options());
        // dispatch(SchoolAction.options());
    }, []);

    useEffect(() => {
        dispatch(Actions.requests());
        // dispatch(SchoolAction.options());
    }, [search]);

    const handleSearch = (evt, values) => {
        const { name, value } = values;
        if (name) {
            dispatch(Actions.search(search.set(name, value instanceof Array ? value.join(',') : value)));
        } else {
            dispatch(Actions.search(search.merge(fromJS(values))));
        }
    };

    const handleAction = (evt, { action }) => {
        switch (action) {
            case 'add':
                dispatch(Actions.show());
                return DetailRef?.current?.handleOpen();
            case 'refresh':
                return dispatch(Actions.requests());
            case 'update':
                return dispatch(Actions.create());
            case 'destroy':
                return dispatch(Actions.destroy());
            case 'print':
                break;
            default:
        }
    };

    const handleSelect = row => {
        dispatch(Actions.request(row.id));
        return DetailRef?.current?.handleOpen();
    };

    const handleChange = (evt, values) => {
        const { name, value, options } = values;

        if (name) {
            dispatch(Actions.show(version.set(name, fromJS(value))));
        } else {
            dispatch(Actions.show(version.merge(fromJS(values))));
        }
    };

    const DetailRef = useRef();

    return (
        <div className="flexHorizontalContent">
            <Sidebar.Pushable className="flexHorizontalContent">
                <DataGrid
                    loading={loading_index}
                    columns={columns}
                    rows={versions}
                    defaultSortColumns={[{ columnKey: 'name', direction: 'ASC' }]}
                    onAction={handleAction}
                    onSelect={handleSelect}
                />
                <Suspense fallback={null}>
                    <Detail
                        ref={DetailRef}
                        loading={loading_show}
                        version={version}
                        onAction={handleAction}
                        onChange={handleChange}
                    />
                </Suspense>
            </Sidebar.Pushable>
        </div>
    );
}

export { default as SettingVersionReducer } from './reducers';
export { default as SettingVersionAction } from './actions';
