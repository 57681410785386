import io from 'socket.io-client';

import * as ACTION_TYPES from './constants';

import { Main as Api } from '../../Api';

export default class MainActions {
    static versionTimeout = 0;
    static statisticTimeout = 0;

    static active = (window = {}) => ({
        type: ACTION_TYPES.WINDOW_ACTIVE,
        window,
    });

    static open = (window = {}) => ({
        type: ACTION_TYPES.WINDOW_OPEN,
        window,
    });

    static close = (window = {}) => ({
        type: ACTION_TYPES.WINDOW_CLOSE,
        window,
    });

    static reset = () => ({
        type: ACTION_TYPES.WINDOW_RESET,
    });

    static version = () => async dispatch => {
        try {
            if (MainActions.versionTimeout) clearTimeout(MainActions.versionTimeout);
            const res = await Api.version();
            if (res instanceof Error) throw res;
            dispatch({ type: ACTION_TYPES.WINDOW_VERSION, version: res });
        } catch (error) {
        } finally {
            MainActions.versionTimeout = setTimeout(() => {
                dispatch(MainActions.version());
            }, 1000 * 60);
        }
    };

    static statistic = query => async dispatch => {
        try {
            if (MainActions.statisticTimeout) clearTimeout(MainActions.statisticTimeout);
            const res = await Api.statistic(query);
            if (res instanceof Error) throw res;
            dispatch({ type: ACTION_TYPES.WINDOW_STATISTIC, statistic: res });
        } catch (error) {
        } finally {
            MainActions.statisticTimeout = setTimeout(() => {
                dispatch(MainActions.statistic());
            }, 1000 * 60);
        }
    };

    static socket = () => async dispatch => {
        try {
            const token = sessionStorage.getItem('token') ?? localStorage.getItem('token');

            const { origin } = window.location;

            const socket = io.connect(origin, {
                transports: ['websocket'],
                forceNew: true,
                query: {
                    token,
                },
            });

            socket.on('online', ({ simulators = [] }) => {
                dispatch({ type: ACTION_TYPES.WINDOW_ONLINE, onlines: simulators });
            });

            socket.on('disconnect', ({ simulators = [] }) => {
                dispatch({ type: ACTION_TYPES.WINDOW_ONLINE, onlines: [] });
            });

            socket.on('usings', ({ simulators = [] }) => {
                dispatch({ type: ACTION_TYPES.WINDOW_USING, usings: simulators });
            });
        } catch (error) {
            throw error;
        }
    };

    static status = () => async dispatch => {
        try {
            if (MainActions.statisticTimeout) clearTimeout(MainActions.statisticTimeout);
            const res = await Api.status();
            if (res instanceof Error) throw res;
            dispatch({ type: ACTION_TYPES.WINDOW_STATUS, status: res });
        } catch (error) {
        } finally {
            MainActions.statisticTimeout = setTimeout(() => {
                dispatch(MainActions.status());
            }, 1000 * 60);
        }
    };
}
