import React, { useEffect, useRef, useState } from 'react';
import { is, List } from 'immutable';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Button, Menu, Segment, Sidebar } from 'semantic-ui-react';
import jsFILE from 'jspdf';
import html2canvas from 'html2canvas';

import Actions from './actions';
import styles from './styles.module.scss';

export default function FILEPreview() {
    const dispatch = useDispatch();

    const [scale, setScale] = useState(1);
    const [activeIndex, setActiveIndex] = useState(0);

    const visible = useSelector(store => store.FilePreviewReducer.get('visible'), shallowEqual);

    const query = useSelector(
        store => store.FilePreviewReducer.get('query'),
        (a, b) => is(a, b)
    );

    const extension = useSelector(store => store.FilePreviewReducer.get('extension'), shallowEqual);
    const content = useSelector(store => store.FilePreviewReducer.get('content'), shallowEqual);

    const name = query.get('name') ?? '';
    const files = query.get('files') ?? List();

    useEffect(() => {
        if (iframeRef.current) {
            iframeRef.current.style.transform = `scale(${scale})`;
        }
    }, [scale]);

    useEffect(() => {
        if (visible) {
            dispatch(Actions.requests(files.get(activeIndex)));
        } else {
            setActiveIndex(0);
        }
    }, [visible, files, activeIndex]);

    const handleClose = () => {
        dispatch(Actions.hide());
    };
    const handleHidden = () => {
        dispatch(Actions.reset());
    };

    const handleZoom = () => {
        if (scale < 2) {
            setScale(scale + 0.5);
        } else {
            setScale(1);
        }
    };

    const handleDownload = async () => {
        const paga_count = content.match(/class="page"/g).length;
        const canvas = await html2canvas(
            (iframeRef.current.contentDocument || iframeRef.current.contentWindow.document).body,
            { width: 794 }
        );

        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 210; // A4 尺寸宽度为 210mm
        const pageHeight = 297;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let position = 0;
        let heightLeft = imgHeight;

        const doc = new jsFILE('p', 'mm');
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
        }
        doc.save(name);
    };

    const handlePrint = (evt, { icon }) => {
        const contentWindow = iframeRef.current.contentWindow;
        contentWindow.focus();
        contentWindow.print();
    };

    const handleLoad = () => {
        const iframe = iframeRef.current;
        iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
    };

    const handleConfirm = () => {
        confirm && confirm();
        handleClose();
    };

    const handleItemClick = (evt, { index }) => {
        setActiveIndex(index);
    };

    const iframeRef = useRef();

    const confirm = query.get('confirm');

    const menu_items = files.toJS();

    return (
        <Sidebar
            visible={visible}
            className={styles.container}
            direction="top"
            animation="overlay"
            onHidden={handleHidden}>
            <Menu inverted borderless attached className={styles.title}>
                <Menu.Item content={name} />
                <Menu.Menu position="right">
                    <Menu.Item icon="download" link onClick={handleDownload} />
                    <Menu.Item icon="print" link onClick={handlePrint} />
                    <Menu.Item icon="zoom" link onClick={handleZoom} />
                    <Menu.Item icon="close" link onClick={handleClose} />
                </Menu.Menu>
            </Menu>
            <Segment.Group horizontal style={{ display: 'flex', flex: 1, margin: 0, flexDirection: 'row' }}>
                {menu_items.length > 2 && (
                    <Menu
                        inverted
                        vertical
                        attached
                        items={menu_items}
                        activeIndex={activeIndex}
                        onItemClick={handleItemClick}
                    />
                )}
                <Segment inverted attached>
                    {!!content && (
                        <iframe
                            onLoad={handleLoad}
                            style={{
                                backgroundColor: 'rgba(255, 255, 255, 0.15)',
                                border: 0,
                                transformOrigin: 'center 0',
                            }}
                            ref={iframeRef}
                            srcDoc={content}
                        />
                    )}
                </Segment>
            </Segment.Group>

            {confirm && (
                <div style={{ position: 'absolute', right: 30, bottom: 30 }}>
                    <Button content="确认" color="blue" size="large" onClick={handleConfirm} />
                </div>
            )}
        </Sidebar>
    );
}
export { default as FilePreviewReducer } from './reducers';
export { default as FILEPreviewAction } from './actions';
//+ '#toolbar=0&view=FitH'
