import React, { lazy, Suspense, useEffect, useRef } from 'react';
import { fromJS, is } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Sidebar } from 'semantic-ui-react';

import { DataGrid } from '../../Common';

import Actions from './actions';

const Detail = lazy(() => import('./Detail'));
const Record = lazy(() => import('./Record'));

export default function SettingCommand(props) {
    const dispatch = useDispatch();

    const loading_index = useSelector(
        state => state.SettingCommandReducer.get('loading_index'),
        (a, b) => is(a, b)
    );

    const loading_show = useSelector(
        state => state.SettingCommandReducer.get('loading_show'),
        (a, b) => is(a, b)
    );

    const commands = useSelector(
        state => state.SettingCommandReducer.get('commands'),
        (a, b) => is(a, b)
    );

    const command = useSelector(
        state => state.SettingCommandReducer.get('command'),
        (a, b) => is(a, b)
    );

    const search = useSelector(
        store => store.SettingCommandReducer.get('search'),
        (a, b) => is(a, b)
    );

    const defaultColumns = useSelector(
        store => store.SettingCommandReducer.get('columns'),
        (a, b) => is(a, b)
    );

    const columns = useSelector(
        store => store.MainReducer.getIn(['gui', 'Command'], defaultColumns),
        (a, b) => is(a, b)
    ).toJS();

    useEffect(() => {
        dispatch(Actions.requests());
    }, [search]);

    const handleSearch = (evt, values) => {
        const { name, value } = values;
        if (name) {
            dispatch(Actions.search(search.set(name, value instanceof Array ? value.join(',') : value)));
        } else {
            dispatch(Actions.search(search.merge(fromJS(values))));
        }
    };

    const handleAction = (evt, { action, selected }) => {
        switch (action) {
            case 'add':
                dispatch(Actions.show());
                return DetailRef?.current?.handleOpen();
            case 'refresh':
                return dispatch(Actions.requests());
            case 'update':
                return dispatch(Actions.create());
            case 'destroy':
                return dispatch(Actions.destroy());
            case 'record':
                return RecordRef?.current?.handleOpen();
            default:
        }
    };

    const handleSelect = row => {
        dispatch(Actions.request(row.id));
        return DetailRef?.current?.handleOpen();
    };

    const handleColumnChange = columns => {
        // dispatch(GuiActions.update({ key: ['Employee', 'Command'], value: columns }));
    };

    const handleChange = (evt, values) => {
        const { name, value } = values;

        if (name) {
            dispatch(Actions.show(command.set(name, fromJS(value))));
        } else {
            dispatch(Actions.show(command.merge(fromJS(values))));
        }
    };

    const DetailRef = useRef();
    const RecordRef = useRef();

    return (
        <div className="flexHorizontalContent">
            <Sidebar.Pushable className="flexHorizontalContent">
                <DataGrid
                    loading={loading_index}
                    columns={columns}
                    rows={commands}
                    defaultSortColumns={[{ columnKey: 'name', direction: 'ASC' }]}
                    onAction={handleAction}
                    onSelect={handleSelect}
                    onColumnChange={handleColumnChange}
                    customInput={
                        <Button
                            color="brown"
                            type="button"
                            size="tiny"
                            content="记录"
                            action="record"
                            icon="list"
                            onClick={handleAction}
                        />
                    }
                />
                <Suspense fallback={null}>
                    <Detail
                        ref={DetailRef}
                        loading={loading_show}
                        command={command}
                        onAction={handleAction}
                        onChange={handleChange}
                    />
                    <Record ref={RecordRef} />
                </Suspense>
            </Sidebar.Pushable>
        </div>
    );
}

export { default as SettingCommandReducer } from './reducers';
export { default as SettingCommandAction } from './actions';
