import Base from '../Base';

export default class SettingSimulator extends Base {
    static url = '/api/setting/campus';

    static rules = {
        customers_id: { name: '客户', required: true },
        name: { name: '名称', required: true },
    };
}
