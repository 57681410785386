import React from 'react';

export default function DropdownFormatter(props) {
    const { column: { key, options = [] } = {}, row } = props;

    const value = row[key] ?? '';

    const text = options.find(o => o.value === value)?.text || '';

    return <div>{text}</div>;
}
