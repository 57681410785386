import { fromJS } from 'immutable';

import * as ACTION_TYPES from './constants';

const initialState = fromJS({
    loading_index: false,
    loading_show: false,
    search: {},
    banners: [],
    banner: {},
    columns: [
        { key: 'schedule', name: '周期' },
        { key: 'name', name: '名称' },
        { key: 'kind', name: '类型' },
        { key: 'value', name: '数值' },
        { key: 'pre_show', name: '提前显示' },
        { key: 'post_show', name: '隆后显示' },
    ],
});
export default function SettingWorkerReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.SETTING_BANNER_LOADING_INDEX:
            return action.toggle
                ? state.set('loading_index', action.toggle)
                : state.update('loading_index', loading => !loading);

        case ACTION_TYPES.SETTING_BANNER_LOADING_SHOW:
            return action.toggle
                ? state.set('loading_show', action.toggle)
                : state.update('loading_show', loading => !loading);

        case ACTION_TYPES.SETTING_BANNER_SEARCH:
            return state.set('search', fromJS(action.search));

        case ACTION_TYPES.SETTING_BANNER_INDEX:
            return state.set('banners', fromJS(action.banners)).set('loading_index', false);

        case ACTION_TYPES.SETTING_BANNER_SHOW:
            return state.update('banner', banner => banner.merge(fromJS(action.banner))).set('loading_show', false);

        case ACTION_TYPES.SETTING_BANNER_OPTION:
            return state.set('options', fromJS(action.options));

        case ACTION_TYPES.SETTING_BANNER_RESET:
            return initialState.set('columns', state.get('columns'));

        default:
            return state;
    }
}
