import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { fromJS, is } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';

import { Select, Sidebar } from 'semantic-ui-react';
import SchoolActions from '../School/actions';
import PrintPreviewAction from '../Common/PrintPreview/actions';

import { DataGrid } from '../Common';

import Actions from './actions';

const Detail = lazy(() => import('./Detail'));

export default function Coach(props) {
    const dispatch = useDispatch();

    const loading_index = useSelector(
        state => state.CoachReducer.get('loading_index'),
        (a, b) => is(a, b)
    );

    const coaches = useSelector(
        state => state.CoachReducer.get('coaches'),
        (a, b) => is(a, b)
    );

    const coach = useSelector(
        state => state.CoachReducer.get('coach'),
        (a, b) => is(a, b)
    );

    const search = useSelector(
        store => store.CoachReducer.get('search'),
        (a, b) => is(a, b)
    );

    const defaultColumns = useSelector(
        store => store.CoachReducer.get('columns'),
        (a, b) => is(a, b)
    );

    const columns = useSelector(
        store => store.MainReducer.getIn(['gui', 'Coach'], defaultColumns),
        (a, b) => is(a, b)
    ).toJS();

    const school_options = useSelector(
        state => state.SchoolReducer.get('options'),
        (a, b) => is(a, b)
    ).toJS();

    const [selected, setSelected] = useState([]);

    useEffect(() => {
        dispatch(SchoolActions.options());
    }, []);

    useEffect(() => {
        dispatch(Actions.requests());
    }, [search]);

    useEffect(() => {
        DataGridRef?.current?.handleSelectedRowsChange();
    }, [coaches]);

    const handleSearch = (evt, values) => {
        const { name, value } = values;
        if (name) {
            dispatch(Actions.search(search.set(name, value instanceof Array ? value.join(',') : value)));
        } else {
            dispatch(Actions.search(search.merge(fromJS(values))));
        }
    };

    const handleAction = (evt, { action, value }) => {
        switch (action) {
            case 'add':
                dispatch(Actions.show());
                return DetailRef?.current?.handleOpen();
            case 'refresh':
                return dispatch(Actions.requests());
            case 'update':
                return dispatch(Actions.update());
            case 'destroy':
                return dispatch(Actions.destroy());
            case 'confirm':
                return dispatch(Actions.confirm(value));
            case 'print':
                return dispatch(
                    PrintPreviewAction.show({ section: 'certificate', coach: coach.set('pass_project', value) })
                );
            default:
        }
    };

    const handleRowSelect = row => {
        setSelected(row);
    };

    const handleColumnChange = columns => {
        // dispatch(GuiActions.update({ key: ['Employee', 'Worker'], value: columns }));
    };

    const handleChange = (evt, values) => {
        if (values) {
            const { name, value } = values;
            if (name) {
                dispatch(Actions.show(coach.set(name, fromJS(value))));
            } else {
                dispatch(Actions.show(coach.merge(fromJS(values))));
            }
        } else {
            dispatch(Actions.show());
        }
    };

    const handleSelect = row => {
        dispatch(Actions.request(row.id));
        return DetailRef?.current?.handleOpen();
    };

    const DetailRef = useRef();
    const DataGridRef = useRef();

    const schools_id = search.get('schools_id') ?? '';
    const disabled = search.get('disabled') ?? '';

    return (
        <div className="flexHorizontalContent">
            <Sidebar.Pushable className="flexHorizontalContent">
                <DataGrid
                    rowSelect
                    selected={selected}
                    ref={DataGridRef}
                    loading={loading_index}
                    columns={columns}
                    rows={coaches}
                    defaultSortColumns={[{ columnKey: 'name', direction: 'ASC' }]}
                    onAction={handleAction}
                    onRowSelect={handleRowSelect}
                    onSelect={handleSelect}
                    onColumnChange={handleColumnChange}
                    rightInput={
                        <React.Fragment>
                            <Select
                                clearable
                                search
                                placeholder="状态"
                                options={[
                                    { value: false, text: '启用' },
                                    { value: true, text: '停用' },
                                ]}
                                name="disabled"
                                value={disabled}
                                onChange={handleSearch}
                            />
                            <Select
                                clearable
                                search
                                placeholder="驾校"
                                options={school_options}
                                name="schools_id"
                                value={schools_id}
                                onChange={handleSearch}
                            />
                        </React.Fragment>
                    }
                />
                <Suspense fallback={null}>
                    <Detail ref={DetailRef} coach={coach} onAction={handleAction} onChange={handleChange} />
                </Suspense>
            </Sidebar.Pushable>
        </div>
    );
}

export { default as CoachReducer } from './reducers';
export { default as CoachAction } from './actions';
