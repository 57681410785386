import { fromJS } from 'immutable';
import moment from 'moment';

import * as ACTION_TYPES from './constants';
import { CheckboxFormatter, NumericFormatter } from '../../Common';

const initialState = fromJS({
    loading_index: false,
    loading_show: false,
    search: {
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().endOf('month').format('YYYY-MM-DD'),
        date_mode: 'current',
        customer: '',
    },
    scholars: [],
    scholar: {},
    options: [],
    schools: [],
    columns: [
        { key: 'confirm', name: '确认', width: 80 },
        { key: 'status', name: '状态', width: 80 },
        { key: 'project', name: '科目', width: 100 },
        { key: 'name', name: '姓名', width: 100 },
        { key: 'sex', name: '性别', maxWidth: 50, minWidth: 50, width: 50 },
        { key: 'age', name: '年龄', maxWidth: 50, minWidth: 50, width: 50 },
        { key: 'excluded', name: '例外', maxWidth: 50, minWidth: 50, width: 50, formatter: CheckboxFormatter },
        { key: 'mobile', name: '手机', width: 150 },
        { key: 'license', name: '考照类型', width: 100 },
        { key: 'created_at', name: '注册日期', width: 160 },
        { key: 'schools_name', name: '驾校', minWidth: 150 },
        { key: 'customers_abbr', name: '客户', width: 150 },
        { key: 'basic_rate', name: '基础', width: 60, formatter: NumericFormatter, unit: '%' },
        { key: 'project2_rate', name: '科二', width: 60, formatter: NumericFormatter, unit: '%' },
        { key: 'project3_rate', name: '科三', width: 60, formatter: NumericFormatter, unit: '%' },
    ],
});
export default function SettingScholarReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.SETTING_SCHOLAR_LOADING_INDEX:
            return action.toggle
                ? state.set('loading_index', action.toggle)
                : state.update('loading_index', loading => !loading);

        case ACTION_TYPES.SETTING_SCHOLAR_LOADING_SHOW:
            return action.toggle
                ? state.set('loading_show', action.toggle)
                : state.update('loading_show', loading => !loading);

        case ACTION_TYPES.SETTING_SCHOLAR_SEARCH:
            return state.set('search', fromJS(action.search));

        case ACTION_TYPES.SETTING_SCHOLAR_INDEX:
            return state.set('scholars', fromJS(action.scholars)).set('loading_index', false);

        case ACTION_TYPES.SETTING_SCHOLAR_SHOW:
            return state
                .set('scholar', initialState.get('scholar').merge(fromJS(action.scholar)))
                .set('loading_show', false);

        case ACTION_TYPES.SETTING_SCHOLAR_OPTION:
            return state.set('options', fromJS(action.options));

        case ACTION_TYPES.SETTING_SCHOLAR_SCHOOL:
            return state.set('schools', fromJS(action.schools));

        case ACTION_TYPES.SETTING_SCHOLAR_RESET:
            return initialState.set('options', state.get('options')).set('columns', state.get('columns'));

        default:
            return state;
    }
}
