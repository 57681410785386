import { fromJS } from 'immutable';

import * as ACTION_TYPES from './constants';
import { NumericFormatter } from '../Common';

const initialState = fromJS({
    loading_index: false,
    loading_show: false,
    clearances: [],
    columns: [
        { key: 'courses_name', name: '科目', width: 200 },
        { key: 'courses_items_name', name: '项目' },
        { key: 'clearance', name: '通关次数', width: 100, formatter: NumericFormatter, editable: true },
    ],
});
export default function ClearanceReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.CLEARANCE_LOADING_INDEX:
            return action.toggle
                ? state.set('loading_index', action.toggle)
                : state.update('loading_index', loading => !loading);

        case ACTION_TYPES.CLEARANCE_INDEX:
            return state.set('clearances', fromJS(action.clearances)).set('loading_index', false);

        case ACTION_TYPES.CLEARANCE_RESET:
            return initialState.set('options', state.get('options')).set('columns', state.get('columns'));

        default:
            return state;
    }
}
