import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';

import * as Components from '../Components';

const Reducers = combineReducers({
    ...Object.entries(Components)
        .filter(([key]) => key.includes('Reducer'))
        .reduce((a, [key, value]) => ({ ...a, [key]: value }), {}),
});

export default createStore(Reducers, applyMiddleware(thunk));
