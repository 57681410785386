import { fromJS } from 'immutable';
import 'react-datepicker/dist/react-datepicker.css';
import React, { useEffect, useState } from 'react';
import Picker, { registerLocale } from 'react-datepicker';
import zh from 'date-fns/locale/zh-CN';
import { Button, Form, Input } from 'semantic-ui-react';
import { format } from 'date-fns';
import moment from 'moment';

import styles from './styles.module.scss';

registerLocale('zh', zh);
export default function DatePicker(props) {
    const {
        fluid,
        mode = 'form',
        label = '',
        showMonthYearPicker,
        showYearPicker,
        showTimeInput,
        pattern = 'yyyy-MM-dd',
        width,
        clearable = false,
        name = '',
        onChange = () => {},
    } = props;

    const SEARCH_MODE_OPTIONS = [
        { value: 'current', text: '本月' },
        { value: 'last', text: '上个月' },
        { value: 'last2', text: '上2个月' },
        { value: 'last3', text: '上3个月' },
        { value: 'half', text: '最近半年' },
        { value: 'year', text: '最近一年' },
        { value: 'custom', text: '自订' },
    ];

    const initState = fromJS({
        value: props.value && moment.isDate(moment(props.value).toDate()) ? props.value : undefined,
        search: {
            mode: props.search_mode ?? 'current',
            start_date: props.start_date ?? moment().startOf('month').format('YYYY-MM-DD'),
            end_date: props.end_date ?? moment().endOf('month').format('YYYY-MM-DD'),
        },
    });

    const [state, setState] = useState(initState);

    const search = state.get('search');
    const value = state.get('value');

    const search_mode = search.get('mode') ?? '';
    const start_date = search.get('start_date') ?? '';
    const end_date = search.get('end_date') ?? '';

    useEffect(() => {
        if (props.value && moment.isDate(moment(props.value).toDate())) {
            setState(state.set('value', props.value));
        } else {
            setState(state.set('value', undefined));
        }
    }, [props.value]);

    useEffect(() => {
        let updated = search;
        if (props.start_date && moment.isDate(props.start_date)) {
            updated = updated.set('start_date', moment(props.start_date).toDate());
        }

        if (props.end_date && moment.isDate(props.end_date)) {
            updated = updated.set('end_date', moment(props.end_date).toDate());
        }

        setState(state.set('search', updated));
    }, [props.start_date, props.end_date]);

    const handleSearch = (evt, values) => {
        const { name, value, icon } = values;

        let _start_date = start_date;
        let _end_date = end_date;

        let updated = search;

        if (name === 'search_mode') {
            _end_date = moment().endOf('month').toDate();

            switch (value) {
                case 'current':
                    _start_date = moment(_end_date).startOf('month').toDate();
                    break;
                case 'last':
                    _start_date = moment(_end_date).subtract(1, 'month').startOf('month').toDate();
                    _end_date = moment(_end_date).subtract(1, 'month').endOf('month').toDate();
                    break;
                case 'last2':
                    _start_date = moment(_end_date).subtract(2, 'month').startOf('month').toDate();
                    _end_date = moment(_end_date).subtract(2, 'month').endOf('month').toDate();
                    break;
                case 'last3':
                    _start_date = moment(_end_date).subtract(3, 'month').startOf('month').toDate();
                    _end_date = moment(_end_date).subtract(3, 'month').endOf('month').toDate();
                    break;
                case 'half':
                    _start_date = moment(_end_date).subtract(6, 'month').startOf('month').toDate();
                    break;
                case 'year':
                    _start_date = moment(_end_date).subtract(1, 'year').startOf('month').toDate();
                    break;
                default:
            }
            updated = updated.merge(fromJS({ mode: value, start_date: _start_date, end_date: _end_date }));
        }

        if (name === 'start_date') {
            updated = updated.merge(fromJS({ start_date: value }));
        }

        if (name === 'end_date') {
            updated = updated.merge(fromJS({ end_date: value }));
        }

        if (!!icon) {
            if (icon.includes('right')) {
                _start_date = moment(_start_date).add(1, 'month').startOf('month').toDate();
                _end_date = moment(_end_date).add(1, 'month').endOf('month').toDate();
            }

            if (icon.includes('left')) {
                _start_date = moment(_start_date).subtract(1, 'month').startOf('month').toDate();
                _end_date = moment(_end_date).subtract(1, 'month').endOf('month').toDate();
            }
            updated = updated.merge(fromJS({ start_date: _start_date, end_date: _end_date }));
        }

        setState(state.set('search', updated));

        onChange(null, {
            start_date: moment(_start_date).format(pattern.replace('yyyy-MM-dd', 'YYYY-MM-DD')),
            end_date: moment(_end_date).format(pattern.replace('yyyy-MM-dd', 'YYYY-MM-DD')),
        });
    };

    const handleChange = (evt, values) => {
        onChange(evt, values);
    };

    const DatePicker = props => {
        const { name, onChange = () => {}, customInput } = props;

        const { value } = props;

        const formatted = moment.isMoment(moment(value)) ? moment(value).format(pattern) : '';

        const _value = moment.isMoment(moment(value)) ? moment(value).toDate() : undefined;

        return (
            <Picker
                className={styles.picker}
                locale="zh"
                showPopperArrow={false}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                showMonthYearPicker={showMonthYearPicker}
                showYearPicker={showYearPicker}
                todayButton="今天"
                timeInputLabel="时间:"
                dropdownMode="select"
                showTimeInput={showTimeInput}
                dateFormat={pattern}
                popperModifiers={{
                    enabled: true,
                    escapeWithReference: true,
                }}
                placeholderText={label}
                customInput={customInput || <Input value={formatted} placeholder={label} style={{ width: 100 }} />}
                placeholder={label}
                selected={_value}
                onChange={(value, evt) => onChange(evt, { name, value: value ? format(value, pattern) : null })}
                isClearable={clearable}
            />
        );
    };

    switch (mode) {
        case 'search':
            return (
                <React.Fragment>
                    <Form.Select
                        fluid
                        width={width}
                        selection
                        options={SEARCH_MODE_OPTIONS}
                        value={search_mode}
                        name="search_mode"
                        onChange={handleSearch}
                    />
                    {search_mode === 'custom' && (
                        <Form.Field width={width} style={{ minWidth: 'fit-content' }} className={styles.custom}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <React.Fragment>
                                    <Button basic icon="caret left" onClick={handleSearch} className={styles.button} />
                                    <div style={{ minWidth: 100 }}>
                                        <DatePicker name="start_date" value={start_date} onChange={handleSearch} />
                                    </div>
                                    <div style={{ padding: 3 }}>-</div>
                                    <div style={{ minWidth: 100 }}>
                                        <DatePicker name="end_date" value={end_date} onChange={handleSearch} />
                                    </div>
                                    <Button basic icon="caret right" onClick={handleSearch} className={styles.button} />
                                </React.Fragment>
                            </div>
                        </Form.Field>
                    )}
                </React.Fragment>
            );
        case 'form':
            return (
                <Form.Field fluid={fluid} width={width} style={{ minWidth: 200 }}>
                    {!!label && <label>{label}</label>}

                    <DatePicker
                        name={name}
                        onChange={handleChange}
                        value={value}
                        customInput={
                            !value && (
                                <div className="ui input">
                                    <Input value="" />
                                </div>
                            )
                        }
                    />
                </Form.Field>
            );
        default:
            return <DatePicker name={name} value={value} onChange={handleChange} />;
    }
}
