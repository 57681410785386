import { fromJS } from 'immutable';
import moment from 'moment';

import * as ACTION_TYPES from './constants';
import { NumericFormatter } from '../Common';

const initialState = fromJS({
    loading_index: false,
    loading_show: false,
    search: {
        year: moment().format('YYYY'),
    },
    statements: [],
    statement: {},
    options: [],
    columns: [
        { key: 'month', name: '对账月份' },
        { key: 'tax_mode', name: '扣税类型' },
        { key: 'tax_rate', name: '税率', formatter: NumericFormatter },
        { key: 'total', name: '金额', formatter: NumericFormatter, fixed: 2 },
        { key: 'tax', name: '税额', formatter: NumericFormatter, fixed: 2 },
        { key: 'amount', name: '税价合计', formatter: NumericFormatter, fixed: 2 },
        { key: 'collect', name: '收款金额', formatter: NumericFormatter, fixed: 2 },
        { key: 'remain', name: '未收金额', formatter: NumericFormatter, fixed: 2 },
        { key: 'confirm_at', name: '确认时间', width: 150 },
    ],
});

export default function StatementReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.STATEMENT_LOADING_INDEX:
            return action.toggle
                ? state.set('loading_index', action.toggle)
                : state.update('loading_index', loading => !loading);

        case ACTION_TYPES.STATEMENT_LOADING_SHOW:
            return action.toggle
                ? state.set('loading_show', action.toggle)
                : state.update('loading_show', loading => !loading);

        case ACTION_TYPES.STATEMENT_SEARCH:
            return state.set('search', fromJS(action.search));

        case ACTION_TYPES.STATEMENT_INDEX:
            return state.set('statements', fromJS(action.statements)).set('loading_index', false);

        case ACTION_TYPES.STATEMENT_SHOW:
            return state.set('statement', fromJS(action.statement)).set('loading_show', false);

        case ACTION_TYPES.STATEMENT_RESET:
            return initialState.set('options', state.get('options')).set('columns', state.get('columns'));

        default:
            return state;
    }
}
