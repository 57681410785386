import { fromJS } from 'immutable';

import * as ACTION_TYPES from './constants';
import { Features } from '../../../Constants';

const features = Features.filter(({ admin }) => admin);

const initialState = fromJS({
    loading_index: false,
    loading_show: false,
    search: {},
    workers: [],
    worker: {},
    options: [],
    columns: [
        { key: 'name', name: '名称', width: 100 },
        { key: 'mobile', name: '手机', width: 120 },
        {
            key: 'permission',
            name: '权限',
            minWidth: 200,
            formatter: props => {
                const { column, row } = props;
                const values = row[column.key] ?? [];

                const permission = values
                    .map(value => {
                        if (value === 'mobile') {
                            return '内管APP';
                        } else {
                            return features.find(({ key }) => key === value)?.content;
                        }
                    })
                    .join(', ');

                return <div>{permission}</div>;
            },
        },
    ],
});
export default function SettingWorkerReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.SETTING_WORKER_LOADING_INDEX:
            return action.toggle
                ? state.set('loading_index', action.toggle)
                : state.update('loading_index', loading => !loading);

        case ACTION_TYPES.SETTING_WORKER_LOADING_SHOW:
            return action.toggle
                ? state.set('loading_show', action.toggle)
                : state.update('loading_show', loading => !loading);

        case ACTION_TYPES.SETTING_WORKER_LOADING_RESOURCE:
            return action.toggle
                ? state.set('loading_resource', action.toggle)
                : state.update('loading_resource', loading => !loading);

        case ACTION_TYPES.SETTING_WORKER_SEARCH:
            return state.set('search', fromJS(action.search));

        case ACTION_TYPES.SETTING_WORKER_INDEX:
            return state.set('workers', fromJS(action.workers)).set('loading_index', false);

        case ACTION_TYPES.SETTING_WORKER_SHOW:
            return state
                .set('worker', initialState.get('worker').merge(fromJS(action.worker)))
                .set('loading_show', false);

        case ACTION_TYPES.SETTING_WORKER_OPTION:
            return state.set('options', fromJS(action.options));

        case ACTION_TYPES.SETTING_WORKER_RESET:
            return initialState.set('options', state.get('options')).set('columns', state.get('columns'));

        default:
            return state;
    }
}
