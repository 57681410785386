export const PRINT_PREVIEW_LOADING = 'PRINT_PREVIEW_LOADING';
export const PRINT_PREVIEW_SHOW = 'PRINT_PREVIEW_SHOW';
export const PRINT_PREVIEW_HIDE = 'PRINT_PREVIEW_HIDE';
export const PRINT_PREVIEW_RESET = 'PRINT_PREVIEW_RESET';
export const PRINT_PREVIEW_CONTENT = 'PRINT_PREVIEW_CONTENT';
export const PRINT_PREVIEW_TEMPLATES = 'PRINT_PREVIEW_TEMPLATES';
export const PRINT_PREVIEW_PRINTED = 'PRINT_PREVIEW_PRINTED';
export const PRINT_PREVIEW_PAGE = 'PRINT_PREVIEW_PAGE';

export const REQUEST_URL = '/api/common/print';
