import Base from '../Base';
import fetch from '../Fetch';

export default class Customer extends Base {
    static url = '/api/setting/customer';

    static rules = {
        name: { name: '名称', required: true },
        abbr: { name: '简称', required: true },
    };

    static async course(params) {
        try {
            return await fetch({ url: this.url + '/course', method: 'GET' });
        } catch (error) {
            throw error;
        }
    }

    static async resource_request(params) {
        try {
            return await fetch({ url: this.url + '/resource_request', params });
        } catch (error) {
            throw error;
        }
    }

    static async resource_create(body) {
        try {
            return await fetch({ url: this.url + '/resource_create', method: 'POST', body });
        } catch (error) {
            throw error;
        }
    }

    static async resource_upload(formData) {
        try {
            return await fetch({ url: this.url + '/resource_upload', method: 'POST', formData });
        } catch (error) {
            throw error;
        }
    }

    static async resource_download(params) {
        try {
            return await fetch({ url: this.url + '/resource_download', params });
        } catch (error) {
            throw error;
        }
    }

    static async resource_remove(body) {
        try {
            return await fetch({ url: this.url + '/resource_remove', method: 'POST', body });
        } catch (error) {
            throw error;
        }
    }

    static async resource_destroy(params) {
        try {
            return await fetch({ url: this.url + '/resource_destroy', method: 'DELETE', params });
        } catch (error) {
            throw error;
        }
    }
}
