import { isImmutable } from 'immutable';
import update from 'immutability-helper';
import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';

import { Menu, Segment, Sidebar } from 'semantic-ui-react';

import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import styles from '../styles.module.scss';

export default forwardRef(function DataGridColumn(props, ref) {
    const { onChange = () => {} } = props;

    const initState = {
        visible: false,
        columns: [],
    };
    const [state, setState] = useState(initState);

    const { columns, visible } = state;

    useImperativeHandle(ref, () => ({
        handleOpen,
        handleClose,
    }));

    const handleOpen = columns => {
        setState({
            visible: true,
            columns: isImmutable(columns) ? columns.toJS() : columns instanceof Array ? columns : [],
        });
    };

    const handleClose = () => {
        onChange(columns);
        setState(initState);
    };

    const handleItemClick = (evt, values) => {
        const { index } = values;
        let updated = [...columns];
        if (updated[index]) {
            updated[index].disabled = !updated[index]?.disabled;
            setState(state => ({
                ...state,
                columns: updated,
            }));
        }
    };

    const MenuItem = props => {
        const { item, id, index, moveCard } = props;
        const ref = useRef(null);
        const [{ handlerId }, drop] = useDrop({
            accept: 'item',
            collect(monitor) {
                return {
                    handlerId: monitor.getHandlerId(),
                };
            },
            hover(item, monitor) {
                if (!ref.current) return;

                const dragIndex = item.index;
                const hoverIndex = index;
                // Don't replace items with themselves
                if (dragIndex === hoverIndex) return;

                // Determine rectangle on screen
                const hoverBoundingRect = ref.current?.getBoundingClientRect();
                // Get vertical middle
                const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

                // Determine mouse position
                const clientOffset = monitor.getClientOffset();
                // Get pixels to the top
                const hoverClientY = clientOffset.y - hoverBoundingRect.top;
                // Only perform the move when the mouse has crossed half of the items height
                // When dragging downwards, only move when the cursor is below 50%
                // When dragging upwards, only move when the cursor is above 50%
                // Dragging downwards
                if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;

                // Dragging upwards
                if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

                // Time to actually perform the action
                moveCard(dragIndex, hoverIndex);
                // Note: we're mutating the monitor item here!
                // Generally it's better to avoid mutations,
                // but it's good here for the sake of performance
                // to avoid expensive index searches.

                item.index = hoverIndex;
            },
        });

        const [{ isDragging }, drag] = useDrag({
            type: 'item',
            item: () => {
                return { id, index };
            },
            collect: monitor => ({
                isDragging: monitor.isDragging(),
            }),
        });

        const opacity = isDragging ? 0 : 1;
        drag(drop(ref));

        return (
            <div ref={ref} className={styles.item} style={{ opacity }} data-handler-id={handlerId}>
                <Menu.Item
                    data-handler-id={handlerId}
                    key={item.key}
                    id={item.key}
                    index={index}
                    content={item.name}
                    icon={item.disabled ? 'square outline' : 'square check outline'}
                    onClick={(evt, values) => handleItemClick(evt, { ...values, index })}
                />
            </div>
        );
    };

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setState(state => ({
            ...state,
            columns: update(state.columns, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, state.columns[dragIndex]],
                ],
            }),
        }));
    }, []);

    const renderItem = (item, index) => {
        return <MenuItem key={item.key} index={index} id={item.key} item={item} moveCard={moveCard} />;
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <Sidebar className={styles.column} direction="right" animation="overlay" visible={visible}>
                <Menu attached inverted borderless>
                    <Menu.Item content="档位设定" />
                    <Menu.Menu position="right">
                        <Menu.Item icon="close" link onClick={handleClose} />
                    </Menu.Menu>
                </Menu>
                <Segment attached>
                    <Menu fluid attached compact vertical>
                        {columns.map((item, i) => renderItem(item, i))}
                    </Menu>
                </Segment>
            </Sidebar>
        </DndProvider>
    );
});
