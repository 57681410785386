import React from 'react';
import { Button, Dropdown } from 'semantic-ui-react';

export default function DetailAction(props) {
    const { add = true, save = true, remove = true, print, onAction = () => {}, customInput } = props;

    return (
        <div style={{ padding: 14 }}>
            {add && <Button size="tiny" color="blue" content="新增" icon="add" action="add" onClick={onAction} />}
            {save && <Button size="tiny" color="green" content="保存" icon="save" action="update" onClick={onAction} />}
            {remove && (
                <Dropdown
                    selectOnBlur={false}
                    selectOnNavigation={false}
                    icon={null}
                    value=""
                    trigger={<Button size="tiny" color="red" content="删除" icon="trash" />}
                    options={[{ value: 'remove', text: '你确定要删除吗？' }]}
                    action="destroy"
                    onChange={onAction}
                />
            )}
            {print && (
                <Button size="tiny" color="black" content="打印" icon="print" action="print" onClick={onAction} />
            )}
            {customInput}
        </div>
    );
}
