import * as ACTION_TYPES from './constants';

const initialState = {
    peerConnection: null,
    socket: null,
    track: null,
    simulators: [],
    monitor: {},
};

export default function DashboardReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.DASHBOARD_CONNECT:
            return { ...state, socket: action.socket };

        case ACTION_TYPES.DASHBOARD_PEER:
            return { ...state, peerConnection: action.peerConnection };

        case ACTION_TYPES.DASHBOARD_TRACK:
            return { ...state, track: action.track };

        case ACTION_TYPES.DASHBOARD_MONITOR:
            return { ...state, monitor: { ts: new Date().valueOf(), data: action.monitor } };

        case ACTION_TYPES.DASHBOARD_DISCONNECT:
            return { ...state, socket: null };

        case ACTION_TYPES.DASHBOARD_RESET:
            return { ...initialState, simulators: state.simulators };

        case ACTION_TYPES.DASHBOARD_SIMULATOR:
            return { ...state, simulators: action.simulators };

        default:
            return state;
    }
}
