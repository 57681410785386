import { Table, Menu, Icon, Button, Segment, Input, Dropdown } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { is, fromJS, List } from 'immutable';
import React, { useEffect, useState } from 'react';
import Actions from './actions';
import styles from './styles.module.scss';

export default function SettingCourse() {
    const dispatch = useDispatch();

    const [activeIndex, setActiveIndex] = useState(-1);

    const loading = useSelector(
        state => state.SettingCourseReducer.get('loading'),
        (a, b) => is(a, b)
    );

    const courses = useSelector(
        state => state.SettingCourseReducer.get('courses'),
        (a, b) => is(a, b)
    );

    useEffect(() => {
        dispatch(Actions.requests());
        return () => {
            dispatch(Actions.reset());
        };
    }, []);

    const handleChange = (evt, values) => {
        const { name, value, idx } = values;
        dispatch(Actions.index(courses.setIn([activeIndex, 'courses_items', idx, name], value)));
    };
    const handleAction = (evt, values) => {
        const { name: icon = '', action, idx } = values;

        const current = courses.getIn([activeIndex, 'courses_items', idx]);

        switch (action) {
            case 'add':
                return dispatch(
                    Actions.index(
                        courses.updateIn([activeIndex, 'courses_items'], courses_items =>
                            courses_items.push(fromJS({}))
                        )
                    )
                );
            case 'update':
                return dispatch(Actions.create(courses.get(activeIndex)));
            case 'up':
                return dispatch(
                    Actions.index(
                        courses
                            .deleteIn([activeIndex, 'courses_items', idx])
                            .updateIn([activeIndex, 'courses_items'], courses_items =>
                                courses_items.insert(idx - 1, current).map((c, idx) => c.set('idx', idx))
                            )
                    )
                );

            case 'down':
                return dispatch(
                    Actions.index(
                        courses
                            .deleteIn([activeIndex, 'courses_items', idx])
                            .updateIn([activeIndex, 'courses_items'], courses_items =>
                                courses_items.insert(idx + 1, current).map((c, idx) => c.set('idx', idx))
                            )
                    )
                );

            case 'delete':
                return dispatch(Actions.index(courses.deleteIn([activeIndex, 'courses_items', idx])));

            default:
        }
    };

    const handleMenuClick = (evt, { index }) => {
        setActiveIndex(index);
    };

    const menuItems = courses
        .map(course => ({
            key: course.get('id'),
            content: course.get('name'),
        }))
        .toJS();

    const courses_items = activeIndex > -1 ? (courses.getIn([activeIndex, 'courses_items']) ?? List()) : List();

    return (
        <div className={'flexHorizontalContent ' + styles.container}>
            <Segment.Group inverted horizontal className="flexHorizontalContent">
                <Menu
                    tabular
                    attached
                    as={Segment}
                    vertical
                    style={{ flex: 0, minWidth: 200, padding: 0 }}
                    items={menuItems}
                    onItemClick={handleMenuClick}
                />
                <Segment attached>
                    <div className="toolbar" style={{ marginBottom: 14 }}>
                        <Button
                            size="tiny"
                            color="blue"
                            content="新增"
                            icon="add"
                            action="add"
                            disabled={activeIndex < 0}
                            onClick={handleAction}
                        />
                        <Button
                            size="tiny"
                            color="green"
                            content="保存"
                            icon="save"
                            action="update"
                            disabled={activeIndex < 0}
                            onClick={handleAction}
                        />
                    </div>
                    <div className="content">
                        <Table singleLine celled striped textAlign="center" selectable>
                            <Table.Header>
                                <Table.Row style={{ position: 'sticky', top: 1, zIndex: 10 }}>
                                    <Table.HeaderCell collapsing>操作</Table.HeaderCell>
                                    <Table.HeaderCell>名称</Table.HeaderCell>
                                    <Table.HeaderCell style={{ width: 200 }}>考照类型</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>使用客户</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {courses_items.map((item, idx) => (
                                    <Table.Row key={activeIndex + '_' + idx}>
                                        <Table.Cell>
                                            <Icon
                                                action="up"
                                                name="caret up"
                                                link={idx > 0}
                                                disabled={!idx}
                                                onClick={(evt, values) => handleAction(evt, { ...values, idx })}
                                            />
                                            <Icon
                                                action="down"
                                                name="caret down"
                                                link={idx < courses_items.size - 1}
                                                disabled={idx === courses_items.size - 1}
                                                onClick={(evt, values) => handleAction(evt, { ...values, idx })}
                                            />
                                            <Icon
                                                action="delete"
                                                name="close"
                                                link={!item.get('customers_count') > 0}
                                                color="red"
                                                disabled={item.get('customers_count') > 0}
                                                onClick={(evt, values) => handleAction(evt, { ...values, idx })}
                                            />
                                        </Table.Cell>
                                        <Table.Cell style={{ padding: 0 }}>
                                            <Input
                                                fluid
                                                name="name"
                                                value={item.get('name')}
                                                onChange={(evt, values) => handleChange(evt, { ...values, idx })}
                                            />
                                        </Table.Cell>
                                        <Table.Cell style={{ padding: 0 }}>
                                            <Dropdown
                                                icon={null}
                                                selection
                                                style={{ margin: 0 }}
                                                fluid
                                                multiple
                                                name="licenses"
                                                value={(item.get('licenses') ?? List()).toJS()}
                                                options={[
                                                    { value: 'C1', text: 'C1' },
                                                    { value: 'C2', text: 'C2' },
                                                ]}
                                                onChange={(evt, { name, value }) =>
                                                    handleChange(evt, { name, value: fromJS(value), idx })
                                                }
                                            />
                                        </Table.Cell>
                                        <Table.Cell>{item.get('customers_count')}</Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </div>
                </Segment>
            </Segment.Group>
        </div>
    );
}

export { default as SettingCourseReducer } from './reducers';
export { default as SettingCourseAction } from './actions';
