import axios, { AxiosError } from 'axios';

import { isImmutable } from 'immutable';

import { Toast } from '../../Components/Common';

const validate = (data, rules) => {
    if (data instanceof Object) {
        for (const rule of Object.entries(rules)) {
            const [key, value] = rule;
            const { name, required } = value;
            switch (true) {
                case data[key] instanceof Array && !data[key].length:
                    throw `${name}不得空白`;
                case required && !data[key]?.toString().length:
                    throw `${name}不得空白`;
                default:
            }
        }
    }
};

export default async function fetch({
    url,
    method = 'GET',
    query,
    params,
    body,
    timeout = 60000,
    required,
    responseType,
    rules,
    formData,
    headers,
}) {
    const token = sessionStorage.getItem('token') ?? localStorage.getItem('token');

    // body = JSON.stringify(body ?? {});
    // query = queryString.stringify(query ?? {});

    body = isImmutable(body) ? body.toJS() : body;
    query = isImmutable(query) ? query.toJS() : query;

    query = Object.entries(query ?? {}).reduce((a, [key, value]) => {
        return { ...a, [key]: value instanceof Array ? value.join(',') : value };
    }, {});

    url = !!params ? url + '/' + params : url;

    let options = {
        method,
        url,
        responseType,
        timeout,
        params: query,
        data: body,
    };

    options = token ? { ...options, headers: { Authorization: 'Bearer ' + token } } : options;

    if (formData) {
        options = { ...options, data: formData, timeout: Number.MAX_SAFE_INTEGER };
    }

    try {
        if (!!body && !!rules) validate(body, rules);

        const response = await axios(options);

        const { data } = response;

        if (data.error) throw data;

        return data;
    } catch (error) {
        switch (true) {
            case error instanceof AxiosError:
                switch (true) {
                    case error.response?.status === 401:
                        Toast.error('账号密码错误');
                        break;
                    case error.response?.status === 403:
                        Toast.error('权限不足');
                        break;
                    case error.response?.status === 404:
                        Toast.error('资源不存在');
                        break;
                    case error.response?.status === 500:
                        Toast.error('服务器错误');
                        break;
                    case error.response?.status === 502:
                        Toast.error('服务器错误');
                        break;
                    case error.response?.status === 503:
                        Toast.error('服务器错误');
                        break;
                    case error.response?.status === 504:
                        Toast.error('服务器错误');
                        break;
                    default:
                        Toast.error(error.code + ':' + (error?.response?.data?.Toast ?? error?.message));
                }
                break;
            case !!error?.code:
                if (error.code === 50008) {
                    window.location.replace('/');
                }
                Toast.error(error.code + ':' + (error?.response?.data?.message ?? error?.message));
                break;
            case !!error?.message:
                Toast.error(error.message);
                break;
            default:
                Toast.error(error.toString());
        }

        throw error;
    }
}
