import React, { useEffect, useRef, useState } from 'react';
import { fromJS, is } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';

import { Sidebar } from 'semantic-ui-react';
import SchoolActions from '../School/actions';

import { DataGrid } from '../Common';

import Actions from './actions';

export default function Clearance(props) {
    const dispatch = useDispatch();

    const loading_index = useSelector(
        state => state.ClearanceReducer.get('loading_index'),
        (a, b) => is(a, b)
    );

    const clearances = useSelector(
        state => state.ClearanceReducer.get('clearances'),
        (a, b) => is(a, b)
    );

    const search = useSelector(
        store => store.ClearanceReducer.get('search'),
        (a, b) => is(a, b)
    );

    const defaultColumns = useSelector(
        store => store.ClearanceReducer.get('columns'),
        (a, b) => is(a, b)
    );

    const columns = useSelector(
        store => store.MainReducer.getIn(['gui', 'Clearance'], defaultColumns),
        (a, b) => is(a, b)
    ).toJS();

    const school_options = useSelector(
        state => state.SchoolReducer.get('options'),
        (a, b) => is(a, b)
    ).toJS();

    const [selected, setSelected] = useState([]);

    useEffect(() => {
        dispatch(SchoolActions.options());
    }, []);

    useEffect(() => {
        dispatch(Actions.requests());
    }, [search]);

    useEffect(() => {
        DataGridRef?.current?.handleSelectedRowsChange();
    }, [clearances]);

    const handleSearch = (evt, values) => {
        const { name, value } = values;
        if (name) {
            dispatch(Actions.search(search.set(name, value instanceof Array ? value.join(',') : value)));
        } else {
            dispatch(Actions.search(search.merge(fromJS(values))));
        }
    };

    const handleAction = (evt, { action }) => {
        switch (action) {
            case 'refresh':
                return dispatch(Actions.requests());
            default:
        }
    };

    const handleChange = (evt, { value }) => {
        dispatch(Actions.index(value));
        dispatch(Actions.update({ value }));
    };

    const DataGridRef = useRef();

    return (
        <div className="flexHorizontalContent">
            <Sidebar.Pushable className="flexHorizontalContent">
                <DataGrid
                    addition={false}
                    sortable={false}
                    selected={selected}
                    ref={DataGridRef}
                    loading={loading_index}
                    columns={columns}
                    rows={clearances}
                    name="clearances"
                    defaultSortColumns={[{ columnKey: 'name', direction: 'ASC' }]}
                    onAction={handleAction}
                    onChange={handleChange}
                />
            </Sidebar.Pushable>
        </div>
    );
}

export { default as ClearanceReducer } from './reducers';
export { default as ClearanceAction } from './actions';
