import { fromJS } from 'immutable';
import moment from 'moment';
import { Icon } from 'semantic-ui-react';
import * as ACTION_TYPES from './constants';
import { APP_OPTIONS } from './constants';
import { DropdownFormatter } from '../../Common';

const initialState = fromJS({
    loading_index: false,
    loading_show: false,
    search: {
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().endOf('month').format('YYYY-MM-DD'),
        date_mode: 'current',
        status: 1,
    },
    versions: [],
    version: {},
    options: [],
    columns: [
        { key: 'app', name: 'APP 类型', width: 100, formatter: DropdownFormatter, options: APP_OPTIONS },
        { key: 'no', name: '版本号', width: 200 },
        {
            key: 'url',
            name: '下载',
            width: 80,
            formatter: props => {
                const { url = '' } = props.row;
                return (
                    !!url && (
                        <Icon
                            name="download"
                            link
                            onClick={evt => {
                                evt.preventDefault();
                                evt.stopPropagation();
                                const a = document.createElement('a');
                                a.href = url;
                                document.body.appendChild(a);
                                a.click();
                                document.body.removeChild(a);
                            }}
                        />
                    )
                );
            },
        },
        { key: 'remark', name: '序号' },
    ],
});
export default function SettingVersionReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.SETTING_VERSION_LOADING_INDEX:
            return action.toggle
                ? state.set('loading_index', action.toggle)
                : state.update('loading_index', loading => !loading);

        case ACTION_TYPES.SETTING_VERSION_LOADING_SHOW:
            return action.toggle
                ? state.set('loading_show', action.toggle)
                : state.update('loading_show', loading => !loading);

        case ACTION_TYPES.SETTING_VERSION_SEARCH:
            return state.set('search', fromJS(action.search));

        case ACTION_TYPES.SETTING_VERSION_INDEX:
            return state.set('versions', fromJS(action.versions)).set('loading_index', false);

        case ACTION_TYPES.SETTING_VERSION_SHOW:
            return state
                .set('version', initialState.get('version').merge(fromJS(action.version)))
                .set('loading_show', false);

        case ACTION_TYPES.SETTING_VERSION_OPTION:
            return state.set('options', fromJS(action.options));

        case ACTION_TYPES.SETTING_VERSION_RESET:
            return initialState.set('options', state.get('options')).set('columns', state.get('columns'));

        default:
            return state;
    }
}
