import { fromJS } from 'immutable';

import * as ACTION_TYPES from './constants';

const initialState = fromJS({
    loading_index: false,
    loading_show: false,
    courses: [],
});
export default function SettingResourceReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.SETTING_COURSE_LOADING:
            return action.toggle !== undefined
                ? state.set('loading', action.toggle)
                : state.update('loading', loading => !loading);

        case ACTION_TYPES.SETTING_COURSE_INDEX:
            return state.set('courses', fromJS(action.courses)).set('loading', false);

        case ACTION_TYPES.SETTING_COURSE_RESET:
            return initialState;

        default:
            return state;
    }
}
