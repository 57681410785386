import Base from '../Base';
import fetch from '../Fetch';

export default class Assessment extends Base {
    static url = '/api/assessment';

    static async certificate_request() {
        try {
            return await fetch({ url: this.url + '/certificate' });
        } catch (error) {
            throw error;
        }
    }

    static async certificate_update(body) {
        try {
            return await fetch({ url: this.url + '/certificate', method: 'POST', body });
        } catch (error) {
            throw error;
        }
    }
}
