import { fromJS } from 'immutable';
import moment from 'moment';

import * as ACTION_TYPES from './constants';
import { DateFormatter, NumericFormatter } from '../../Common';

const initialState = fromJS({
    loading_index: false,
    loading_show: false,
    loading_resource: false,
    search: {
        year: moment().startOf('year').format('YYYY-MM-DD'),
        customer: '',
        status: 1,
    },
    statements: [],
    statement: {},
    collect: {
        date: moment().format('YYYY-MM-DD'),
    },
    columns: [
        { key: 'customers_name', name: '客户', minWidth: 300 },
        { key: 'month', name: '对账月份', width: 100, formatter: DateFormatter, format: 'YYYY-MM' },
        { key: 'tax_mode', name: '扣税类型', width: 80 },
        { key: 'tax_rate', name: '税率', formatter: NumericFormatter, minWidth: 50, maxWidth: 50, width: 50 },
        { key: 'total', name: '金额', formatter: NumericFormatter, fixed: 2, minWidth: 80, maxWidth: 80, width: 80 },
        { key: 'tax', name: '税额', formatter: NumericFormatter, fixed: 2, minWidth: 80, maxWidth: 80, width: 80 },
        {
            key: 'amount',
            name: '税价合计',
            formatter: NumericFormatter,
            fixed: 2,
            minWidth: 80,
            maxWidth: 80,
            width: 80,
        },
        {
            key: 'collect',
            name: '收款金额',
            formatter: NumericFormatter,
            fixed: 2,
            minWidth: 80,
            maxWidth: 80,
            width: 80,
        },
        {
            key: 'remain',
            name: '未收金额',
            formatter: NumericFormatter,
            fixed: 2,
            minWidth: 80,
            maxWidth: 80,
            width: 80,
        },
        { key: 'confirm_at', name: '确认时间', width: 150 },
    ],
});
export default function SettingFinanceReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.SETTING_FINANCE_LOADING_INDEX:
            return action.toggle
                ? state.set('loading_index', action.toggle)
                : state.update('loading_index', loading => !loading);

        case ACTION_TYPES.SETTING_FINANCE_LOADING_SHOW:
            return action.toggle
                ? state.set('loading_show', action.toggle)
                : state.update('loading_show', loading => !loading);

        case ACTION_TYPES.SETTING_FINANCE_SEARCH:
            return state.set('search', fromJS(action.search));

        case ACTION_TYPES.SETTING_FINANCE_INDEX:
            return state.set('statements', fromJS(action.statements)).set('loading_index', false);

        case ACTION_TYPES.SETTING_FINANCE_SHOW:
            return state
                .set('statement', initialState.get('statement').merge(fromJS(action.statement)))
                .set('loading_show', false);

        case ACTION_TYPES.SETTING_FINANCE_COLLECT:
            return state
                .set('collect', initialState.get('collect').merge(fromJS(action.collect)))
                .set('loading_show', false);

        case ACTION_TYPES.SETTING_FINANCE_RESET:
            return initialState.set('options', state.get('options')).set('columns', state.get('columns'));

        default:
            return state;
    }
}
