import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Icon } from 'semantic-ui-react';

import styles from './styles.module.scss';

export default forwardRef(function Tree(props, ref) {
    const { treeData = [], onSelect = () => {} } = props;

    const [active, setActive] = useState();

    useEffect(() => {
        onSelect(active);
    }, [active]);

    useImperativeHandle(ref, () => ({
        handleActive,
    }));

    const handleActive = id => {
        setActive(id);
    };

    const NestedItem = ({ item }) => {
        const isLeaf = !item?.children?.length;
        const isActive = active === item.id;

        return (
            <React.Fragment>
                <div className={'item ' + (isActive ? 'active' : '')} onClick={() => handleActive(item.id)}>
                    {!isLeaf ? <Icon name="folder" color="yellow" /> : <Icon name="square outline" />}
                    {item.no} {item.name}
                </div>
                {item.children && (
                    <ul>
                        {item.children.map((child, index) => (
                            <li key={index}>
                                <NestedItem item={child} />
                            </li>
                        ))}
                    </ul>
                )}
            </React.Fragment>
        );
    };

    return (
        <div className={styles.container}>
            {treeData.map((item, index) => (
                <NestedItem key={index} item={item} />
            ))}
        </div>
    );
});
