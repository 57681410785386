import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { fromJS, is, List } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';

import { Sidebar } from 'semantic-ui-react';

import { DataGrid } from '../../Common';

import Actions from './actions';
import Search from './Search';
import SettingCustomerAction from '../Customer/actions';
import { NUMERIC_FORMAT } from '../../../Constants/Utils';
import FilePreviewActions from '../../Common/FilePreview/actions';

const Detail = lazy(() => import('./Detail'));
const Collect = lazy(() => import('./Collect'));

export default function SettingFinance(props) {
    const dispatch = useDispatch();

    const [selected, setSelected] = useState([]);

    const loading_index = useSelector(
        state => state.SettingFinanceReducer.get('loading_index'),
        (a, b) => is(a, b)
    );

    const loading_show = useSelector(
        state => state.SettingFinanceReducer.get('loading_show'),
        (a, b) => is(a, b)
    );

    const loading_resource = useSelector(
        state => state.SettingFinanceReducer.get('loading_resource'),
        (a, b) => is(a, b)
    );

    const statements = useSelector(
        state => state.SettingFinanceReducer.get('statements'),
        (a, b) => is(a, b)
    );

    const statement = useSelector(
        state => state.SettingFinanceReducer.get('statement'),
        (a, b) => is(a, b)
    );

    const search = useSelector(
        store => store.SettingFinanceReducer.get('search'),
        (a, b) => is(a, b)
    );

    const defaultColumns = useSelector(
        store => store.SettingFinanceReducer.get('columns'),
        (a, b) => is(a, b)
    );

    const columns = useSelector(
        store => store.MainReducer.getIn(['gui', 'Finance'], defaultColumns),
        (a, b) => is(a, b)
    ).toJS();

    useEffect(() => {
        dispatch(SettingCustomerAction.options());
    }, []);

    useEffect(() => {
        dispatch(Actions.requests());
    }, [search]);

    const handleRowSelect = selected => {
        setSelected(selected);
    };

    const handleSearch = (evt, values) => {
        const { name, value } = values;
        if (name) {
            dispatch(Actions.search(search.set(name, value instanceof Array ? value.join(',') : value)));
        } else {
            dispatch(Actions.search(search.merge(fromJS(values))));
        }
    };

    const handleAction = (evt, { action, selected }) => {
        switch (action) {
            case 'collect':
                return CollectRef.current.handleOpen();
            case 'refresh':
                return dispatch(Actions.requests());
            case 'destroy':
                return dispatch(Actions.destroy());
            case 'print':
                const token = sessionStorage.getItem('token') ?? localStorage.getItem('token');
                const name = '对帐单-' + statement.get('customers_abbr') + ' (' + statement.get('month') + ')';
                const files = statement.get('files') ?? List();
                return dispatch(
                    FilePreviewActions.open({
                        name,
                        token,
                        files,
                    })
                );
            case 'exclude':
                return dispatch(Actions.exclude(selected));
            default:
        }
    };
    //                            '/data/statements/' + statement.get('id') + '.html',
    const handleSelect = row => {
        dispatch(Actions.request(row.id));
        return DetailRef?.current?.handleOpen();
    };

    const handleChange = (evt, values) => {
        const { name, value, checked } = values;

        if (name === 'courses' || name === 'demo' || name === 'features') {
            if (name === 'features' && value === 'scan' && !checked) {
                dispatch(Actions.show(statement.set(name, List())));
            } else {
                dispatch(
                    Actions.show(
                        statement.update(name, values => {
                            return checked
                                ? (values ?? List()).push(value)
                                : (values ?? List()).filter(c => c !== value);
                        })
                    )
                );
            }
        } else {
            if (name) {
                dispatch(Actions.show(statement.set(name, fromJS(value))));
            } else {
                dispatch(Actions.show(statement.merge(fromJS(values))));
            }
        }
    };

    const DetailRef = useRef();
    const CollectRef = useRef();

    const TOTAL_REMAIN = statements.reduce((a, b) => a + Number(b.get('remain') ?? 0), 0);

    const status = [{ label: '未收合计', value: NUMERIC_FORMAT(TOTAL_REMAIN, 2) }];

    return (
        <div className="flexHorizontalContent">
            <Search selected={selected} search={search} onSearch={handleSearch} onAction={handleAction} />
            <Sidebar.Pushable className="flexHorizontalContent">
                <DataGrid
                    status={status}
                    refresh={false}
                    addition={false}
                    loading={loading_index}
                    columns={columns}
                    rows={statements}
                    defaultSortColumns={[{ columnKey: 'name', direction: 'ASC' }]}
                    onAction={handleAction}
                    onSelect={handleSelect}
                />
                <Suspense fallback={null}>
                    <Detail
                        ref={DetailRef}
                        loading={loading_show}
                        statement={statement}
                        onAction={handleAction}
                        onChange={handleChange}
                    />
                    <Collect
                        ref={CollectRef}
                        loading={loading_show}
                        statement={statement}
                        onAction={handleAction}
                        onChange={handleChange}
                    />
                </Suspense>
            </Sidebar.Pushable>
        </div>
    );
}

export { default as SettingFinanceReducer } from './reducers';
export { default as SettingFinanceAction } from './actions';
