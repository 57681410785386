import Base from '../Base';
import fetch from '../Fetch';

export default class Student extends Base {
    static url = '/api/student';

    static rules = {
        // project2_coaches_id: { name: '科目二教练', required: true },
        // project3_coaches_id: { name: '科目三教练', required: true },
        // simulator_coaches_id: { name: '模拟培训教练', required: true },
    };

    static async school() {
        try {
            return await fetch({ url: this.url + '/school' });
        } catch (error) {
            throw error;
        }
    }
}
