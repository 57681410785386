import fetch from '../Fetch';

export default class Login {
    static url = '/api/login';

    static rules = {
        username: { name: '登入帐号', required: true },
        password: { name: '登入密码', required: true },
    };

    static async login(body) {
        try {
            return await fetch({ url: this.url, body, method: 'POST', rules: this.rules });
        } catch (error) {
            throw error;
        }
    }

    static async token() {
        try {
            return await fetch({ url: this.url + '/token' });
        } catch (error) {
            throw error;
        }
    }
}
