import Base from '../Base';
import fetch from '../Fetch';

export default class PDF extends Base {
    static url = '/api/common/file';

    static async index(query) {
        try {
            return await fetch({ url: this.url, query });
        } catch (error) {
            throw error;
        }
    }
}
