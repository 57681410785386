import moment from 'moment';

export default function DateFormatter(props) {
    const { column, row } = props;

    const { format = 'YYYY-MM-DD' } = column;

    const value = row[column.key] ?? '';

    return moment(value).isValid() ? moment(value).format(format) : '';
}
