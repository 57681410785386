import React from 'react';

export default function Api(props) {
    const contents = [
        {
            title: 'APP',
            children: [
                { content: '学员注册', icon: 'file outline', key: 'Registration' },
                { content: '学员登入', icon: 'file outline', key: 'Login' },
                { content: '预约查询', icon: 'file outline', key: 'Booking' },
                { content: '预约新增', icon: 'file outline', key: 'Booking' },
                { content: '预约取消', icon: 'file outline', key: 'Booking' },
            ],
        },
        {
            title: '模拟器',
            children: [
                { content: '学员登入', icon: 'file outline', key: 'Login' },
                { content: '预约查询', icon: 'file outline', key: 'Booking' },
                { content: '记录上传', icon: 'file outline', key: 'Record' },
            ],
        },
    ];

    return (
        <div className="flexHorizontalContent">
            <iframe style={{ flex: 1 }} frameBorder="off" />
        </div>
    );
}
