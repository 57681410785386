import { fromJS } from 'immutable';

import * as ACTION_TYPES from './constants';

const initialState = fromJS({
    loading_index: false,
    loading_show: false,
    resources: [],
    resource: {},
    groups: [],
    options: [],
    search: {
        customer: 'common',
    },
    columns: [
        { key: 'customers_abbr', name: '客户', width: 100 },
        { key: 'schools_name', name: '驾校', width: 100 },
        { key: 'resources_groups_name', name: '分类名称', width: 150 },
        { key: 'resources_name', name: '名称', width: 100 },
        { key: 'description', name: '描述' },
        { key: 'resources_files_name', name: '档案名称', width: 150 },
        { key: 'checksum', name: '校验和', minWidth: 300 },
        { key: 'size', name: '档案大小', width: 90 },
        { key: 'uploaded_at', name: '上传日期', width: 160 },
    ],
});
export default function SettingResourceReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.SETTING_RESOURCE_LOADING_INDEX:
            return action.toggle !== undefined
                ? state.set('loading_index', action.toggle)
                : state.update('loading_index', loading => !loading);

        case ACTION_TYPES.SETTING_RESOURCE_LOADING_SHOW:
            return action.toggle !== undefined
                ? state.set('loading_show', action.toggle)
                : state.update('loading_show', loading => !loading);

        case ACTION_TYPES.SETTING_RESOURCE_SEARCH:
            return state.set('search', fromJS(action.search));

        case ACTION_TYPES.SETTING_RESOURCE_INDEX:
            return state.set('resources', fromJS(action.resources)).set('loading_index', false);

        case ACTION_TYPES.SETTING_RESOURCE_GROUP:
            return state.set('groups', fromJS(action.groups)).set('loading_show', false);

        case ACTION_TYPES.SETTING_RESOURCE_SHOW:
            return state
                .set('resource', initialState.get('resource').merge(fromJS(action.resource)))
                .set('loading_show', false);

        case ACTION_TYPES.SETTING_RESOURCE_OPTION:
            return state.set('options', fromJS(action.options));

        case ACTION_TYPES.SETTING_RESOURCE_RESET:
            return initialState.set('options', state.get('options')).set('columns', state.get('columns'));

        default:
            return state;
    }
}
