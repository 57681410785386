import { QRCodeCanvas } from '@cheprasov/qrcode';

import { is } from 'immutable';

import moment from 'moment';

import React, { useEffect, useRef, useState } from 'react';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Dropdown, Input, Menu, Segment, Sidebar } from 'semantic-ui-react';

import { NUMERIC_FORMAT } from '../../../Constants/Utils';

import Actions from './actions';
import styles from './styles.module.scss';

export default function CommonPrintPreview(props) {
    const dispatch = useDispatch();

    const [template, setTemplate] = useState('');

    const visible = useSelector(store => store.PrintPreviewReducer.get('visible'), shallowEqual);

    const templates = useSelector(
        store => store.PrintPreviewReducer.get('templates'),
        (a, b) => is(a, b)
    ).toJS();

    const param = useSelector(
        store => store.PrintPreviewReducer.get('param'),
        (a, b) => is(a, b)
    );

    const content = useSelector(
        store => store.PrintPreviewReducer.get('content'),
        (a, b) => is(a, b)
    ).toJS();

    const pages = useSelector(
        store => store.PrintPreviewReducer.get('pages'),
        (a, b) => is(a, b)
    ).toJS();

    useEffect(() => {
        if (!template && templates.length) {
            setTemplate(templates.find(({ text }) => text === param.get('kind'))?.value ?? templates[0]?.value);
        }
    }, [templates]);

    useEffect(() => {
        if (template) {
            const iframe = iframeRef.current;
            // iframe.sandbox = 'allow-same-origin';
            iframe.contentWindow.document.open();

            if (pages.length) {
                let [head] = template.match(/<body>([\s\S\r\n]*?)<\/body>/gim) ?? [];
                head = head?.replace(/<body>|<\/body>/gim, '');
                head = template.replace(head, '');
                iframe.contentWindow.document.write(
                    head.replace(
                        '<body></body>',
                        pages
                            .map(({ content }) => {
                                const page = createHtml({ template, content });
                                let [body] = page.match(/<body>([\s\S\r\n]*?)<\/body>/gim) ?? [];
                                body = body?.replace(/<body>|<\/body>/gim, '');
                                return body;
                            })
                            .join('')
                    )
                );
            } else {
                iframe.contentWindow.document.write(createHtml({ template, content }));
            }
            iframe.contentWindow.document.close();
        }
    }, [template]);

    useEffect(() => {
        if (visible && param.get('template')) {
            if (
                templates.findIndex(({ text }) => text === param.get('template')) !==
                templates.findIndex(t => t.value === template)
            ) {
                setTemplate(templates.find(({ text }) => text === param.get('template'))?.value);
            }
        }
    }, [visible, param.get('template'), templates]);

    const createHtml = ({ template, content }) => {
        if (!template || !content) return '';

        const { qrcode, details = [] } = content ?? {};

        let [body] = template.match(/<body>([\s\S\r\n]*?)<\/body>/gim) ?? [];
        body = body?.replace(/<body>|<\/body>/gim, '');

        let html = template?.replace(body, ``);

        let [detail = ''] = template.match(/<!--表身开始-->([\s\S\r\n]*?)<!--表身结束-->/gim) ?? [];
        detail = detail?.replace(/<!--表身开始-->|<!--表身结束-->/gim, '');

        let [detail_header = ''] = detail.match(/<!--表身表头开始-->([\s\S\r\n]*?)<!--表身表头结束-->/gim) ?? [];
        detail_header = detail_header?.replace(/<!--表身表头开始-->|<!--表身表头结束-->/gim, '');

        let [detail_footer = ''] = detail.match(/<!--表身表尾开始-->([\s\S\r\n]*?)<!--表身表尾结束-->/gim) ?? [];
        detail_footer = detail_footer?.replace(/<!--表身表尾开始-->|<!--表身表尾结束-->/gim, '');

        let [last = ''] = body.match(/<!--最后一页开始-->([\s\S\r\n]*?)<!--最后一页结束-->/gim) ?? [];
        last = last?.replace(/<!--最后一页开始-->|<!--最后一页结束-->/gim, '');

        body = body?.replace(detail, '');
        body = body?.replace(last, '');

        detail = detail?.replace(detail_header, '');
        detail = detail?.replace(detail_footer, '');

        let [, repeat] = template?.match(/<meta.*?name="repeat.*?content="(.*?)".*?\/>/i) ?? [];
        repeat = repeat ?? details.length;

        const chunks = details.length
            ? details.reduce((resultArray, item, index) => {
                  const chunkIndex = Math.floor(index / repeat);

                  if (!resultArray[chunkIndex]) {
                      resultArray[chunkIndex] = []; // start a new chunk
                  }
                  resultArray[chunkIndex].push(item);
                  return resultArray;
              }, [])
            : [[]];

        let counter = 1;
        const _content = chunks
            .map((chunk, page) => {
                let _body = body;
                let _last = last;

                let _qrcode = new QRCodeCanvas(qrcode, { padding: 0 }).toDataURL();

                _body = _body?.replace(new RegExp(`<!--qrcode-->`, 'igm'), `<img src="${_qrcode}"/>`);
                _body = _body?.replace(new RegExp(`<!--print_date-->`, 'igm'), moment().format('YYYY-MM-DD HH:mm'));

                Object.entries(content).map(([key, value]) => {
                    const re = new RegExp(`<!--${key}-->`, 'igm');
                    _body = _body?.replace(re, value ?? '');
                });

                let _detail = chunk
                    .map((ch, idx) => {
                        let _detail = detail ?? '';
                        let _detail_header = detail_header ?? '';
                        let _detail_footer = detail_footer ?? '';

                        Object.entries(ch ?? {}).map(([key, value]) => {
                            const re = new RegExp(`<!--${key}-->`, 'igm');
                            if (key === 'qrcode') {
                                const svg = value ? new QRCodeCanvas(value, { padding: 0 }).toDataURL() : '';
                                _detail = _detail?.replace(re, `<img src="${svg}"/>`);
                            } else {
                                _detail = _detail?.replace(re, value ?? '');
                            }

                            _detail_header = _detail_header?.replace(re, value ?? '');
                            _detail_footer = _detail_footer?.replace(re, value ?? '');

                            _detail = _detail?.replace('<!--idx-->', idx + 1);
                            _detail = _detail?.replace('<!--counter-->', counter);
                        });

                        counter++;

                        if (ch.header) {
                            _detail = _detail?.replace('<!--表身表头开始--><!--表身表头结束-->', _detail_header);
                        }

                        if (ch.footer) {
                            _detail = _detail?.replace('<!--表身表尾开始--><!--表身表尾结束-->', _detail_footer);
                        }

                        _body = _body?.replace('<!--page-->', page + 1);

                        return _detail;
                    })
                    .join('');

                const page_count = chunk.reduce((a, b) => a + Number(b.count), 0);
                const page_amount = chunk.reduce((a, b) => a + Number(b.amount), 0);
                const page_quantity = chunk.reduce((a, b) => a + Number(b.quantity), 0);

                _body = _body
                    ?.replace('<!--page_quantity-->', NUMERIC_FORMAT(page_quantity))
                    .replace('<!--page_count-->', NUMERIC_FORMAT(page_count))
                    .replace('<!--page_count-->', NUMERIC_FORMAT(page_count, 2));

                if (page === chunks.length - 1) {
                    Object.entries(content).map(([key, value]) => {
                        const re = new RegExp(`<!--${key}-->`, 'igm');
                        _last = _last?.replace(re, value ?? '');
                    });

                    _body = _body?.replace('<!--最后一页开始--><!--最后一页结束-->', _last);
                }

                return _body
                    ?.replace('<!--表身开始--><!--表身结束-->', _detail)
                    ?.replace('<!--pages-->', chunks.length);
            })
            .join('');

        return html.replace(`<body></body>`, `<body>${_content}</body>`);
    };

    const afterprint = evt => {
        const iframe = iframeRef.current;
        if (evt) dispatch(Actions.printed(param.set('content', content)));
        iframe.contentWindow.removeEventListener('afterprint', afterprint);
        dispatch(Actions.reset());
    };

    const handlePrint = () => {
        const iframe = iframeRef.current;
        iframe.contentWindow.addEventListener('afterprint', afterprint);
        iframe.contentWindow.print();
    };

    const handleClose = () => {
        dispatch(Actions.hide());
    };

    const handleHidden = () => {
        const iframe = iframeRef.current;
        // iframe.sandbox = 'allow-same-origin';
        iframe.contentWindow.document.open();
        iframe.contentWindow.document.write('');
        iframe.contentWindow.document.close();
        dispatch(Actions.reset());
        afterprint();
        setTemplate('');
    };

    const handleTemplateChange = (evt, { value }) => {
        setTemplate(value);
    };

    const match = template?.match(/@page([\s\S\r\n]*?){([\s\S]*?)}/i) ?? [];

    const [width = 0, height = 0] =
        match[0]
            ?.replace(/\{|\}|\r|\n|\s|;|@page/gim, '')
            ?.split(/size:|mm/)
            ?.filter(Boolean) ?? [];

    const iframeRef = useRef();

    return (
        <Sidebar
            visible={visible}
            className={styles.container}
            direction="top"
            animation="overlay"
            onHidden={handleHidden}>
            <Menu inverted borderless attached className={styles.title}>
                <Menu.Item content="打印预览" />
                <Menu.Menu position="right">
                    <Menu.Item icon="close" link onClick={handleClose} />
                </Menu.Menu>
            </Menu>
            <Menu borderless attached size="mini">
                <Menu.Item content="打印" link onClick={handlePrint} />
                <Dropdown
                    item
                    placeholder="模板"
                    options={templates}
                    value={template}
                    onChange={handleTemplateChange}
                />
                <Menu.Item style={{ width: 150 }}>
                    <Input fluid label="份数" type="number" defaultValue={1} />
                </Menu.Item>
            </Menu>
            <Segment attached style={{ padding: 0 }}>
                <div className="sheet" style={{ flex: 1 }}>
                    <iframe ref={iframeRef} style={{ flex: 1, backgroundColor: '#999' }} />
                </div>
            </Segment>
        </Sidebar>
    );
}

export { default as PrintPreviewReducer } from './reducers';
export { default as PrintPreviewAction } from './actions';
