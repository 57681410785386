import { fromJS } from 'immutable';

import * as ACTION_TYPES from './constants';

const initialState = fromJS({
    loading: false,
    user: {},
    features: [],
    interfaces: {},
});

export default function LoginReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.LOGIN_LOADING:
            return state.update('loading', loading => !loading);

        case ACTION_TYPES.LOGIN_SUCCESS:
            return state
                .set('user', fromJS(action.user))
                .set('features', fromJS(action.features))
                .set('loading', false);

        case ACTION_TYPES.LOGIN_RESET:
            return initialState;

        default:
            return state;
    }
}
