import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';

export default class Toast {
    static _toast = null;

    static success = message => {
        if (this._toast) {
            toast.update(this._toast, { render: message, type: 'success', onClose: () => (this._toast = null) });
        } else {
            this._toast = toast.success(message, { onClose: () => (this._toast = null) });
        }
    };

    static error = message => {
        if (this._toast) {
            toast.update(this._toast, { render: message, type: 'error', onClose: () => (this._toast = null) });
        } else {
            this._toast = toast.error(message, { onClose: () => (this._toast = null) });
        }
    };
}
