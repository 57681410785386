import { fromJS } from 'immutable';

import * as ACTION_TYPES from './constants';

const initialState = fromJS({
    loading_show: false,
    setting: {},
    options: [],
});
export default function SettingReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.SETTING_LOADING_SHOW:
            return action.toggle
                ? state.set('loading_show', action.toggle)
                : state.update('loading_show', loading => !loading);

        case ACTION_TYPES.SETTING_SHOW:
            return state.set('setting', fromJS(action.setting)).set('loading_show', false);

        case ACTION_TYPES.SETTING_RESET:
            return initialState;

        default:
            return state;
    }
}
