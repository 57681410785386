import { fromJS } from 'immutable';

import * as ACTION_TYPES from './constants';
import { NumericFormatter } from '../Common';

const initialState = fromJS({
    loading_index: false,
    loading_show: false,
    schools: [],
    school: {},
    options: [],
    columns: [
        { key: 'name', name: '驾校名称', width: 200, frozen: true },
        { key: 'address', name: '地址', minWidth: 300, frozen: true },
        { key: 'simulators_count', name: '模拟器', width: 70, formatter: NumericFormatter },
        { key: 'students_no_confirm', name: '未确认', width: 70, formatter: NumericFormatter },
        { key: 'students_study', name: '在学', width: 70, formatter: NumericFormatter },
        { key: 'students_finish', name: '完成', width: 70, formatter: NumericFormatter },
        { key: 'students_pause', name: '停学', width: 70, formatter: NumericFormatter },
        { key: 'students_total', name: '学员合计', width: 70, formatter: NumericFormatter },
    ],
});
export default function SchoolReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.SCHOOL_LOADING_INDEX:
            return action.toggle
                ? state.set('loading_index', action.toggle)
                : state.update('loading_index', loading => !loading);

        case ACTION_TYPES.SCHOOL_LOADING_SHOW:
            return action.toggle
                ? state.set('loading_show', action.toggle)
                : state.update('loading_show', loading => !loading);

        case ACTION_TYPES.SCHOOL_INDEX:
            return state.set('schools', fromJS(action.schools)).set('loading_index', false);

        case ACTION_TYPES.SCHOOL_SHOW:
            return state.set('school', fromJS(action.school)).set('loading_show', false);

        case ACTION_TYPES.SCHOOL_OPTION:
            return state.set('options', fromJS(action.options));

        case ACTION_TYPES.SCHOOL_RESET:
            return initialState.set('options', state.get('options')).set('columns', state.get('columns'));

        default:
            return state;
    }
}
