import { fromJS } from 'immutable';
import moment from 'moment';

import * as ACTION_TYPES from './constants';

const initialState = fromJS({
    loading_index: false,
    loading_show: false,
    search: {
        date: moment().format('YYYY-MM-DD'),
    },
    bookings: [],
    booking: {},
    setting: {},
});
export default function BookingReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.BOOKING_LOADING_INDEX:
            return action.toggle
                ? state.set('loading_index', action.toggle)
                : state.update('loading_index', loading => !loading);

        case ACTION_TYPES.BOOKING_LOADING_SHOW:
            return action.toggle
                ? state.set('loading_show', action.toggle)
                : state.update('loading_show', loading => !loading);

        case ACTION_TYPES.BOOKING_SEARCH:
            return state.set('search', fromJS(action.search));

        case ACTION_TYPES.BOOKING_INDEX:
            return state.set('bookings', fromJS(action.bookings)).set('loading_index', false);

        case ACTION_TYPES.BOOKING_SETTING:
            return state.set('setting', fromJS(action.setting)).set('loading_show', false);

        case ACTION_TYPES.BOOKING_SHOW:
            return state.update('booking', booking => booking.merge(fromJS(action.booking))).set('loading_show', false);

        case ACTION_TYPES.BOOKING_RESET:
            return initialState.set('options', state.get('options')).set('columns', state.get('columns'));

        default:
            return state;
    }
}
