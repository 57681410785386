import { fromJS, isMap } from 'immutable';
import React from 'react';

import styles from './styles.module.scss';

export default function CommonoStatusBar(props) {
    let { status = [], data = {}, style } = props;

    data = !isMap(data) ? fromJS(data) : data;

    let _status = [];

    const keys = Object.keys(data.toJS());

    _status = keys.includes('creator')
        ? _status.concat([
              {
                  label: '创建',
                  value: (data.get('creator') ?? '') + ' ' + (data.get('creator') ? data.get('created_at') : ''),
              },
          ])
        : _status;
    _status = keys.includes('updater')
        ? _status.concat([
              {
                  label: '修改',
                  value: (data.get('updater') ?? '') + ' ' + (data.get('updated_at') ? data.get('updated_at') : ''),
              },
          ])
        : _status;
    _status = keys.includes('printer')
        ? _status.concat([
              {
                  label: '打印',
                  value: (data.get('printer') ?? '') + ' ' + (data.get('printed_at') ? data.get('printed_at') : ''),
              },
          ])
        : _status;
    _status = keys.includes('auditor')
        ? _status.concat([
              {
                  label: '审核',
                  value: (data.get('auditor') ?? '') + ' ' + (data.get('audited_at') ? data.get('audited_at') : ''),
              },
          ])
        : _status;

    const StatsItem = props => {
        const { item } = props;

        const { label, value, spacer, onClick, index } = item;

        return spacer ? (
            <div className="spacer" key={index}></div>
        ) : (
            <React.Fragment key={index}>
                <div className="label" onClick={onClick}>
                    {label}
                </div>
                <div className={label ? 'value' : 'label'} onClick={onClick}>
                    {value}
                </div>
            </React.Fragment>
        );
    };

    return (
        <div className={styles.container} style={style}>
            {(_status.length ? _status : status).map((s, index) => (
                <StatsItem key={index} item={s} index={index} />
            ))}
        </div>
    );
}
