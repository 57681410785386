import Base from '../Base';
import fetch from '../Fetch';

export default class Worker extends Base {
    static url = '/api/setting/revenue';

    static rules = {
        kind: { name: '类型', required: true },
        date: { name: '日期', required: true },
        method: { name: '收付方式', required: true },
    };

    static async statements_requests(params) {
        try {
            return await fetch({ url: this.url + '/statements', method: 'GET', params });
        } catch (error) {
            throw error;
        }
    }
}
