export const DASHBOARD_CONNECT = 'DASHBOARD_CONNECT';
export const DASHBOARD_DISCONNECT = 'DASHBOARD_DISCONNECT';
export const DASHBOARD_PEER = 'DASHBOARD_PEER';
export const DASHBOARD_TRACK = 'DASHBOARD_TRACK';
export const DASHBOARD_RESET = 'DASHBOARD_RESET';
export const DASHBOARD_SIMULATOR = 'DASHBOARD_SIMULATOR';
export const DASHBOARD_MONITOR = 'DASHBOARD_MONITOR';

export const configuration = {
    iceServers: [
        { urls: 'stun:stun.l.google.com:19302' },
        // { urls: 'stun:stun.miclus.cn:3478', username: 'studycar', credential: 'a8fa0358e406c1463f0d07763cf094a8' },
    ],
};
