import * as ACTION_TYPES from './constants';

import { Setting } from '../../../Api';

import { Toast } from '../../Common';

const Api = Setting.Revenue;

const reducer = 'SettingRevenueReducer';

export default class SettingRevenueActions {
    static loading_index = toggle => ({
        type: ACTION_TYPES.SETTING_REVENUE_LOADING_INDEX,
        toggle,
    });

    static loading_show = toggle => ({
        type: ACTION_TYPES.SETTING_REVENUE_LOADING_SHOW,
        toggle,
    });

    static search =
        (search = {}) =>
        async (dispatch, getState) => {
            dispatch({ type: ACTION_TYPES.SETTING_REVENUE_SEARCH, search });
        };

    static requests = () => async (dispatch, getState) => {
        try {
            dispatch(this.loading_index(true));
            const query = getState()[reducer].get('search');
            const res = await Api.index(query);
            if (res instanceof Error) throw res;
            dispatch(this.index(res));
        } catch (error) {
            dispatch(this.loading_index(false));
            console.error(error);
        } finally {
        }
    };

    static index = ({ revenues = [], deposit = 0 }) => ({
        type: ACTION_TYPES.SETTING_REVENUE_INDEX,
        revenues,
        deposit,
    });

    static request = params => async (dispatch, getState) => {
        try {
            dispatch(this.loading_show());
            const res = await Api.show(params);
            if (res instanceof Error) throw res;
            dispatch(this.show(res));
        } catch (error) {
            dispatch(this.loading_show(false));
            console.error(error);
        } finally {
        }
    };

    static create = () => async (dispatch, getState) => {
        try {
            dispatch(this.loading_show());
            const body = getState()[reducer].get('revenue');
            const res = await Api.create(body);
            if (res instanceof Error) throw res;
            dispatch(this.show(res));
            Toast.success('记录已保存');
            dispatch(this.requests());
        } catch (error) {
            dispatch(this.loading_show(false));
            console.error(error);
        } finally {
        }
    };

    static destroy = () => async (dispatch, getState) => {
        try {
            const params = getState()[reducer].getIn(['revenue', 'id']);
            dispatch(this.loading_show());
            const res = await Api.destroy(params);
            if (res instanceof Error) throw res;
            dispatch(this.show(res));
            Toast.success('记录已删除');
            dispatch(this.requests());
        } catch (error) {
            dispatch(this.loading_show());
            console.error(error);
        } finally {
        }
    };

    static statements_requests = params => async (dispatch, getState) => {
        try {
            const res = await Api.statements_requests(params);
            if (res instanceof Error) throw res;
            dispatch(this.statements(res));
        } catch (error) {
            dispatch(this.loading_show(false));
            console.error(error);
        } finally {
        }
    };

    static statements = (statements = []) => ({
        type: ACTION_TYPES.SETTING_REVENUE_STATEMENT,
        statements,
    });

    static show = (revenue = {}) => ({
        type: ACTION_TYPES.SETTING_REVENUE_SHOW,
        revenue,
    });

    static reset = () => ({
        type: ACTION_TYPES.SETTING_REVENUE_RESET,
    });

    static column = (columns = []) => ({
        type: ACTION_TYPES.SETTING_REVENUE_COLUMN,
        columns,
    });
}
