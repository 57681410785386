export const SEX_OPTIONS = [
    { value: true, text: '男' },
    { value: false, text: '女' },
];

export const STUDENT_STATUS_OPTIONS = [
    { value: 1, text: '在学' },
    { value: 0, text: '通过' },
    { value: -1, text: '停学' },
];

export const WORKER_BLOOD_OPTIONS = [
    { value: 'A', text: 'A' },
    { value: 'B', text: 'B' },
    { value: 'O', text: 'O' },
    { value: 'AB', text: 'AB' },
];

export const AUDIT_STATUS_OPTIONS = [
    { value: 1, text: '未审' },
    { value: 0, text: '已审' },
    { value: -1, text: '驳回' },
];

export const LICENSE_OPTIONS = [
    { value: 'C1', text: 'C1' },
    { value: 'C2', text: 'C2' },
];

export const arrayToHierarchy = (flatArray, parent = null) => {
    return flatArray
        .filter(item => item.parent === parent)
        .map(item => ({
            ...item,
            children: arrayToHierarchy(flatArray, item.key ?? item.id),
        }));
};

export const NUMERIC_FORMAT = (value, fixed = 0, _default) => {
    const formatter = new Intl.NumberFormat('zh-CN', {
        minimumFractionDigits: fixed,
        maximumFractionDigits: fixed,
    });
    return isNaN(Number(value)) ? _default ?? formatter.format(0) : formatter.format(value);
};

export const PAY_NOTIFICATION =
    '温馨提示:您正在使用的汽车驾驶模拟培训系统即将于本月{EXPIRE}号23点59分59秒后停止服务,请您尽快联系系统服务商处理!';
