import { fromJS } from 'immutable';
import moment from 'moment';

import * as ACTION_TYPES from './constants';
import { Icon } from 'semantic-ui-react';
import { NumericFormatter } from '../../Common';
import { SETTING_COMMAND_RECORD } from './constants';

const initialState = fromJS({
    loading_index: false,
    loading_show: false,
    search: {
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().endOf('month').format('YYYY-MM-DD'),
        date_mode: 'current',
        status: 1,
    },
    commands: [],
    command: {},
    options: [],
    columns: [
        { key: 'name', name: '名称', width: 200 },
        { key: 'command', name: '操作指令' },
    ],
    records: [],
});
export default function SettingCampusReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.SETTING_COMMAND_LOADING_INDEX:
            return action.toggle
                ? state.set('loading_index', action.toggle)
                : state.update('loading_index', loading => !loading);

        case ACTION_TYPES.SETTING_COMMAND_LOADING_SHOW:
            return action.toggle
                ? state.set('loading_show', action.toggle)
                : state.update('loading_show', loading => !loading);

        case ACTION_TYPES.SETTING_COMMAND_SEARCH:
            return state.set('search', fromJS(action.search));

        case ACTION_TYPES.SETTING_COMMAND_INDEX:
            return state.set('commands', fromJS(action.commands)).set('loading_index', false);

        case ACTION_TYPES.SETTING_COMMAND_SHOW:
            return state
                .set('command', initialState.get('command').merge(fromJS(action.command)))
                .set('loading_show', false);

        case ACTION_TYPES.SETTING_COMMAND_OPTION:
            return state.set('options', fromJS(action.options));

        case ACTION_TYPES.SETTING_COMMAND_RECORD:
            return state.set('records', fromJS(action.records));

        case ACTION_TYPES.SETTING_COMMAND_RESET:
            return initialState.set('options', state.get('options')).set('columns', state.get('columns'));

        default:
            return state;
    }
}
