import * as ACTION_TYPES from './constants';

import { Setting } from '../../../Api';

import { Toast } from '../../Common';

const Api = Setting.Course;

const reducer = 'SettingCourseReducer';

export default class SettingCourseActions {
    static loading = toggle => ({
        type: ACTION_TYPES.SETTING_COURSE_LOADING,
        toggle,
    });

    static requests = () => async (dispatch, getState) => {
        try {
            dispatch(this.loading(true));
            const res = await Api.index();
            if (res instanceof Error) throw res;
            dispatch(this.index(res));
        } catch (error) {
            dispatch(this.loading(false));
            console.error(error);
        } finally {
        }
    };

    static index = (courses = []) => ({
        type: ACTION_TYPES.SETTING_COURSE_INDEX,
        courses,
    });

    static create = body => async (dispatch, getState) => {
        try {
            dispatch(this.loading());
            const res = await Api.create(body);
            if (res instanceof Error) throw res;
            Toast.success('记录已保存');
            dispatch(this.requests());
        } catch (error) {
            dispatch(this.loading(false));
            console.error(error);
        } finally {
        }
    };

    static reset = () => ({
        type: ACTION_TYPES.SETTING_COURSE_RESET,
    });
}
