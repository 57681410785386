import fetch from '../Fetch';

export default class Main {
    static url = '/api/main';

    static async version(body) {
        try {
            return await fetch({ url: this.url + '/version' });
        } catch (error) {
            throw error;
        }
    }

    static async token() {
        try {
            return await fetch({ url: this.url + '/token', method: 'GET' });
        } catch (error) {
            throw error;
        }
    }

    static async statistic(query) {
        try {
            return await fetch({ url: this.url + '/statistic', method: 'GET', query });
        } catch (error) {
            throw error;
        }
    }

    static async status(body) {
        try {
            return await fetch({ url: this.url + '/status' });
        } catch (error) {
            throw error;
        }
    }
}
