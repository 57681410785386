import * as ACTION_TYPES from './constants';

import { Setting } from '../../../Api';

import { Toast } from '../../Common';

const Api = Setting.Token;

const reducer = 'SettingTokenReducer';

export default class SettingTokenActions {
    static loading_index = toggle => ({
        type: ACTION_TYPES.SETTING_TOKEN_LOADING_INDEX,
        toggle,
    });

    static requests = () => async (dispatch, getState) => {
        try {
            dispatch(this.loading_index(true));
            const query = getState()[reducer].get('search');
            const res = await Api.index(query);
            if (res instanceof Error) throw res;
            dispatch(this.index(res));
        } catch (error) {
            dispatch(this.loading_index(false));
            console.error(error);
        } finally {
        }
    };

    static index = (tokens = []) => ({
        type: ACTION_TYPES.SETTING_TOKEN_INDEX,
        tokens,
    });

    static create = body => async (dispatch, getState) => {
        try {
            const res = await Api.create(body);
            if (res instanceof Error) throw res;
            dispatch(this.index(res));
            Toast.success('记录已保存');
        } catch (error) {
            dispatch(this.loading_index(false));
            console.error(error);
        } finally {
        }
    };

    static reset = () => ({
        type: ACTION_TYPES.SETTING_TOKEN_RESET,
    });
}
