import * as ACTION_TYPES from './constants';

import { Clearance as Api } from '../../Api';

const reducer = 'ClearanceReducer';

export default class ClearanceActions {
    static loading_index = toggle => ({
        type: ACTION_TYPES.CLEARANCE_LOADING_INDEX,
        toggle,
    });

    static requests = () => async (dispatch, getState) => {
        try {
            dispatch(this.loading_index(true));
            const query = getState()[reducer].get('search');
            const res = await Api.index(query);
            if (res instanceof Error) throw res;
            dispatch(this.index(res));
        } catch (error) {
            dispatch(this.loading_index(false));
            console.error(error);
        } finally {
        }
    };

    static index = (clearances = []) => ({
        type: ACTION_TYPES.CLEARANCE_INDEX,
        clearances,
    });

    static update = body => async (dispatch, getState) => {
        try {
            const res = await Api.create(body);
            if (res instanceof Error) throw res;
            dispatch(this.index(res));
        } catch (error) {
            console.error(error);
        } finally {
        }
    };

    static reset = () => ({
        type: ACTION_TYPES.CLEARANCE_RESET,
    });

    static column = (columns = []) => ({
        type: ACTION_TYPES.CLEARANCE_COLUMN,
        columns,
    });
}
