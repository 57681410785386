import { is, List } from 'immutable';
import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import Upload from 'rc-upload';
import { Button, Modal, Image, Segment, Checkbox, Input, Sidebar, Table } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from './actions';
import certificate_default from '../../Assets/certificate.jpg';

export default function Assessment(props) {
    const dispatch = useDispatch();

    const loading = useSelector(
        state => state.AssessmentReducer.get('loading'),
        (a, b) => is(a, b)
    );

    const courses = useSelector(
        state => state.AssessmentReducer.get('courses'),
        (a, b) => is(a, b)
    );

    const certificate = useSelector(
        state => state.AssessmentReducer.get('certificate'),
        (a, b) => is(a, b)
    );

    useEffect(() => {
        dispatch(Actions.requests());
        dispatch(Actions.certificate_request());
        return () => {
            dispatch(Actions.reset());
        };
    }, []);

    const handleChange = (evt, values) => {
        const { type, index, name, value, checked } = values;
        const [i, j] = index;
        let updated = courses;

        switch (type) {
            case 'checkbox':
                updated = updated
                    .setIn([i, 'courses_items', j, name], checked)
                    .setIn([i, 'courses_items', j, 'clearance'], checked ? 2 : null);
                break;
            default:
                updated = updated.setIn([i, 'courses_items', j, name], value);
        }

        dispatch(Actions.index(updated));
    };

    const handleSelectAll = (evt, { index, checked }) => {
        let updated = courses;

        updated = updated
            .updateIn([index, 'courses_items'], courses_items =>
                courses_items.map(item => {
                    return item
                        .set('checked', checked)
                        .update('clearance', clearance => (checked ? (clearance ? clearance : 2) : null));
                })
            )
            .setIn([index, 'selected_all'], checked);

        dispatch(Actions.index(updated));
    };

    const handleBeforeUpload = file => {
        const reader = new FileReader();

        reader.onload = function () {
            dispatch(Actions.certificate_update({ content: reader.result }));
        };

        reader.readAsDataURL(file);

        return false;
    };

    const handleAction = (evt, { action }) => {
        switch (action) {
            case 'save':
                return dispatch(Actions.create(courses));
            case 'refresh':
                return dispatch(Actions.requests());
            default:
        }
    };

    return (
        <Sidebar.Pushable className="flexHorizontalContent">
            <div style={{ display: 'flex', gap: 5, marginBottom: 7 }}>
                <Button
                    color="teal"
                    icon="refresh"
                    size="tiny"
                    content="刷新"
                    action="refresh"
                    onClick={handleAction}
                />
                <Button color="green" size="tiny" icon="save" content="保存" action="save" onClick={handleAction} />
                <Modal
                    centered={false}
                    size="large"
                    content={
                        <Upload
                            accept="image/jpeg"
                            beforeUpload={handleBeforeUpload}
                            style={{ backgroundColor: '#CCC', aspectRatio: '99/70' }}>
                            <Image
                                key={certificate || certificate_default}
                                fluid
                                src={certificate || certificate_default}
                            />
                        </Upload>
                    }
                    action={<Button content="上传证书" />}
                    trigger={<Button color="brown" size="tiny" icon="file" content="证书" action="certificate" />}
                />
            </div>
            <Segment.Group as={Segment} horizontal style={{ flex: 1, height: 0, borderBottom: 0 }} loading={loading}>
                {courses.map((course, i) => (
                    <Segment attached as={Segment.Group} style={{ display: 'flex', padding: 0, borderBottom: 0 }}>
                        <Segment attached textAlign="center">
                            {course.get('name')}
                        </Segment>
                        <Segment
                            attached
                            style={{ flex: 1, padding: 0, overflowY: 'auto', overflowX: 'hidden', borderBottom: 0 }}>
                            <Table attached celled>
                                <Table.Header>
                                    <Table.Row style={{ position: 'sticky', top: 1, zIndex: 2 }}>
                                        <Table.HeaderCell collapsing>
                                            <Checkbox
                                                index={i}
                                                checked={course.get('selected_all')}
                                                onChange={handleSelectAll}
                                            />
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>项目</Table.HeaderCell>
                                        <Table.HeaderCell collapsing>通关</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {(course.get('courses_items') ?? List()).map((item, j) => (
                                        <Table.Row>
                                            <Table.Cell>
                                                <Checkbox
                                                    name="checked"
                                                    index={[i, j]}
                                                    checked={item.get('checked')}
                                                    onChange={handleChange}
                                                />
                                            </Table.Cell>
                                            <Table.Cell>{item.get('name')}</Table.Cell>
                                            <Table.Cell>
                                                <Input
                                                    name="clearance"
                                                    index={[i, j]}
                                                    style={{ width: 50 }}
                                                    value={item.get('clearance') ?? ''}
                                                    onChange={handleChange}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        </Segment>
                    </Segment>
                ))}
            </Segment.Group>
        </Sidebar.Pushable>
    );
}

export { default as AssessmentReducer } from './reducers';
