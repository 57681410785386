import Base from '../Base';
import fetch from '../Fetch';

export default class Coach extends Base {
    static url = '/api/coach';

    static rules = {
        name: { name: '姓名', required: true },
        mobile: { name: '手机', required: true },
        schools_id: { name: '驾校', required: true },
        kind: { name: '科目', required: true },
    };

    static async confirm(body) {
        try {
            return await fetch({ url: this.url + '/confirm', body, method: 'POST' });
        } catch (error) {
            throw error;
        }
    }
}
