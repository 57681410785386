import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { is, Map } from 'immutable';
import moment from 'moment';
import { Button, Icon, Label, Sidebar } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';

import { SimulatorAction } from '../Simulator';
import { SchoolAction } from '../School';
import { StudentAction } from '../Student';

import { DataGrid, DatePicker, Toast } from '../Common';
import Actions from './actions';

import styles from './styles.module.scss';

const Detail = lazy(() => import('./Detail'));
const Booked = lazy(() => import('./Booked'));
const Setting = lazy(() => import('./Setting'));

export default function BookSchedule(props) {
    const dispatch = useDispatch();

    const setting = useSelector(
        state => state.BookingReducer.get('setting'),
        (a, b) => is(a, b)
    );

    const loading_index = useSelector(
        state => state.BookingReducer.get('loading_index'),
        (a, b) => is(a, b)
    );

    const loading_show = useSelector(
        state => state.BookingReducer.get('loading_show'),
        (a, b) => is(a, b)
    );

    const bookings = useSelector(
        state => state.BookingReducer.get('bookings'),
        (a, b) => is(a, b)
    );

    const booking = useSelector(
        state => state.BookingReducer.get('booking'),
        (a, b) => is(a, b)
    );

    const search = useSelector(
        state => state.BookingReducer.get('search'),
        (a, b) => is(a, b)
    );

    const schools_options = useSelector(
        state => state.SchoolReducer.get('options'),
        (a, b) => is(a, b)
    ).toJS();

    const handleSettingAction = (evt, values) => {
        const start_hour = setting.get('start_hour') ?? '';
        const start_minute = setting.get('start_minute') ?? '';
        const end_hour = setting.get('end_hour') ?? '';
        const end_minute = setting.get('end_minute') ?? '';
        const shortest = setting.get('shortest') ?? '';
        if (!start_hour || !start_minute) {
            return Toast.error('预约开始时间不得空白');
        }
        if (!end_hour || !end_minute) {
            return Toast.error('预约结束时间不得空白');
        }
        if (!shortest) {
            return Toast.error('预约最短时长不得空白');
        }
        dispatch(Actions.setting_create());
    };

    const handleAction = (evt, { action, value }) => {
        switch (action) {
            case 'add':
                dispatch(Actions.show());
                return DetailRef?.current?.handleOpen();
            case 'setting':
                return SettingRef?.current?.handleOpen();
            case 'refresh':
                return dispatch(Actions.requests());
            case 'destroy':
                return dispatch(Actions.destroy(value));
            case 'update':
                return dispatch(Actions.create(value));
            default:
        }
    };

    const handleChange = (evt, { value }) => {};

    const handleSettingChange = (evt, { name, value }) => {
        dispatch(Actions.setting(setting.set(name, value)));
    };

    const handleSearch = (evt, values) => {
        const { icon, name, value } = values;

        if (icon) {
            if (icon.includes('left')) {
                dispatch(
                    Actions.search(search.set('date', moment(search_date).subtract(1, 'day').format('YYYY-MM-DD')))
                );
            }
            if (icon.includes('right')) {
                dispatch(Actions.search(search.set('date', moment(search_date).add(1, 'day').format('YYYY-MM-DD'))));
            }
        } else {
            dispatch(Actions.search(search.set(name, value)));
        }
    };

    const handleRowSelect = (row, column) => {
        const value = row[column.key];
        if ((value ?? []).length) {
            BookedRef.current.handleOpen(value);
        }
    };

    useEffect(() => {
        dispatch(Actions.setting_request());
        dispatch(SimulatorAction.options());
        dispatch(SchoolAction.options());
        dispatch(StudentAction.options());
        return () => {
            dispatch(Actions.reset());
        };
    }, []);

    useEffect(() => {
        dispatch(Actions.requests());
    }, [search]);

    const search_date = search.get('date');

    const start_hour = setting.get('start_hour') ?? '';
    const start_minute = setting.get('start_minute') ?? '';
    const end_hour = setting.get('end_hour') ?? '';
    const end_minute = setting.get('end_minute') ?? '';
    const shortest = setting.get('shortest') ?? 0;

    const start =
        start_hour && start_minute
            ? moment(search_date + '  ' + start_hour + ':' + start_minute, 'YYYY-MM-DD HH:mm')
            : null;

    const end =
        end_hour && end_minute ? moment(search_date + '  ' + end_hour + ':' + end_minute, 'YYYY-MM-DD HH:mm') : null;

    const CellFormatter = props => {
        const [open, setOpen] = useState(false);
        const { column, row } = props;
        const values = row[column.key] ?? [];

        return values.map(value => {
            let color = 'green';
            if (moment().format('YYYY-MM-DD HH:mm') > value.book_end_time) {
                if (!value.start_time) {
                    color = 'pink';
                } else {
                    color = 'gray';
                }
            }
            return <Label as="a" color={color} content={value.students_mobile} />;
        });
    };

    const rows_count =
        shortest > 0
            ? moment(search_date + '  ' + end_hour + ':' + end_minute, 'YYYY-MM-DD HH:mm').diff(
                  moment(search_date + '  ' + start_hour + ':' + start_minute, 'YYYY-MM-DD HH:mm'),
                  'minutes'
              ) / shortest
            : 0;

    const rows = [...Array.from({ length: rows_count }).keys()].map(value => {
        const time = start.clone().add(shortest * value, 'minute');

        const founds = bookings
            .map(booking => {
                const schools_id = booking.get('schools_id');
                const found = booking.getIn(['bookings', time.format('YYYY-MM-DD HH:mm')]);
                if (found)
                    return {
                        [schools_id]: found,
                    };
            })
            .filter(Boolean)
            .reduce((a, b) => a.merge(b), Map())
            .toJS();

        return {
            time:
                time.format('HH:mm') +
                ' - ' +
                moment(time.format('YYYY-MM-DD HH:mm')).add(shortest, 'minute').format('HH:mm'),
            ...founds,
        };
    });

    const columns = [{ key: 'time', name: '时间', width: 120 }].concat(
        bookings
            .map(booking => ({
                key: booking.get('schools_id'),
                name: booking.get('schools_name') + `(${booking.get('simulators_count')})`,
                minWidth: 150,
                formatter: CellFormatter,
            }))
            .toJS()
    );

    const DetailRef = useRef();
    const BookedRef = useRef();
    const SettingRef = useRef();

    return (
        <Sidebar.Pushable className={'flexHorizontalContent ' + styles.container}>
            <div style={{ display: 'flex', gap: 5, marginBottom: 7 }}>
                <Button
                    color="purple"
                    size="tiny"
                    icon="setting"
                    content="设定"
                    action="setting"
                    onClick={handleAction}
                />
                <Button
                    color="teal"
                    icon="refresh"
                    size="tiny"
                    content="刷新"
                    action="refresh"
                    onClick={handleAction}
                />
                <Button color="blue" size="tiny" icon="add" content="新增" action="add" onClick={handleAction} />
                <Button basic size="tiny" icon="caret left" onClick={handleSearch} />
                <DatePicker name="date" value={moment(search_date).toDate()} onChange={handleSearch} />
                <Button basic size="tiny" icon="caret right" onClick={handleSearch} />
                <div
                    style={{
                        flex: 1,
                        textAlign: 'right',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}>
                    <Icon name="square" color="green" size="large" /> 待赴{' '}
                    <Icon name="square" color="grey" size="large" />
                    完成 <Icon name="square" color="pink" size="large" /> 爽约
                </div>
            </div>
            <DataGrid
                loading={loading_index}
                key={columns.toString()}
                add={false}
                addition={false}
                refresh={false}
                sortable={false}
                columns={columns}
                rows={rows}
                onSelect={handleRowSelect}
            />
            <Suspense fallback={null}>
                <Booked ref={BookedRef} onAction={handleAction} />
                <Detail
                    ref={DetailRef}
                    loading={loading_show}
                    setting={setting}
                    booking={booking}
                    bookings={bookings}
                    onAction={handleAction}
                    onChange={handleChange}
                />
                <Setting
                    ref={SettingRef}
                    loading={loading_show}
                    setting={setting}
                    booking={booking}
                    onAction={handleSettingAction}
                    onChange={handleSettingChange}
                />
            </Suspense>
        </Sidebar.Pushable>
    );
}

export { default as BookingReducer } from './reducers';
export { default as BookingAction } from './actions';
