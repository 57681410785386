export const SETTING_SCHOLAR_COLUMN = 'SETTING_SCHOLAR_COLUMN';
export const SETTING_SCHOLAR_INDEX = 'SETTING_SCHOLAR_INDEX';
export const SETTING_SCHOLAR_LOADING_INDEX = 'SETTING_SCHOLAR_LOADING_INDEX';
export const SETTING_SCHOLAR_LOADING_SHOW = 'SETTING_SCHOLAR_LOADING_SHOW';
export const SETTING_SCHOLAR_OPTION = 'SETTING_SCHOLAR_OPTION';
export const SETTING_SCHOLAR_RESET = 'SETTING_SCHOLAR_RESET';
export const SETTING_SCHOLAR_SEARCH = 'SETTING_SCHOLAR_SEARCH';
export const SETTING_SCHOLAR_SHOW = 'SETTING_SCHOLAR_SHOW';
export const SETTING_SCHOLAR_SCHOOL = 'SETTING_SCHOLAR_SCHOOL';

export const SETTING_SCHOLAR_LICENSE_OPTIONS = [
    { value: 'C1', text: 'C1' },
    { value: 'C2', text: 'C2' },
];
