import './Styles/index.scss';
import 'semantic-ui-css/semantic.min.css';
import 'react-data-grid/lib/styles.css';

import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import store from './Store';

const Main = lazy(() => import('./Components/Main'));
const Login = lazy(() => import('./Components/Login'));
const Dashboard = lazy(() => import('./Components/Dashboard'));

const isProduction = process.env.REACT_APP_ENV !== 'local';

const token = sessionStorage.getItem('token') ?? localStorage.getItem('token');

if (!token) {
    if (document.location.pathname !== '/') document.location.assign('/');
}

const root = ReactDOM.createRoot(document.getElementById('root'));
//
const basename = isProduction ? '/simulator' : '/';

root.render(
    <Provider store={store}>
        <Suspense fallback={<div />}>
            <BrowserRouter basename={basename}>
                <Routes>
                    <Route path="/Dashboard/*" element={<Dashboard />} />
                    <Route path="/Main/*" element={<Main />} />
                    <Route path="/" element={<Login />} />
                </Routes>
            </BrowserRouter>
        </Suspense>
        <ToastContainer
            delay={1}
            newestOnTop
            transition={Slide}
            position="top-center"
            autoClose={1000}
            hideProgressBar={true}
            limit={1}
            draggable={false}
            pauseOnFocusLoss={false}
            pauseOnHover={false}
        />
    </Provider>
);

// reportWebVitals(console.log);
