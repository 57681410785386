import fetch from '../Fetch';

export default class Main {
    static url = '/api/dashboard';

    static async simulators(body) {
        try {
            return await fetch({ url: this.url + '/simulators' });
        } catch (error) {
            throw error;
        }
    }
}
