import React, { useEffect, useRef, useState } from 'react';
import { DataGrid } from '../Common';
import { useDispatch, useSelector } from 'react-redux';
import { is } from 'immutable';
import Actions from './actions';
import { Sidebar } from 'semantic-ui-react';
import FilePreviewActions from '../Common/FilePreview/actions';

export default function Statement(props) {
    const dispatch = useDispatch();

    const [row, setRow] = useState({});

    const loading_index = useSelector(
        state => state.StatementReducer.get('loading_index'),
        (a, b) => is(a, b)
    );

    const statements = useSelector(
        state => state.StatementReducer.get('statements'),
        (a, b) => is(a, b)
    );

    const statement = useSelector(
        state => state.StatementReducer.get('statement'),
        (a, b) => is(a, b)
    );

    const search = useSelector(
        store => store.StatementReducer.get('search'),
        (a, b) => is(a, b)
    );

    const defaultColumns = useSelector(
        store => store.StatementReducer.get('columns'),
        (a, b) => is(a, b)
    );

    const columns = useSelector(
        store => store.MainReducer.getIn(['gui', 'Coach'], defaultColumns),
        (a, b) => is(a, b)
    ).toJS();

    useEffect(() => {
        dispatch(Actions.requests());
    }, [search]);

    const handleSelect = row => {
        const token = sessionStorage.getItem('token') ?? localStorage.getItem('token');
        const name = '对帐单-' + row.customers_abbr + ' (' + row.month + ')';
        setRow(row);
        return dispatch(
            FilePreviewActions.open({
                // url: '/data/statements/' + row.id + '.html',
                name,
                token,
                files: row.files,
                confirm: row.confirm_at ? false : handleConfirm,
            })
        );
    };

    const handleAction = (evt, { action }) => {
        switch (action) {
            case 'refresh':
                return dispatch(Actions.requests());
            default:
        }
    };

    const handleConfirm = () => {
        dispatch(Actions.confirm(row));
    };

    const DataGridRef = useRef();

    return (
        <div className="flexHorizontalContent">
            <Sidebar.Pushable className="flexHorizontalContent">
                <DataGrid
                    addition={false}
                    ref={DataGridRef}
                    loading={loading_index}
                    columns={columns}
                    rows={statements}
                    onAction={handleAction}
                    onSelect={handleSelect}
                    defaultSortColumns={[{ columnKey: 'month', direction: 'ASC' }]}
                />
            </Sidebar.Pushable>
        </div>
    );
}

export { default as StatementReducer } from './reducers';
export { default as StatementAction } from './actions';
