import { fromJS } from 'immutable';
import moment from 'moment';

import * as ACTION_TYPES from './constants';
import { NumericFormatter, CheckboxFormatter } from '../../Common';

const initialState = fromJS({
    loading_index: false,
    loading_show: false,
    search: {
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().endOf('month').format('YYYY-MM-DD'),
        date_mode: 'current',
        account: '',
    },
    revenues: [],
    revenue: {},
    statements: [],
    deposit: 0,
    columns: [
        { key: 'kind', name: '类型', width: 80 },
        { key: 'date', name: '日期', width: 100 },
        { key: 'deposit', name: '押金收入', width: 80, formatter: CheckboxFormatter },
        { key: 'deposit_return', name: '押金支出', width: 80, formatter: CheckboxFormatter },
        { key: 'customers_abbr', name: '对象', width: 200 },
        { key: 'remark', name: '内容', minWidth: 200 },
        { key: 'offset', name: '可冲', width: 80, formatter: NumericFormatter, fixed: 2 },
        { key: 'income', name: '收入', width: 80, formatter: NumericFormatter, fixed: 2 },
        { key: 'outcome', name: '支出', width: 80, formatter: NumericFormatter, fixed: 2 },
        { key: 'final', name: '结存', width: 80, formatter: NumericFormatter, fixed: 2 },
    ],
});
export default function SettingRevenueReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.SETTING_REVENUE_LOADING_INDEX:
            return action.toggle
                ? state.set('loading_index', action.toggle)
                : state.update('loading_index', loading => !loading);

        case ACTION_TYPES.SETTING_REVENUE_LOADING_SHOW:
            return action.toggle
                ? state.set('loading_show', action.toggle)
                : state.update('loading_show', loading => !loading);

        case ACTION_TYPES.SETTING_REVENUE_SEARCH:
            return state.set('search', fromJS(action.search));

        case ACTION_TYPES.SETTING_REVENUE_INDEX:
            return state
                .set('revenues', fromJS(action.revenues))
                .set('deposit', action.deposit ?? 0)
                .set('loading_index', false);

        case ACTION_TYPES.SETTING_REVENUE_SHOW:
            return state
                .set('revenue', initialState.get('revenue').merge(fromJS(action.revenue)))
                .set('loading_show', false);

        case ACTION_TYPES.SETTING_REVENUE_STATEMENT:
            return state.set('statements', fromJS(action.statements));

        case ACTION_TYPES.SETTING_REVENUE_RESET:
            return initialState;

        default:
            return state;
    }
}
