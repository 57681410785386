import { Menu, Segment, Table } from 'semantic-ui-react';
import { forwardRef, useImperativeHandle, useState } from 'react';

export default function SettingSummary() {
    const [activeIndex, setActiveIndex] = useState(0);

    const tabItems = [
        { value: 'simulator', content: '模拟器使用率' },
        { value: 'student', content: '学员统计' },
        // { value: 'school', content: '驾校统计' },
        // { value: 'customer', content: '客户统计' },
    ];

    return (
        <div className="flexHorizontalContent">
            <Segment.Group inverted horizontal className="flexHorizontalContent">
                <Menu
                    activeIndex={activeIndex}
                    attached
                    vertical
                    tabular
                    style={{ width: 130 }}
                    items={tabItems}
                    onItemClick={(evt, { index }) => setActiveIndex(index)}
                />
                <Segment attached style={{ display: 'flex', flex: 1, border: 0, flexDirection: 'column' }}>
                    <div></div>
                    <Segment attached style={{ flex: 1, overflow: 'auto', padding: 0 }}>
                        <Table singleLine>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>驾校</Table.HeaderCell>
                                    <Table.HeaderCell>模拟器</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>1</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>2</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>3</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>4</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>5</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>6</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>7</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>8</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>9</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>10</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>11</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>12</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>13</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>14</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>15</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>16</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>17</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>18</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>19</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>20</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>21</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>22</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>23</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>24</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>25</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>26</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>27</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>28</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>29</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>30</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>31</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                        </Table>
                    </Segment>
                </Segment>
            </Segment.Group>
        </div>
    );
}
