import * as ACTION_TYPES from './constants';

import { Statement as Api } from '../../Api';

const reducer = 'StatementReducer';

export default class StatementActions {
    static loading_index = toggle => ({
        type: ACTION_TYPES.STATEMENT_LOADING_INDEX,
        toggle,
    });

    static loading_show = toggle => ({
        type: ACTION_TYPES.STATEMENT_LOADING_SHOW,
        toggle,
    });

    static search =
        (search = {}) =>
        async (dispatch, getState) => {
            dispatch({ type: ACTION_TYPES.STATEMENT_SEARCH, search });
        };

    static requests = () => async (dispatch, getState) => {
        try {
            dispatch(this.loading_index(true));
            const query = getState()[reducer].get('search');
            const res = await Api.index(query);
            if (res instanceof Error) throw res;
            dispatch(this.index(res));
        } catch (error) {
            dispatch(this.loading_index(false));
            console.error(error);
        } finally {
        }
    };

    static index = (statements = []) => ({
        type: ACTION_TYPES.STATEMENT_INDEX,
        statements,
    });

    static request = params => async (dispatch, getState) => {
        try {
            dispatch(this.loading_show());
            const res = await Api.show(params);
            if (res instanceof Error) throw res;
            dispatch(this.show(res));
        } catch (error) {
            dispatch(this.loading_show(false));
            console.error(error);
        } finally {
        }
    };

    static show = (statement = {}) => ({
        type: ACTION_TYPES.STATEMENT_SHOW,
        statement,
    });

    static column = (columns = []) => ({
        type: ACTION_TYPES.STATEMENT_COLUMN,
        columns,
    });

    static confirm = body => async (dispatch, getState) => {
        try {
            dispatch(this.loading_show());
            await Api.confirm(body);
            dispatch(this.requests());
        } catch (error) {
            throw error;
        } finally {
            dispatch(this.loading_show());
        }
    };
}
