import * as ACTION_TYPES from './constants';

import { Setting } from '../../../Api';

import { Toast } from '../../Common';

const Api = Setting.Resource;

const reducer = 'SettingResourceReducer';

export default class SettingResourceActions {
    static loading_index = toggle => ({
        type: ACTION_TYPES.SETTING_RESOURCE_LOADING_INDEX,
        toggle,
    });

    static loading_show = toggle => ({
        type: ACTION_TYPES.SETTING_RESOURCE_LOADING_SHOW,
        toggle,
    });

    static search =
        (search = {}) =>
        async (dispatch, getState) => {
            dispatch({ type: ACTION_TYPES.SETTING_RESOURCE_SEARCH, search });
        };

    static requests = () => async (dispatch, getState) => {
        try {
            dispatch(this.loading_index(true));
            const query = getState()[reducer].get('search');
            const res = await Api.index(query);
            if (res instanceof Error) throw res;
            dispatch(this.index(res));
        } catch (error) {
            dispatch(this.loading_index(false));
            console.error(error);
        } finally {
        }
    };

    static index = (resources = []) => ({
        type: ACTION_TYPES.SETTING_RESOURCE_INDEX,
        resources,
    });

    static request = params => async (dispatch, getState) => {
        try {
            dispatch(this.loading_show());
            const res = await Api.show(params);
            if (res instanceof Error) throw res;
            dispatch(this.show(res));
        } catch (error) {
            dispatch(this.loading_show(false));
            console.error(error);
        } finally {
        }
    };

    static create = () => async (dispatch, getState) => {
        try {
            dispatch(this.loading_show());
            const body = getState()[reducer].get('resource');
            const res = await Api.create(body);
            if (res instanceof Error) throw res;
            dispatch(this.show(res));
            Toast.success('记录已保存');
            dispatch(this.requests());
        } catch (error) {
            dispatch(this.loading_show(false));
            console.error(error);
        } finally {
        }
    };

    static destroy = () => async (dispatch, getState) => {
        try {
            const params = getState()[reducer].getIn(['resource', 'id']);
            dispatch(this.loading_show());
            const res = await Api.destroy(params);
            if (res instanceof Error) throw res;
            dispatch(this.show(res));
            Toast.success('记录已删除');
            dispatch(this.requests());
        } catch (error) {
            dispatch(this.loading_show());
            console.error(error);
        } finally {
        }
    };

    static show = (resource = {}) => ({
        type: ACTION_TYPES.SETTING_RESOURCE_SHOW,
        resource,
    });

    static reset = () => ({
        type: ACTION_TYPES.SETTING_RESOURCE_RESET,
    });

    static column = (columns = []) => ({
        type: ACTION_TYPES.SETTING_RESOURCE_COLUMN,
        columns,
    });

    static upload = body => async (dispatch, getState) => {
        try {
            dispatch(this.loading_show());
            const res = await Api.upload(body);
            if (res instanceof Error) throw res;
            dispatch(this.show(res));
            Toast.success('记录已保存');
            dispatch(this.requests());
        } catch (error) {
        } finally {
            dispatch(this.loading_show(false));
        }
    };

    static remove = body => async (dispatch, getState) => {
        try {
            dispatch(this.loading_show());
            const resource = getState()[reducer].get('resource');
            const res = await Api.remove({ ...body, resource });
            if (res instanceof Error) throw res;
            dispatch(this.show(res));
            Toast.success('记录已保存');
            dispatch(this.requests());
        } catch (error) {
        } finally {
            dispatch(this.loading_show(false));
        }
    };

    static group_requests = () => async (dispatch, getState) => {
        try {
            dispatch(this.loading_show(true));
            const res = await Api.group_index();
            if (res instanceof Error) throw res;
            dispatch(this.group_index(res));
        } catch (error) {
            dispatch(this.loading_show(false));
            console.error(error);
        } finally {
        }
    };

    static group_create = () => async (dispatch, getState) => {
        try {
            dispatch(this.loading_show());
            const body = getState()[reducer].get('groups');
            const res = await Api.group_create(body);
            if (res instanceof Error) throw res;
            Toast.success('记录已保存');
            dispatch(this.group_requests());
        } catch (error) {
            dispatch(this.loading_show(false));
            console.error(error);
        } finally {
        }
    };

    static group_index = (groups = []) => ({
        type: ACTION_TYPES.SETTING_RESOURCE_GROUP,
        groups,
    });
}
