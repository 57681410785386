import io from 'socket.io-client';
import * as ACTION_TYPES from './constants';
import { Dashboard as Api } from '../../Api';

export default class DashboardActions {
    static socket = null;

    static connect = serial => async dispatch => {
        try {
            const token = sessionStorage.getItem('token') ?? localStorage.getItem('token');

            const { origin } = window.location;

            this.socket = io.connect(origin, {
                transports: ['websocket'],
                forceNew: true,
                query: {
                    token,
                    serial,
                    action: 'cast',
                },
            });

            this.socket.on('connect', () => {
                dispatch({ type: ACTION_TYPES.DASHBOARD_CONNECT, socket: this.socket });
                this.socket.emit('message', { type: 'offer', serial });
            });

            this.socket.on('disconnect', () => {
                dispatch({ type: ACTION_TYPES.DASHBOARD_DISCONNECT });
            });

            this.socket.on('monitor', monitor => {
                dispatch({ type: ACTION_TYPES.DASHBOARD_MONITOR, monitor });
            });
        } catch (error) {
            console.log(error);
        }
    };

    static disconnect = serial => async dispatch => {
        if (this.socket) {
            this.socket.disconnect();
            this.socket = null;
        }
    };

    static reset = () => ({
        type: ACTION_TYPES.DASHBOARD_RESET,
    });

    static simulators = () => async dispatch => {
        try {
            const res = await Api.simulators();
            if (res instanceof Error) throw res;
            dispatch({ type: ACTION_TYPES.DASHBOARD_SIMULATOR, simulators: res });
        } catch (error) {}
    };
}
