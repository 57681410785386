import { Modal } from 'semantic-ui-react';
import styles from '../styles.module.scss';
import { fromJS } from 'immutable';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Actions from '../actions';

export default forwardRef(function DashboardModal(props, ref) {
    const { onClose = () => {} } = props;

    const dispatch = useDispatch();

    const iniState = fromJS({
        open: false,
        item: {},
        loading: true,
    });

    const [state, setState] = useState(iniState);
    const [sourceBuffer, setSourceBuffer] = useState(null);

    const item = state.get('item');
    const open = state.get('open');

    const monitor = useSelector(state => state.DashboardReducer.monitor, shallowEqual);

    useEffect(() => {
        if (open) {
            handleConnect();
        } else {
            dispatch(Actions.reset());
        }
    }, [open]);

    useEffect(() => {
        try {
            if (monitor.data) {
                if (sourceBuffer && !sourceBuffer.updating) sourceBuffer.appendBuffer(monitor.data);
            }
        } catch (error) {
            console.log(error, VideoRef.current);
        }
    }, [monitor]);

    useImperativeHandle(ref, () => ({
        handleOpen,
        handleClose,
        handleConnect,
    }));

    const handleOpen = item => {
        setState(state.set('open', true).set('item', item));
    };

    const handleMount = () => {
        const mediaSource = new MediaSource();
        VideoRef.current.src = URL.createObjectURL(mediaSource);
        mediaSource.addEventListener('sourceopen', () => {
            setSourceBuffer(mediaSource.addSourceBuffer('video/webm;codecs=vp8'));
        });
    };

    const handleClose = () => {
        setState(iniState);
        dispatch(Actions.disconnect(item.serial_no));
        onClose();
    };

    const handleConnect = () => {
        dispatch(Actions.connect(item.serial_no));
    };

    const VideoRef = useRef();

    return (
        <Modal
            open={open}
            onMount={handleMount}
            onClose={handleClose}
            closeIcon
            basic
            centered={false}
            size="fullscreen"
            content={
                <div className={styles.modal}>
                    <video autoPlay playsInline ref={VideoRef}></video>
                    <div className="title">
                        <div>{item.no}</div>
                        <div>{item.schools_name}</div>
                    </div>
                </div>
            }
        />
    );
});
