import * as ACTION_TYPES from './constants';

import { Assessment as Api } from '../../Api';

const reducer = 'AssessmentReducer';

export default class ClearanceActions {
    static loading = toggle => ({
        type: ACTION_TYPES.ASSESSMENT_LOADING,
        toggle,
    });

    static requests = () => async (dispatch, getState) => {
        try {
            dispatch(this.loading(true));
            const query = getState()[reducer].get('search');
            const res = await Api.index(query);
            if (res instanceof Error) throw res;
            dispatch(this.index(res));
        } catch (error) {
            dispatch(this.loading(false));
            console.error(error);
        } finally {
        }
    };

    static index = (courses = []) => ({
        type: ACTION_TYPES.ASSESSMENT_INDEX,
        courses,
    });

    static create = body => async (dispatch, getState) => {
        try {
            dispatch(this.loading(true));
            const res = await Api.create(body);
            if (res instanceof Error) throw res;
            dispatch(this.index(res));
        } catch (error) {
            dispatch(this.loading(true));
            console.error(error);
        } finally {
        }
    };

    static certificate_request = () => async (dispatch, getState) => {
        try {
            const res = await Api.certificate_request();
            if (res instanceof Error) throw res;
            dispatch(this.certificate_show(res));
        } catch (error) {
            dispatch(this.loading(true));
            console.error(error);
        } finally {
        }
    };

    static certificate_update = body => async (dispatch, getState) => {
        try {
            const res = await Api.certificate_update(body);
            if (res instanceof Error) throw res;
            dispatch(this.certificate_show(res));
        } catch (error) {
            console.error(error);
        } finally {
        }
    };

    static certificate_show = (certificate = '') => ({
        type: ACTION_TYPES.ASSESSMENT_CERTIFICATE,
        certificate,
    });

    static reset = () => ({
        type: ACTION_TYPES.ASSESSMENT_RESET,
    });
}
