import { fromJS } from 'immutable';
import moment from 'moment';

import * as ACTION_TYPES from './constants';
import { Icon } from 'semantic-ui-react';
import { NumericFormatter } from '../../Common';

const initialState = fromJS({
    loading_index: false,
    loading_show: false,
    search: {
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().endOf('month').format('YYYY-MM-DD'),
        date_mode: 'current',
        status: 1,
    },
    simulators: [],
    simulator: {},
    options: [],
    columns: [
        { key: 'no', name: '编号', width: 200 },
        { key: 'customers_name', name: '客户', width: 200 },
        { key: 'schools_name', name: '驾校', width: 200 },
        { key: 'license', name: '类型', width: 150 },
        { key: 'serial_no', name: '序号' },
        {
            key: 'online',
            name: '在线',
            width: 80,
            formatter: ({ row }) => {
                const { online } = row;
                return (
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <Icon name="circle" color={online ? 'green' : 'grey'} />
                    </div>
                );
            },
        },
        { key: 'rate', name: '使用率', width: 80, formatter: NumericFormatter, unit: '%' },
    ],
});
export default function SettingSimulatorReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.SETTING_SIMULATOR_LOADING_INDEX:
            return action.toggle
                ? state.set('loading_index', action.toggle)
                : state.update('loading_index', loading => !loading);

        case ACTION_TYPES.SETTING_SIMULATOR_LOADING_SHOW:
            return action.toggle
                ? state.set('loading_show', action.toggle)
                : state.update('loading_show', loading => !loading);

        case ACTION_TYPES.SETTING_SIMULATOR_SEARCH:
            return state.set('search', fromJS(action.search));

        case ACTION_TYPES.SETTING_SIMULATOR_INDEX:
            return state.set('simulators', fromJS(action.simulators)).set('loading_index', false);

        case ACTION_TYPES.SETTING_SIMULATOR_SHOW:
            return state
                .set('simulator', initialState.get('simulator').merge(fromJS(action.simulator)))
                .set('loading_show', false);

        case ACTION_TYPES.SETTING_SIMULATOR_OPTION:
            return state.set('options', fromJS(action.options));

        case ACTION_TYPES.SETTING_SIMULATOR_RESET:
            return initialState.set('options', state.get('options')).set('columns', state.get('columns'));

        default:
            return state;
    }
}
