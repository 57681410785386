import { Icon, Menu } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { fromJS, is } from 'immutable';
import Actions from '../actions';

export default function TapMenu(props) {
    const dispatch = useDispatch();

    const windows = useSelector(
        state => state.MainReducer.get('windows'),
        (a, b) => is(a, b)
    );

    const active = useSelector(
        state => state.MainReducer.get('active'),
        (a, b) => is(a, b)
    );

    const MenuItem = props => {
        const { window } = props;
        const content = window.get('content');
        const key = window.get('key');
        const isHome = key === 'Main';
        const isActive = window.get('key') === active.get('key');

        const handleClick = (evt, values) => {
            evt.stopPropagation();
            const { name } = values;
            if (name === 'refresh') {
                return dispatch(Actions.statistic());
            } else if (name) {
                return dispatch(Actions.close(window));
            } else {
                return dispatch(Actions.active(window));
            }
        };

        return (
            <Menu.Item active={isActive} link onClick={handleClick} style={{ paddingRight: 10 }}>
                <span style={{ marginRight: 7 }}>{content}</span>
                <Icon name={isHome ? 'refresh' : 'close'} link onClick={handleClick} />
            </Menu.Item>
        );
    };

    const _windows = fromJS([{ content: '工作台', key: 'Main' }]).concat(windows);

    return (
        <Menu attached tabular style={{ flexWrap: 'wrap' }}>
            {_windows.map(window => (
                <MenuItem window={window} key={window.get('key')} />
            ))}
        </Menu>
    );
}
