export const SETTING_BANNER_INDEX = 'SETTING_BANNER_INDEX';
export const SETTING_BANNER_LOADING_INDEX = 'SETTING_BANNER_LOADING_INDEX';
export const SETTING_BANNER_LOADING_SHOW = 'SETTING_BANNER_LOADING_SHOW';
export const SETTING_BANNER_OPTION = 'SETTING_BANNER_OPTION';
export const SETTING_BANNER_RESET = 'SETTING_BANNER_RESET';
export const SETTING_BANNER_SEARCH = 'SETTING_BANNER_SEARCH';
export const SETTING_BANNER_SHOW = 'SETTING_BANNER_SHOW';

export const solar_options = [
    { value: '立春', text: '立春' },
    { value: '雨水', text: '雨水' },
    { value: '惊蛰', text: '惊蛰' },
    { value: '春分', text: '春分' },
    { value: '清明', text: '清明' },
    { value: '谷雨', text: '谷雨' },
    { value: '立夏', text: '立夏' },
    { value: '小满', text: '小满' },
    { value: '芒种', text: '芒种' },
    { value: '夏至', text: '夏至' },
    { value: '小暑', text: '小暑' },
    { value: '大暑', text: '大暑' },
    { value: '立秋', text: '立秋' },
    { value: '处暑', text: '处暑' },
    { value: '白露', text: '白露' },
    { value: '秋分', text: '秋分' },
    { value: '寒露', text: '寒露' },
    { value: '霜降', text: '霜降' },
    { value: '立冬', text: '立冬' },
    { value: '小雪', text: '小雪' },
    { value: '大雪', text: '大雪' },
    { value: '冬至', text: '冬至' },
    { value: '小寒', text: '小寒' },
    { value: '大寒', text: '大寒' },
];
