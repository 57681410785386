import * as ACTION_TYPES from './constants';

import { Setting } from '../../../Api';

import { Toast } from '../../Common';

const Api = Setting.Setting;

const reducer = 'SettingSettingReducer';

export default class SettingActions {
    static loading_show = toggle => ({
        type: ACTION_TYPES.SETTING_LOADING_SHOW,
        toggle,
    });

    static request = params => async (dispatch, getState) => {
        try {
            dispatch(this.loading_show());
            const res = await Api.show(params);
            if (res instanceof Error) throw res;
            dispatch(this.show(res));
        } catch (error) {
            dispatch(this.loading_show(false));
            console.error(error);
        } finally {
        }
    };

    static update = () => async (dispatch, getState) => {
        try {
            dispatch(this.loading_show());
            const body = getState()[reducer].get('setting');
            const res = await Api.create(body);
            if (res instanceof Error) throw res;
            dispatch(this.show(res));
            Toast.success('记录已保存');
        } catch (error) {
            dispatch(this.loading_show(false));
            console.error(error);
        } finally {
        }
    };

    static show = (setting = {}) => ({
        type: ACTION_TYPES.SETTING_SHOW,
        setting,
    });

    static reset = () => ({
        type: ACTION_TYPES.SETTING_RESET,
    });
}
