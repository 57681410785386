import styles from './styles.module.scss';
import { Dropdown, Icon, Menu, Segment } from 'semantic-ui-react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { is } from 'immutable';
import { useEffect, useRef, useState } from 'react';
import MainActions from '../Main/actions';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Modal from './Modal';
import Simulator from './Simulator';
import Actions from './actions';

export default function Dashboard(props) {
    let clockInterval = 0;

    const navigate = useNavigate();

    const [clock, setClock] = useState('0000-00-00 00:00:00');
    const [opened, setOpened] = useState({});
    const [filter, setFilter] = useState({ schools_id: [] });

    const dispatch = useDispatch();

    const simulators = useSelector(state => state.DashboardReducer.simulators, shallowEqual);

    const onlines = useSelector(
        state => state.MainReducer.get('onlines'),
        (a, b) => is(a, b)
    );

    const usings = useSelector(
        state => state.MainReducer.get('usings'),
        (a, b) => is(a, b)
    );

    useEffect(() => {
        dispatch(Actions.simulators());
        dispatch(MainActions.socket());

        clockInterval = setInterval(() => {
            setClock(moment().format('YYYY-MM-DD HH:mm:ss'));
        }, 1000);
        return () => {
            if (clockInterval) clearInterval(clockInterval);
        };
    }, []);

    useEffect(() => {
        const openOnline = onlines.includes(opened.serial_no);
        if (!openOnline) {
            ModalRef.current.handleClose();
        } else {
            ModalRef.current.handleConnect();
        }
    }, [onlines]);

    const handleHome = () => {
        return navigate('/Main', { replace: true });
    };

    const handleSimulatorClick = (evt, { item }) => {
        setOpened(item);
        ModalRef.current.handleOpen(item);
    };

    const handleSchoolClick = (evt, { value }) => {
        setFilter({ ...filter, schools_id: value });
    };

    const ModalRef = useRef();

    const schools = [
        ...new Set(
            simulators.map(
                ({ schools_id, schools_name }) =>
                    schools_name + ' (' + simulators.filter(s => s.schools_id === schools_id).length + ')|' + schools_id
            )
        ),
    ].sort((a, b) => a.localeCompare(b));

    const schools_options = schools.map(v => {
        const [content, key] = v.split('|');
        return { key, content, text: content, value: key };
    });

    const _simulators = simulators.filter(s => {
        return !filter.schools_id.length ? true : filter.schools_id.includes(s.schools_id);
    });

    return (
        <div className={styles.container}>
            <div className="title">
                <div></div>
                <div>
                    <span>学车宝模拟器</span>
                </div>
                <div></div>
            </div>
            <Segment attached className="content">
                <div className="simulators">
                    {_simulators.map(simulator => (
                        <Simulator
                            key={simulator.id}
                            item={simulator}
                            onlines={onlines}
                            onClick={handleSimulatorClick}
                        />
                    ))}
                </div>
            </Segment>
            <Menu borderless inverted className="footer">
                <Menu.Item icon="home" link onClick={handleHome} />
                <Dropdown
                    scrolling
                    multiple
                    selectOnBlur={false}
                    selectOnNavigation={false}
                    item
                    placeholder="全部"
                    options={schools_options}
                    onChange={handleSchoolClick}
                />
                <Menu.Menu position="right">
                    <Menu.Item content={'驾校：' + schools.length} />
                    <Menu.Item content={'模拟器：' + simulators.length} />
                    <Menu.Item content={'在线：' + onlines.size} />
                    <Menu.Item content={'在学：' + usings.size} />
                    <Menu.Item content={clock} />
                </Menu.Menu>
            </Menu>
            <Modal ref={ModalRef} onClose={() => setOpened({})} />
        </div>
    );
}

export { default as DashboardReducer } from './reducers';
