import React from 'react';
import { Icon } from 'semantic-ui-react';

export default function CheckboxFormatter(props) {
    const { row, column } = props;

    const value = row[column.key] ?? false;

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Icon name={value ? 'check square outline' : 'square outline'} />
        </div>
    );
}
