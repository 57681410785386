import zh from 'date-fns/locale/zh-CN';
import { format, toDate } from 'date-fns';
import React from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Input } from 'semantic-ui-react';

registerLocale('zh', zh);
export default function DataGridDateEditor(props) {
    const { column: { key, showTimeSelect, showMonthYearPicker } = {}, row } = props;

    const value = row[key] ?? '';

    const formatted = value ? format(value, 'yyyy-MM-dd') : '';

    return (
        <ReactDatePicker
            open
            showPopperArrow={false}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            todayButton="今天"
            dropdownMode="select"
            showTimeSelect={showTimeSelect}
            showMonthYearPicker={showMonthYearPicker}
            locale="zh"
            value={formatted}
            customInput={<Input fluid value={formatted} />}
            selected={value ? toDate(value) : null}
            onChange={value => props.onRowChange({ ...row, [key]: format(value, 'yyyy-MM-dd') }, true)}
        />
    );
}
