import { Login as Api } from '../../Api';

import * as ACTION_TYPES from './constants';
import { Toast } from '../Common';

export default class LoginAction {
    static loading = () => ({
        type: ACTION_TYPES.LOGIN_LOADING,
    });

    static login = body => async (dispatch, getState) => {
        try {
            dispatch(this.loading());
            const res = await Api.login(body);
            if (res instanceof Error) throw res;
            if (res) {
                const { autoLogin } = body;
                const { token } = res;
                sessionStorage.setItem('token', token);
                dispatch(this.success({ ...res, autoLogin }));
                Toast.success('登入成功');
            } else {
                throw new Error('登入失敗');
            }
        } catch (error) {
            Toast.error(error);
            dispatch(this.loading());
        }
    };

    static token = body => async (dispatch, getState) => {
        try {
            const res = await Api.token(body);
            if (res instanceof Error) throw res;
            dispatch(this.success({ ...res }));
        } catch (error) {}
    };

    static success = (user = {}) => ({
        type: ACTION_TYPES.LOGIN_SUCCESS,
        user,
    });

    static reset = () => async (dispatch, getState) => {
        localStorage.removeItem('token');
        sessionStorage.removeItem('token');
        dispatch({ type: ACTION_TYPES.LOGIN_RESET });
    };
}
