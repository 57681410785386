import * as ACTION_TYPES from './constants';

import { Setting } from '../../../Api';

import { Toast } from '../../Common';

const Api = Setting.Version;

const reducer = 'SettingVersionReducer';

export default class SettingVersionActions {
    static loading_index = toggle => ({
        type: ACTION_TYPES.SETTING_VERSION_LOADING_INDEX,
        toggle,
    });

    static loading_show = toggle => ({
        type: ACTION_TYPES.SETTING_VERSION_LOADING_SHOW,
        toggle,
    });

    static search =
        (search = {}) =>
        async (dispatch, getState) => {
            dispatch({ type: ACTION_TYPES.SETTING_VERSION_SEARCH, search });
        };

    static requests = () => async (dispatch, getState) => {
        try {
            dispatch(this.loading_index(true));
            const query = getState()[reducer].get('search');
            const res = await Api.index(query);
            if (res instanceof Error) throw res;
            dispatch(this.index(res));
        } catch (error) {
            dispatch(this.loading_index(false));
            console.error(error);
        } finally {
        }
    };

    static index = (versions = []) => ({
        type: ACTION_TYPES.SETTING_VERSION_INDEX,
        versions,
    });

    static request = params => async (dispatch, getState) => {
        try {
            dispatch(this.loading_show());
            const res = await Api.show(params);
            if (res instanceof Error) throw res;
            dispatch(this.show(res));
        } catch (error) {
            dispatch(this.loading_show(false));
            console.error(error);
        } finally {
        }
    };

    static create = () => async (dispatch, getState) => {
        try {
            dispatch(this.loading_show());
            const body = getState()[reducer].get('version').toJS();

            const form = new FormData();

            for (const [key, value] of Object.entries(body)) {
                if (key !== 'url') {
                    form.append(key, value);
                }
            }
            const res = await Api.create(form, body);
            if (res instanceof Error) throw res;
            dispatch(this.show(res));
            Toast.success('记录已保存');
            dispatch(this.requests());
        } catch (error) {
            dispatch(this.loading_show(false));
            console.error(error);
        } finally {
        }
    };

    static destroy = () => async (dispatch, getState) => {
        try {
            const params = getState()[reducer].getIn(['version', 'id']);
            dispatch(this.loading_show());
            const res = await Api.destroy(params);
            if (res instanceof Error) throw res;
            dispatch(this.show(res));
            Toast.success('记录已删除');
            dispatch(this.requests());
        } catch (error) {
            dispatch(this.loading_show());
            console.error(error);
        } finally {
        }
    };

    static show = (version = {}) => ({
        type: ACTION_TYPES.SETTING_VERSION_SHOW,
        version,
    });

    static reset = () => ({
        type: ACTION_TYPES.SETTING_VERSION_RESET,
    });

    static column = (columns = []) => ({
        type: ACTION_TYPES.SETTING_VERSION_COLUMN,
        columns,
    });
}
