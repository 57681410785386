import { Button, Form } from 'semantic-ui-react';
import { DetailAction } from '../../Common';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from './actions';
import { is } from 'immutable';
import Upload from 'rc-upload';
import { PAY_NOTIFICATION } from '../../../Constants/Utils';

export default function (props) {
    const dispatch = useDispatch();

    const loading_show = useSelector(
        state => state.SettingSettingReducer.get('loading_show'),
        (a, b) => is(a, b)
    );

    const setting = useSelector(
        state => state.SettingSettingReducer.get('setting'),
        (a, b) => is(a, b)
    );

    useEffect(() => {
        dispatch(Actions.request());
        return () => {
            dispatch(Actions.show());
        };
    }, []);

    const handleChange = (evt, { name, value }) => {
        dispatch(Actions.show(setting.set(name, value)));
    };

    const handleAction = () => {
        dispatch(Actions.update());
    };

    const name = setting.get('name') ?? '';
    const about = setting.get('about') ?? '';
    const service = setting.get('service') ?? '';
    const tax_bank = setting.get('tax_bank') ?? '';
    const tax_bank_name = setting.get('tax_bank_name') ?? '';
    const tax_bank_account = setting.get('tax_bank_account') ?? '';
    const bank = setting.get('bank') ?? '';
    const bank_name = setting.get('bank_name') ?? '';
    const bank_account = setting.get('bank_account') ?? '';
    const pay_notification = setting.get('pay_notification') ?? '';

    return (
        <div className="flexHorizontalContent">
            <div style={{ marginLeft: -14, marginTop: -7 }}>
                <DetailAction remove={false} add={false} onAction={handleAction} />
            </div>
            <Form widths="equal" loading={loading_show}>
                <Form.Group>
                    <Form.Input label="公司名称" name="name" value={name} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                    <Form.Input label="关于我们(网址)" name="about" value={about} onChange={handleChange} />
                    <Form.Input label="客服电话" name="service" value={service} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                    <Form.Input label="公户银行" name="tax_bank" value={tax_bank} onChange={handleChange} />
                    <Form.Input label="公户户名" name="tax_bank_name" value={tax_bank_name} onChange={handleChange} />
                    <Form.Input
                        label="公户账号"
                        name="tax_bank_account"
                        value={tax_bank_account}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Input label="私户银行" name="bank" value={bank} onChange={handleChange} />
                    <Form.Input label="私户户名" name="bank_name" value={bank_name} onChange={handleChange} />
                    <Form.Input label="私户账号" name="bank_account" value={bank_account} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                    <Form.Input
                        label="收款提醒"
                        name="pay_notification"
                        value={pay_notification}
                        onChange={handleChange}
                        placeholder={PAY_NOTIFICATION}
                        type="number"
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Input
                        readOnly
                        label="基础安装包"
                        action={
                            <Upload>
                                <Button content="上传" />
                            </Upload>
                        }
                    />
                </Form.Group>
            </Form>
        </div>
    );
}

export { default as SettingSettingReducer } from './reducers';
